///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
新着情報

トップページで使用されている新着情報のスタイルです。
*/
.ec-newsRole {
  @include container;
  max-width: $container-maxwidth3;

  @include containerMargin;
  
  & &__news {
    max-width: 1280px;
    margin: 0 auto;
    border: 6px solid $clrBodyToneBg;
    padding: 1rem;
    
    @include media_desktop {
      padding: $padding-desktop;
    }
  }
  
  & &__newsItem {

    &:nth-child(n+2) {
      border-top: 1px solid $clrBorderColor;
      padding-top: 1rem;
      margin-top: 1rem;

      @include media_desktop {
        margin-top: 2rem;
      }
    }
  }
  
  & &__newsHeading {
    font-family: $ff-min;
    font-size: 1.13rem;
    display: flex;
    flex-flow: row wrap;
    
    @include media_desktop {
      font-size: 1.4rem;
    }
  }

  & &__newsDate {
    flex: 0 0 6em;

    @include media_sphone {
      flex-basis: 100%;// 日付で1行改行
      font-size: 1rem;
    }
  }

  & &__newsTitle {

    @include media_sphone {
      margin: .5em 0;
    }
  }
  
  & &__newsDescription {
    margin: 1em 0 0;
    font-size: 0.93rem;
    line-height: 1.8;
  }

  // もっと読む
  & &__newsMore {
    margin: 0.25em 0 0 0;
    font-size: 0.93rem;
    font-weight: bold;
    
    a {
      color: inherit;
      
      &:active,
      &:hover {
        outline: 0;
        text-decoration: none;
      }
      
      .ec-newsRole__newsMoreClose {
        display: none;
      }

      &.is_active {
      }
    }
  }

  & &__newsMoreIcon {
    position: relative;
    display: inline-block;
    width: 0.85em;
    height: 0.85em;
    margin: 0 0.5em 0 0;
    vertical-align: -0.05em;
    
    &:before,
    &:after {
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 2px;
      background-color: #333;
      content: "";
    }
    &:before {
      transform: rotate( -90deg );
      transition: transform .2s ease-out;
    }
  }

  & &__newsMore a.is_active {
    .ec-newsRole__newsMoreIcon {
      &:before {
        transform: rotate( 0deg );
      }
    }
    .ec-newsRole__newsMoreText {
      display: none;
    }
    .ec-newsRole__newsMoreClose {
      display: inline;
    }
  }

  // リンクが指定された場合の「詳しくはこちら」ボタン
  & &__link {

    a {
      display: inline-block;
      font-size: .83rem;
      background-color: #000;
      color: #fff;
      line-height: 1.2;
      padding: .5em 1em;
      border-radius: 4px;
      margin-top: 1em;
    }
  }
}
