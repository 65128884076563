///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。
*/



.ec-layoutRole__footer {

  // ページコンテンツとフッターとの余白をスタイリングします
  // ※stickyフッター（ブラウザの底に吸着）のため「margin-top:auto;」をかけてありますので（_11.1.role.scss）余白はpadding-topを用います
  @include media_sphone {
    padding-top: $padding-sphone;
  }

  @include media_tablet {
    padding-top: $padding-tablet;
  }

  @include media_desktop {
    padding-top: 100px;
  }
}

.ec-footerRole {
  background-color: $clrFooterBase;
  color: $clrFooterText;// メイン文字色

  @include media_sphone {
    font-size: 0.9285rem;
  }

  @include media_tablet {
    font-size: 1rem;//0.8666rem;
  }

  @include media_desktop {
    font-size: 1rem//0.8666rem;
  }

  // インナー
  & &__inner {
  }
}



/*
フッターナビ
*/
.ec-footerNavi{
  background-color: $clrFooterBase;
  color: $clrFooterText;// メイン文字色

  padding: 0;

  @include media_desktop {
    padding: 1.75rem;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-align: center;

    li {
      display: block;

      @include media_desktop {
        display: inline-block;
      }

      &:nth-child(n+2) {
        border-top: solid 1px $clrFooterBorder;

        @include media_desktop {
          border-top: none;
          border-left: solid 1px $clrFooterBorder;
        }
      }

      a {
        display: block;
        color: inherit;
        padding: 0.75em;

        @include media_desktop {
          padding: 0 1em;
        }
      }
    }
  }
}



/*
フッターガイド
*/
.ec-footerGuide {
  border-top: 1px solid $clrFooterBorder;
  border-bottom: 1px solid $clrFooterBorder;

  .ec-footerRow {
    display: flex;

    @include media_sphone {
      display: block;
    }

    @include media_desktop {
      max-width: calc(1280px + 30px * 2);// 本来のmaxサイズ1280pxに、内包するカラムの左右余白分を足しています。
      margin: 0 auto;
    }

    // フッターガイド カラム
    .ec-footerColumn {
      border-top: 1px solid $clrFooterBorder;

      // カラム内容を上下中央配置
      display: flex;
      flex-flow: column;
      justify-content: center;

      // スマホ
      @include media_sphone {
        padding: $padding-sphone;
      }

      // タブレット
      @include media_tablet {
        flex: 0 0 auto;
        width: 33.3%;
        padding: $padding-tablet;
        border-top: none;
        border-left: 1px solid $clrFooterBorder;
      }

      // デスクトップ
      @include media_desktop {
        flex: 0 0 auto;
        width: 33.3%;
        padding: 30px;
        border-top: none;
        border-left: 1px solid $clrFooterBorder;
      }

      &:first-child {
        border: none;
      }

      // カラムアイテム（支払方法、ソーシャルボタン枠）
      &__item {

        // 連続するカラムアイテム
        &:nth-child(n+2) {
          margin-top: 2rem;
        }
      }

      // タイトル
      &__title {
        margin: 0;
        margin-bottom: 1.3em;
        font-family: $ff-roboto;
        font-size: 1.13rem;
        font-weight: normal;
        color: $clrFooterTitleText;
        text-align: center;
      }

      // About us テキスト
      &__txt {

        p {
          font-size: 0.93em;
          line-height: 1.7;
          margin: 0;

          // 連続するp要素
          &:nth-child(n+2) {
            margin-top: 1em;
          }
        }
      }
    }
  }
}



/*
支払い方法
*/
.ec-footerPayment {

  ul {
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 0;

    li {
      display: inline-block;
      margin: 0 0.5rem;

      img {
        width: auto;
        height: 2rem;
      }
    }
  }
}



/*
フッター ソーシャルボタン
*/
.ec-footerSocial {
  margin-top: -1rem;

  & &__itemList {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      margin: 5px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        width: 1.6em;
        height: 1.6em;
        border-radius: 50%;
        background-color: transparent;
        color: inherit;

        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }

      i {
        display: block;
      }

      /* デスクトップ時はマウスオーバーで色が付くようにします */
      @include media_desktop {
        a {
          color: $clrFooterText;
          transition: .2s;
        }
        &.facebook a:hover {
          background-color: $clr-facebook;
        }
        &.twitter a:hover {
          background-color: $clr-twitter;
        }
        &.instagram a:hover {
          background-color: $clr-instagram;
        }
      }
    }
  }
}



/*
フッターカレンダー

EC-CUBE4.1.1より追加された「カレンダーブロック」になりますが、当テンプレートではフッターカレンダーとして使用しています。
標準でふた月分のカレンダーが表示されますが、当テンプレートではひと月ごとスライド表示させるようにしています。
*/

// フッターカレンダー
.ec-footerCalendar {
  width: 100%;
  color: $clrFooterText;
  position: relative;// slick領域外アローボタン用

  // デスクトップ用
  @include media_desktop {
    // カレンダー枠に最大幅を定義して左右中央配置とします
    width: 300px;
    margin: 0 auto;
  }

  // slickコンテナ
  & &__slick {
    border-bottom: solid 1px $clrFooterBorder;// カレンダーの下に敷かれる下線

    // slick準備整うまでは非表示
    visibility: hidden;
    opacity: 0;
    transition: 1s;

    // slick準備完了で表示開始
    &.slick-initialized.slick-initialized {
      visibility: visible;
      opacity: 1;
    }
  }

  // slick領域外アローボタン（※positionによって、カレンダータイトル（〇年〇月）の上に被せる形にします）
  .slick-appendArrows {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    display: flex;
    justify-content: space-between;

    .slick-arrow {
      font-size: .86rem;

      &:not(.slick-disabled) {
        cursor: pointer;
      }

      &.slick-disabled {
        opacity: 0.25;
      }
    }
  }

  // カレンダーアイテム
  & &__item {
  }

  // カレンダータイトル
  & &__monthTitle {
    text-align: center;
    font-size: 1rem;
    line-height: 1;
  }

  // 脚注
  & &__footnote {
    font-size: 0.8rem;
    color: inherit;
    line-height: 1.2;
    margin-top: 1em;

    .holiday {
      display: inline-block;
      width: 15px;
      height: 15px;
      background-color: $clrCalendarHolidayBg;
      margin-left: .25em;
      margin-right: .25em;
    }
  }
}


// カレンダー
.ec-calendar {
  border-top: solid 1px $clrFooterBorder;// カレンダーの上に敷かれるborderライン
  // ※カレンダーの下に敷かれる下線は、月によってカレンダー高さは変わるので、カレンダーにではなく、slickコンテナ側に下線を入れる形にしています
  padding: .5rem 0;
  margin-top: .5rem;

  // カレンダー構成table
  table {
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    font-size: 0.8rem;

    // デスクトップ用
    @include media_desktop {
      // tableをわずかに左右拡大して、日・土のマスの端の余白をつぶし、枠いっぱいになるようにします（カレンダーの枠「.ec-footerCalendar」が300pxである前提で調整します）
      width: calc(100% + 20px);
      margin-left: -10px;
    }

    th, td{
      padding: 1px 0;// 行の高さに連携します
      text-align: center;
      vertical-align: middle;
      font-weight: 400;

      span {
        display: inline-block;
        width: 1.7em;
        line-height: 1.7;
        border: solid 1px transparent;
      }
    }
  }

  // 休日の表記
  & &__holiday {

    span {
      background-color: $clrCalendarHolidayBg;
      color: $clrCalendarHolidayText;
    }
  }

  // 本日の表記
  & &__today {

    span {
      background-color: $clrCalendarTodayBg;
      color: $clrCalendarTodayText;
    }
  }
}



// フッタータイトル
.ec-footerTitle{
  padding: 1rem;

  @include media_desktop {
    padding: 1.75rem;
  }

  & &__copyright{
    text-align: center;
  }
}
