///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ブロックタイトル

プラグインブロック用に用意したセクションタイトルになります。
当テンプレートでは「商品レビュー」「関連商品」プラグインで使用しています。

※「関連商品」プラグインはTwigカスタマイズできないので「jeia_function.js」からブロックタイトルを挿入しています。
*/
.ec-blockHeading {
  margin-bottom: 2rem;
  text-align: center;

  @include media_desktop {
    margin-bottom: $padding-desktop * 1.2;
  }

  h2 {
    margin: 0;
    line-height: 1.2;
    text-align: center;
  }

  // main
  & &__main {
    font-family: Arial;
    font-size: 1.6rem;
    font-weight: bold;
    color: $clrDefault;
    position: relative;
    z-index: 0;

    span {
      display: inline-block;
      padding: 0 1em;
      background-color: $clrBodyBg;
    }

    // 左右罫線
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $clrBorderColor;// 罫線色
      position: absolute;
      top: 50%;
      left: 0;
      z-index: -1;
    }
  }

  // sub
  & &__sub {
    font-size: 1rem;
    font-weight: 400;
    margin-top: .25em;
  }
}
