///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
トップカテゴリ

トップページで使用されているトップカテゴリのスタイルです。
セクション要素の高さ、ギャップ余白はCSSカスタムプロパティ化してありますのでCSS管理から（custome.css）調整が可能です。
*/

// セクション要素高さの設定
$topCategoryHeight: var(--topCategoryHeight, 42vw);

// ギャップ設定（デスクトップ時のみ余白を設けます、スマホ・タブレット時は余白なし）
$topCategoryGap: var(--topCategoryGap, 1vw);
@include media_sphone {
  :root {
    --topCategoryGap: 0vw;
  }
}
@include media_tablet {
  :root {
    --topCategoryGap: 0vw;
  }
}

.ec-topCategoryRole {
  @include container;
  max-width: 100%;
  padding: 0 !important;// セクション余白をリセット

  // リスト枠（3つのトップカテゴリを包括する要素）
  & &__list {
    padding: #{$topCategoryGap};// ギャップ余白を周囲に設けます

    @include clearfix;
    overflow: hidden;// IE11でclearfixがうまく効かないのでoverflow:hiddenを併記
  }

  // アイテム
  & &__item {
    position: relative;

    // -----------
    // まずデスクトップ時のスタイリングを施して（左1枚、右2枚レイアウト 余白あり）、そのあとでスマホ・タブレット用のスタイルを上書きしていきます
    // -----------

    // 3枚とも幅はギャップ余白を引いた50%サイズ
    width: calc( (99.99% - #{$topCategoryGap}) * 0.5 );// 99.99%は、100%から計算するとブラウザ幅によっては収まらず改行されてしまう場合を考慮
    float: left;

    // 1枚目
    &:nth-child(1) {
      margin-right: #{$topCategoryGap};

      img {
        object-fit: cover;
        height: #{$topCategoryHeight};
      }
    }

    // 2,3枚目
    &:nth-child(2),
    &:nth-child(3) {

      img {
        object-fit: cover;
        height: calc(( #{$topCategoryHeight} - #{$topCategoryGap} ) * 0.5);
      }
    }

    &:nth-child(3) {
      margin-top: #{$topCategoryGap};
    }

    // スマホ用に上書き
    @include media_sphone {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        width: 100%;
        margin-top: #{$topCategoryGap};

        img {
          height: #{$topCategoryHeight};
        }
      }

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    // タブレット用に上書き
    @include media_tablet {
    }

    // a要素
    a {
      display: block;

      // hover
      &:hover {

        .ec-topCategoryRole__content {
          background-color: rgba(0, 0, 0, 0.5);

          .title {
            transform: translate3d(0,-0.5em,0);
          }
        }        
      }
    }
  }

  // イメージ画像
  & &__image {
    @include imageHover;
  }

  // コンテンツ
  & &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;// イメージ画像のhover動作を疎外させないように

    display: flex;
    justify-content: center;
    align-items: flex-end;//center;
    padding: 2.5vw;
    background-color: transparent;
    transition: 1s;

    // タイトル
    .title {
      margin: 0;
      color: #fff;
      font-family: $ff-roboto;
      font-size: 3vw;
      font-weight: 700;
      line-height: 1.2;
      transition: 1s;

      @include media_sphone {
        font-size: 7vw;
      }
    }
  }
}
