///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。
*/
.ec-productRole {
  @include container;
  max-width: $container-maxwidth2;

  & &__grid {

    @include media_desktop {
      display: flex;
      justify-content: space-between;
    }
  }

  & &__gridCell {

    // スマホ用レイアウト
    @include media_sphone {

      &.cellLeft {
        // 画像ビジュアル枠をフル幅にするためネガティブマージン使用
        margin-left: $padding-sphone * -1;
        margin-right: $padding-sphone * -1;
      }

      &.cellRight {
        margin-top: 20px;
      }
    }

    // タブレット用レイアウト
    @include media_tablet {
      &.cellLeft {
        // 画像ビジュアル枠をフル幅にするためネガティブマージン使用
        margin-left: $padding-tablet * -1;
        margin-right: $padding-tablet * -1;
      }

      &.cellRight {
        margin-top: 30px;
      }
      /*
      @media only screen and ( orientation: portrait ) {
        &.cellLeft {
          // 画像ビジュアル枠をフル幅にするためネガティブマージン使用
          margin-left: $padding-tablet * -1;
          margin-right: $padding-tablet * -1;
        }

        &.cellRight {
          margin-top: 30px;
        }
      }
      @media only screen and ( orientation: landscape ) {
        &.cellLeft {
          flex: 0 0 auto;
          width: 56%;
        }

        &.cellRight {
          flex: 1 1 auto;
          width: auto;
          margin-left: 50px;
        }
      }
      */
    }

    // デスクトップ用レイアウト（左右2分割）
    @include media_desktop {

      &.cellLeft {
        flex: 0 0 auto;
        width: 56%;
      }

      &.cellRight {
        flex: 1 1 auto;
        width: auto;
        margin-left: 50px;
        //overflow: hidden;
      }
    }
  } 




  // ビジュアル画像枠
  & &__img {
    /*
    @include media_desktop {
      flex: 0 0 auto;
      width: 55%;
      margin: 0;
    }
    */
  }

  // 商品情報欄枠
  & &__profile {
    /*
    @include media_sphone {
      //padding: $padding-sphone 0;
      margin-top: $padding-sphone;
    }

    @include media_tablet {
      //padding: $padding-tablet 0;
      margin-top: $padding-tablet;
    }
    
    @include media_desktop {
      width: auto;
      //padding-left: 50px;
      margin-left: 50px;
      flex-grow: 1;
    }
    */
  }

  /* 商品タイトル */
  & &__title {

    h1 {
      margin: 0;
      margin-bottom: .5em;
      font-family: $ff-min;
      font-size: 1.5rem;
      font-weight: normal;
      color: $clrDefaultDark;
      
      @include media_desktop {
        font-size: 2rem;
      }
    }
  }

  /* 商品コード（商品タイトルの中で展開） */
  & &__code {
    font-size: 0.933rem;
    color: $clrDefaultThin;
    margin: 0.5rem 0;

    .product-code-default {
      font-family: $ff-en;
    }
  }

  // 属性タグ枠
  & &__tags {
    list-style: none;
    margin: 0.75rem 0;
    margin-left: -5px;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
  }

  // 属性タグ
  & &__tag {
    border: solid 1px $clrBorderColor;
    margin: 0;
    margin-left: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    font-size: 0.866rem;
    padding: 0.5em 0.75em;
    line-height: 1;
    white-space: nowrap;
    color: $clrDefaultThin;
  }

  // 通常価格
  & &__priceRegular {
    margin: .75em 0;

    .ec-price__label {
      font-size: .86rem;
    }

    .ec-price__price {
      font-size: 1rem;
    }

    .ec-price__tax {
      font-size: 0.8rem;
    }

    // 「.ec-price」の構造スタイリングは、_1.2.type.scssにて
  }

  // 販売価格
  & &__price {
    margin: .75em 0;
    color: $btn-action-bg;// アクションボタンカラーを使用

    .ec-price__label {
      font-size: .86rem;
    }

    .ec-price__price {
      font-size: 1.5rem;
      font-weight: 400;

      @include media_desktop {
        font-size: 2rem;
      }
    }

    .ec-price__tax {
      font-size: 0.8rem;
    }

    // 「.ec-price」の構造スタイリングは、_1.2.type.scssにて
  }

  // 詳細コメント
  & &__description {
    font-size: 1rem;
    margin: 1.5rem 0 2rem;
    line-height: 1.7;
    color: $clrDefaultThin;
    text-align: justify;
    border-top: 1px solid $clrBorderColor;
    border-bottom: 1px solid $clrBorderColor;
    padding: 1rem 0;

    @include media_sphone {
      margin: 1rem 0;
    }
  }

  /*
  // 数量ボタンユニット
  .ec-numberInput {

    dl {
      margin: 0;
      
      dt {
        font-size: 0.86rem;
        font-weight: 500;
      }

      dd {
        margin: 0;
        margin-top: .5rem;
      }
    }

    .ec-number-wrap {
      display: inline-flex;
      flex-direction: row;
      padding: 0;
      margin: 0;
      height: $cartinRow-size;
      border: solid 1px $clrBorderColor;
      
      input[type=number] {
        width: $cartinRow-size;//3rem;
        padding: 0;
        padding-top: 0.2em;// 上下位置微調整
        margin: 0;
        border-color: transparent;
        border-radius: 0;
        background-color: #fff;
        text-align: center;
        -moz-appearance: textfield;
        font-family: $ff-roboto;
        font-size: 1.4rem;
        font-weight: 400;
        
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0; 
        }
      }

      .ec-number-down,
      .ec-number-up {
        width: $cartinRow-size;
        height: auto;
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
        background-color: #e8e8e8;
      }

      input[type=number],
      .ec-number-down,
      .ec-number-up {
        line-height: $cartinRow-size;
      }
    }
  }

  // __form
  & &__form {
    padding: 0;//1rem 0;
    margin: 1rem 0;
  }

  // 規格ボタン枠
  & &__actions {
    display: flex;
    flex-direction: column;
    
    @include media_desktop {
      flex-direction: row;
    }
    
    dl {
      margin: 0;

      &:nth-child(n+2) {
        margin-top: 1rem;

        @include media_desktop {
          margin-top: 0;
          margin-left: 15px;
        }
      }
      
      dt {
        font-size: 0.86rem;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0;
      }

      dd {
        margin: .5rem 0 0 0;
      }
    }
    .ec-select {
      margin: 0;
    }
  }
  */

  /* アクション枠 */
  & &__actions {
    //@include borderTop;
    margin: 1rem 0;
    //padding-top: 1rem;
  }

  /* 規格枠 */
  & &__class {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.classCategory1 {
    }

    &.classCategory2 {
      margin-top: 10px;
    }

    .classLabel {
      margin: 0;
      margin-right: 0.5em;//1em;
      min-width: 6em;
      font-size: .86rem;

      &:after {
        content: "：";
      }
    }

    .ec-select {

      select {
        max-width: 100%;
      }
    }

    // タブレット・デスクトップ時は従来のJEIA4002スタイルとします
    @media (min-width: $tablet) {
      display: inline-block;

      &.classCategory2 {
        margin-top: 0;
        margin-left: 12px;
      }

      .classLabel {
        margin: 0 0 0.25em;
        min-width: auto;
      }
    }
  }

  /*
  // 数量・カートボタン枠
  & &__add-cart {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 1rem 0;

    @include media_sphone {
      display: block;
    }

    // 数量ボタン枠
    .ec-numberInput {
      margin-right: 20px;// デスクトップ・タブレット

      @include media_sphone {
        margin-right: 0;
      }
    }
    
    // カートインボタン枠
    .ec-productRole__btn {
      flex-grow: 1;

      @include media_sphone {
        margin-top: 1rem;
      }
    }

    .ec-blockBtn--action {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.06rem;
      font-weight: 500;
      letter-spacing: 0;
      height: $cartinRow-size;
      //line-height: $cartinRow-size;
      
      &:before {
        display: block;
        width: 1.25em;
        height: 1.25em;
        margin-right: .5em;
        background: url(../icon/cart.svg) no-repeat;
        background-size: 100% 100%;
        content: "";
      }
    }
  }

  // お気に入りボタン・シェアボタン枠
  & &__share {
    margin: 3rem 0 1rem;
    display: flex;
    flex-direction: row;
  }

  & &__add-favorite {
    button {
      -webkit-appearance: none;
      appearance: none;
      padding: 0;
      border: 0;
      background: transparent;
      font-size: 1rem;
      
      &:before {
        display: inline-block;
        width: 1.4375em;
        height: 1.3125em;
        background: url(../icon/favorite.png) no-repeat;
        background-size: contain;
        vertical-align: -0.2em;
        margin: 0 0.5em 0 0;
        content: "";
      }
    }
  }

  // シェアボタン
  & &__sns {
    border-left: 1px solid $clrBorderColor;
    padding: 0 0 0 0.25rem;
    margin: 0 0 0 1rem;
    
    ul {
      padding: 0;
      margin: 0;
      font-size: 0;
      
      li {
        display: inline-block;
        padding: 0;
        margin: 0 0 0 0.5rem;
        list-style: none;
        
        @include media_desktop {
          margin: 0 0 0 0.75rem;
        }
        
        a {
          display: inline-block;
          
          i {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            
            &.icon-twitter {
              background-image: url(../icon/sns-twitter.svg);
            }
            &.icon-facebook {
              background-image: url(../icon/sns-facebook.svg);
            }
            &.icon-line {
              background-image: url(../icon/sns-line.svg);
            }
          }
        }
      }
    }
  }
  */


  /* カートイン枠数量・カートイン・お気に入り） */
  & &__cartinRow {
    display: flex;
    align-items: center;
    margin-top: 1.5em;

    /* 数量タイトルラベル */
    .quantityLabel {
      margin: 0;
      margin-right: .5em;
      font-size: .86rem;
      font-weight: 400;
      display: none;
    }

    /* 数量 */
    .ec-quantityRole {
      //本体「.ec-quantityRole」は、_3.1.inputText.scssにて
      margin-right: 15px;
    }

    /* カートインボタン・品切れ */
    .ec-productRole__btn {
      flex: 1 1 auto;// 伸縮
      max-width: 350px;// お気に入りボタンが無い場合を考慮して一応最大幅を定義

      // ボタン定義を上書きして調整
      button {
        height: $cartinRow-size;
        line-height: $cartinRow-size;
        width: 100%;
      }
    }

    /* お気に入りボタン */
    .ec-blockBtn--favorite {
      // 本体のボタン定義は、_2.1.buttonsize.scssにて
      margin-left: 10px;
    }

    // スマホ用調整
    @include media_sphone {
      flex-flow: row wrap;

      .quantityLabel {
        display: block;
      }

      .quantityLabel,
      .ec-quantityRole {
        margin-bottom: 1em;
      }
    }
  }

  /* シェアボタン */
  & &__share {
    margin-top: 2rem;

    .shareLabel {
      margin: 0;
      margin-right: .5em;
      display: inline-block;
    }

    .shareBtnList {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin-left: 3px;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 17px;// ※emを使うとフォントサイズに端数が出て中のアイコン位置がずれる様子なのでpx指定
          width: 2em;
          height: 2em;

          @include media_sphone {
            font-size: 1.1rem;// スマホ時は相対的フォントサイズで
          }

          background:#666;
          color: #fff;
          border-radius: 50%;
          cursor: pointer;
          transition: .3s;

          i {

            & + span {
              display: none;
            }
          }

          &:hover {
            text-decoration: none;
          }

          &.twitter:hover {
            background-color: $clr-twitter;
          }
          &.facebook:hover {
            background-color: $clr-facebook;
          }
          &.pinterest:hover {
            background-color: $clr-pinterest;
          }
          &.line:hover {
            background-color: $clr-line;
          }
          &.pocket:hover {
            background-color: $clr-pocket;
          }
          &.hatena:hover {
            background-color: $clr-hatena;
          }
        }
      }
    }
  }



  // 関連カテゴリ
  & &__category {
    margin: 1rem 0;
    font-size: 0.933rem;
    border-top: solid 1px $clrBorderColor;
    padding-top: 1rem;

    @include media_desktop {
      margin-top: 1.5rem;
    }

    .titleLabel {
      color: $clrDefaultThin;
      font-size: .86rem;
      margin-bottom: .5em;
    }

    // 商品一覧で使用した.ec-topicpathを利用して、関連カテゴリ用に調整
    .ec-topicpath {

      .ec-topicpath__item {

        &:first-child {
          &:before {
            display: none;
            //content: "・";
          }
        }

        /* 最後のli要素を太字 */
        &:last-child {

          a {
            font-weight: normal;
            pointer-events: auto;
          }
        }
      }
    }
  }
}



/*
モバイル時に横長（landscape）の場合の追加スタイリング

特にタブレットにおいて横置きしての閲覧の場合に商品詳細ページのビジュアル画像が画面いっぱいに表示するとページスクロールができない問題の対策。
JEIA4002では画像拡大用のタッチイベントが画面全体に働くので、対策として、デスクトップ時と同じ見た目に上書きスタイリングします。
また、ビジュアル枠全体がすっぽり収まるようjsにて横幅を計算させます。
*/

// 判定用要素（※#issp要素の有無でスマホかデスクトップかを判定していますが、新たにプロパティを追加してlandscapeモバイルを判定します）
#issp {
  --device: '';// 初期値
}

// デスクトップ未満デバイスで横長デバイス
@media only screen and ( max-width: ($desktop - 1) ) and ( orientation: landscape ) {
  
  // sp判定をオフ
  #issp {
    display: none;// sp判定をオフ。サムネイルナビを縦スライドさせる目的（function_4002.js）
    --device: 'mobile_landscape';// モバイル時に横長（landscape）の場合に目印となるプロパティをセット（function_4002.jsで条件分岐）
  }

  .ec-sliderItemRole{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;// jsにより計算
    padding: $padding-tablet;
    padding-bottom: 0;

    .item_visual {
      flex: 1 0 auto;
      width: auto;
      margin: 0;
      margin-left: 1px;//10px;
      border: 1px solid $clrBorderColor;
    }

    .item_nav {
      flex: 0 0 auto;
      width: 15%;
      height: auto;
      margin-top: 0;
      overflow: visible;

      &__inner {
        flex-direction: column;
        left: 0;
      }

      // prev,nextボタン
      &__prev,
      &__next {
        width: 100%;
        height: 20px;

        .arw {
          top: 0;
          left: 50%;
          width: 30px;
          height: 100%;
          margin-top: 0;
          margin-left: -15px;
        }
      }

      &__prev {
        top: -7px;// 少しはみ出させます

        .arw {
          &:before {
            left: 11px;
            margin-top: -2px;
            transform: rotate( 45deg );
          }
        }
      }

      &__next {
        top: auto;
        bottom: -7px;// 少しはみ出させます

        .arw {
          &:before {
            right: 11px;
            margin-top: -5px;
            transform: rotate( 225deg );
          }
        }
      }
    }

    .slideThumb{
      width: 100%;
      margin-bottom: 1px;//10px;
    }
  }
}



/*
商品フリーエリア

フリーエリアのスタイリングになります。
*/
.ec-productRole {

  & &__freearea {
    margin-top: 30px;

    @include media_desktop {
      margin-top: 60px;
    }
  }
}


// 商品フリーエリアサンプルコードスタイリング
.ec-productDetailTab--noTab {

  h2 {
    font-size: 1.4rem;
    font-weight: 500;
    //margin: 0;
    margin-top: 2em;
    margin-bottom: 1em;

    &:nth-of-type(n+2) {
      //margin-top: 2rem;
    }
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 2em 0 1em;
  }

  h4 {
    font-size: 1.066rem;
    font-weight: 500;
    margin: 2em 0 1em;
  }
}
