///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
TOPヘッドプロモーション
*/
.ec-headPromotionRole {

  // プロモーションアイテム
  .item {
    position: relative;
    width: 100%;
    background:#ccc;

    @include media_sphone {
      height: calc(960 / 750 * 100vw);
    }

    @include media_tablet_and_desktop {
      height: calc(730 / 1920 * 100vw);
    }

    // イメージ画像
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    //
    a {
      display: block;
      width: 100%;
      height: 100%;
    }

    // オーバーレイ要素
    .item-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      text-decoration: none;
      transition: 1s;

      // テキストコンテンツ枠
      .content {
        position: absolute;

        @include media_sphone {
          left: 0;
          right: 0;
          bottom: calc(2rem + 11px);// slickドットの高さ分
          padding: 0 $padding-sphone;
        }

        @include media_tablet_and_desktop {
          // 中のテキスト要素をpositionで位置決めしていくので左右余白をここで確定しておきます
          $pos: 6.6vw;
          left:  $pos;
          right: $pos;
          top: 50%;
          transform: translateY(-50%);
          padding: 0;
        }
      }
    }

    // テキスト1
    .item-catch {
      font-family: $ff-roboto;
      font-weight: normal;
      line-height: 1.2;
      /*
      display: inline-block;
      background-color: $clrBodyBg;
      color: $clrDefaultDark;
      padding: 0 .75em;
      */

      opacity: 0;
      clip-path: inset(0 0 100% 0);
      transition: clip-path 0.5s $ease-out-circ;
      transition-delay: 0.3s;

      @include media_sphone {
        font-size: 1.2rem;
        margin-bottom: .75em;
      }

      @include media_tablet_and_desktop {
        font-size: 1.5vw;
        // 中央の.item-nameが常に上下位置中央を維持できるよう、こちらをpositionで位置決め
        position: absolute;
        bottom: calc(100% + 1.5vw);
        left: 0;
      }
    }

    // テキスト2
    .item-name {
      font-family: $ff-roboto;
      font-weight: 700;
      overflow-wrap: break-word;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      
      opacity: 0;
      clip-path: inset(0 100% 0 0);
      transition: clip-path 1.6s $ease-out-circ;
      transition-delay: 1s;

      @include media_sphone {
        font-size: 8vw;
        line-height: 1.2;
      }

      @include media_tablet_and_desktop {
        font-size: 4vw;
        line-height: 1.2;
      }
    }

    // テキスト3
    .item-more {
      opacity: 0;
      transform: translateY(1em);
      transition: opacity .7s linear, transform .7s $ease-out-circ;
      transition-delay: 1.6s;

      @include media_sphone {
        font-size: 0.73rem;
        margin-top: 2rem;
      }

      @include media_tablet_and_desktop {
        font-size: 0.86rem;
        // 中央の.item-nameが常に上下位置中央を維持できるよう、こちらをpositionで位置決め
        position: absolute;
        top: calc(100% + 4vw);
        left: 0;
      }

      span {
        display: inline-flex;
        align-items: center;
        padding-bottom: 0.4em;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        line-height: 1;

        &:after {
          font-family: jeiafont;
          content: "\e91d";
          margin-left: .5em;
          padding-top: 0.2em;// 上下位置微調整
        }
      }
    }

    // 表示中フラグ
    &.is-visible {

      .item-catch {
        opacity: 1;
        clip-path: inset(0 0 0 0);
      }

      .item-name {
        opacity: 1;
        clip-path: inset(0 0 0 0);
      }

      .item-more {
        opacity: 1;
        transform: translateY(0);
      }
    }

    // 表示終了間際フラグ
    &.is-visibleEnd {
      .item-catch,
      .item-name,
      .item-more {
        transition-delay: 0;
        transition: 1s $ease-in-circ;
        opacity: 0;
        transform: translate3d(0,1em,0);
      }
    }
  }

  // slickスタイリングを上書き
  .slick-slider {

    .slick-dots {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1rem;

      @include media_desktop {
        padding: $padding-desktop;
      }
    }
  }
}



/*
商品詳細

商品詳細 スライド部に関する Project コンポーネントを定義します。
*/
.ec-sliderItemRole{
  @include media_desktop {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;//705px;
  }

  .item_visual {

    @include media_desktop {
      flex: 1 0 auto;
      width: auto;
      margin: 0;
      margin-left: 10px;
      border: 1px solid $clrBorderColor;
    }

    &__container {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 100%;
      overflow: hidden;
    }
    &__inner {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: flex-start;
      transition: left .5s $ease-out-cubic;
    }
    .slide-item {
      position: relative;
      overflow: hidden;
      width: 100%;// add
    }
  }

  // ギャップ
  $gap_sp: 1px;// スマホ用（タブレット兼用）

  // サムネイルサイズ（モバイル時スタイリングに使用）
  $thumb_size_sp: calc((100vw - #{$gap_sp} * 4) / 5);// スマホ用（5個配置）
  $thumb_size_tb: calc((100vw - #{$gap_sp} * 6) / 7);// タブレット用（7個配置）

  .item_nav {

    position: relative;
    //overflow: hidden;// ←prev,nextボタンを少しはみ出させたいので（デスクトップ時）ここでのoverflowはかけません

    @include media_sphone {
      height: $thumb_size_sp;
      margin-top: $gap_sp;
      overflow: hidden;// モバイル時はここでoverflowかけておきます
    }

    @include media_tablet {
      height: $thumb_size_tb;
      margin-top: $gap_sp;
      overflow: hidden;// モバイル時はここでoverflowかけておきます
    }

    @include media_desktop {
      flex: 0 0 auto;
      width: 15%;
      height: auto;
    }

    // ラッパ（prev,nextボタンをはみ出させるための枠=デスクトップ時）
    &__wrap {
      width: 100%;
      height: 100%;
      overflow: hidden;
      clip-path: inset(0 0);// overflow:hiddenではクリップできないようなのでclip-pathを併用
    }

    &__inner {
      position: absolute;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      transition: left .5s $ease-out-cubic, top .5s $ease-out-cubic;

      @include media_desktop {
        flex-direction: column;
      }
    }

    // prev,nextボタン
    &__prev,
    &__next {
      position: absolute;
      top: 0;
      width: 20px;
      height: 100%;
      cursor: pointer;

      @include media_desktop {
        width: 100%;
        height: 20px;
      }

      .arw {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 30px;
        margin-top: -15px;
        background-color: #000;

        @include media_desktop {
          top: 0;
          left: 50%;
          width: 30px;
          height: 100%;
          margin-top: 0;
          margin-left: -15px;
        }

        &:before {
          position: absolute;
          top: 50%;
          width: 8px;
          height: 8px;
          border-top: 1px solid #fff;
          border-left: 1px solid #fff;
          margin-top: -4px;
          content: "";
        }
      }
    }

    &__prev {
      left: 0;

      @include media_desktop {
        top: -7px;// 少しはみ出させます
      }

      .arw {
        &:before {
          left: 8px;
          transform: rotate( -45deg );

          @include media_desktop {
            left: 11px;
            margin-top: -2px;
            transform: rotate( 45deg );
          }
        }
      }
    }

    &__next {
      right: 0;

      @include media_desktop {
        top: auto;
        bottom: -7px;// 少しはみ出させます
      }

      .arw {
        &:before {
          right: 8px;
          transform: rotate( 135deg );

          @include media_desktop {
            right: 11px;
            margin-top: -5px;
            transform: rotate( 225deg );
          }
        }
      }
    }
  }

  // サムネ
  .slideThumb{
    position: relative;
    flex-shrink: 0;
    cursor: pointer;

    @include media_sphone {
      width: $thumb_size_sp;
      margin-right: $gap_sp;

    }

    @include media_tablet {
      width: $thumb_size_tb;
      margin-right: $gap_sp;
    }

    @include media_desktop {
      width: 100%;
      margin-bottom: 10px;
    }

    // 最後の要素のマージンは解除
    &:last-child {
      margin: 0;
    }

    &:focus {
      outline: none;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      transition: .5s;
    }

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      box-sizing: border-box;
      border: solid 3px #000;
      transition: .3s;
      content: "";
      visibility: hidden;
      opacity: 0;
    }

    &:hover,
    &.current {
      &:after {
        visibility: visible;
        opacity: 1;
      }
    }

    &:hover img {
      filter: brightness(1.05);
    }
  }
}
