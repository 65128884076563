///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////
/*
見出し

トップページで使用されている見出しのスタイルです。
*/

/*
セクション見出し
*/
.ec-secHeading {

  margin-bottom: 1.5rem;
  color: $clrDefaultDark;
  text-align: center;
  overflow: hidden;// 飾り線用

  @include media_desktop {
    margin-bottom: $padding-desktop * 1.5;
  }
  
  // メインタイトル（英語）
  & &__maintitle {
    position: relative;
    display: inline-block;
    margin: 0;
    font-size: 1.5rem;
    font-family: $ff-roboto;
    font-weight: 700;
    letter-spacing: 0;
    
    @include media_desktop {
      font-size: 2.5rem;
    }
    
    // 飾り罫線
    &:before,
    &:after {
      position: absolute;
      top: 50%;
      display: block;
      width: 100vw;
      max-width: 110px;
      height: 1px;
      background-color: $clrBorderColor;
      content: "";
    }

    &:before {
      right: calc(100% + 0.75em);
    }

    &:after {
      left: calc(100% + 0.75em);
    }
  }

  // サブタイトル（日本語）
  & &__subtitle {
    margin: .5em 0 0 0;
    text-align: center;
    font-size: 0.93rem;
    font-family: $ff-min;
    
    @include media_desktop {
      font-size: 1rem;
      margin-top: .75em;
    }
  }
}
