///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
新着商品

トップページで使用されている新着商品ブロックのスタイルです。
*/
.ec-newItemRole {
  @include container;
  max-width: $container-maxwidth3;

  @include containerMargin;

  @include media_desktop {
    // slickのprev,nextボタンの余白分、少し大きく確保します。
    $gap: 60px;
    padding-left:  $gap;
    padding-right: $gap; 
  }
}



/*
バナー

トップページで使用されているバナーブロックのスタイルです。
*/
.ec-bannersRole {
  @include container;
  max-width: $container-maxwidth3;

  @include containerMargin;

  // バナーリスト
  & &__list {

    @include media_sphone {
      @include shelfLayout(1,0px,10px);
    }

    @include media_tablet {
      @include shelfLayout(2,10px,10px);
    }

    @include media_desktop {
      @include shelfLayout(2,30px,30px);
    }
  }

  // バナーアイテム
  & &__item {
    position: relative;

    a {
      display: block;

      // hover時
      @include media_desktop {
        &:hover {
          .ec-bannersRole__image {

            img {
              transform: scale(1.1);
            }
          }

          .ec-bannersRole__content {
            background-color: rgba(0, 0, 0, 0.5);
          }

          // morebtn
          .morebtn {

            &:after {
              transform: translate3d(0.25em,0,0);
            }
          }
        }
      }
    }
  }

  // バナー画像
  & &__image {
    overflow: hidden;

    img {
      transition: 1s;
    }
  }

  // バナーコンテンツ
  & &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5%;
    pointer-events: none;
    transition: 1s;

    // バナータイトル
    .title {
      font-family: $ff-roboto;
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 1.2;
      color: #fff;

      @include media_desktop {
        font-size: 1.75rem;
      }
    }

    // バナーテキスト
    .text {
      font-family: $ff-roboto;
      font-size: .86rem;
      font-weight: 400;
      line-height: 1.2;
      color: #fff;
      margin: .75em 0;

      @include media_desktop {
        font-size: 1rem;//1.066rem;
      }
    }

    // moreボタン
    .morebtn {
      display: inline-block;
      font-family: $ff-roboto;
      font-size: 0.86rem;
      font-weight: 500;
      line-height: 1;
      padding: 1em 2em;
      padding-right: 3em;
      margin-top: 1rem;
      background-color: #fff;
      color: #333;
      transition: .3s;
      position: relative;// 「>」記号用

      &:after {
        font-family: jeiafont;
        content: "\e902";
        font-size: 1.2em;
        line-height: 1;
        position: absolute;
        top: calc(50% - 0.4em);
        right: 0.5em;
        transform: translate3d(0,0,0);
        transition: .3s;
      }

      @include media_desktop {
        font-size: .93rem;
      }
    }
  }
}
