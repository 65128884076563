///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。
*/
.ec-shelfRole {
  @include container;
  max-width: 100%;
}



/*
商品一覧グリッド

陳列レイアウト用のmixinを使用します。（_projects.scss）
@mixin shelfLayout($amount: 5, $gutter_w: 20px, $gutter_h: 20px, $item: li) {}
* $amount: 横に配置する数
* $gutter_W: アイテム間の左右余白
* $gutter_h: アイテム間の上下余白
* $item: 子要素のタグ名称・class名称（デフォルトはli要素）
*/
.ec-shelfGrid {

  // jsによりレイアウト「col_max値」をセットしますが完了までの一瞬に商品画像がフル幅で表示されてしまうので最初は非表示とし、jsにてclass「.ready」を付与し解除します。
  visibility: hidden;
  opacity: 0;

  // col_max値セット完了後に表示開始
  &.ready {
    visibility: visible;
    opacity: 1;
  }

  // 商品レイアウト（スマホ時は2列、タブレット時は3列で固定としています）
  @media ( max-width: 767px ) {
    @include shelfLayout(2, 15px, 20px);
  }

  @media ( min-width: 768px) and ( max-width: 979px ) {
    @include shelfLayout(3, 15px, 30px);
  }

  // 3列表示（980px以上で横に3列表示）
  &.col_max3 {
    @media ( min-width: 980px) {
      @include shelfLayout(3, 30px, 40px);
    }
  }

  // 4列表示（980px以上で横に4列表示）
  &.col_max4 {
    @media ( min-width: 980px) {
      @include shelfLayout(4, 20px, 40px);
    }
  }

  // 最大5列表示（980px～1279px時は4列、1280px以上で5列表示）
  &.col_max5 {
    @media ( min-width: 980px) and ( max-width: 1279px ) {
      @include shelfLayout(4, 20px, 40px);
    }

    @media ( min-width: 1280px) {
      @include shelfLayout(5, 20px, 40px);
    }
  }

  // 最大6列表示（980px～1279px時は4列、1280px~1679pxで5列、1680px以上で6列表示）
  &.col_max6 {
    @media ( min-width: 980px) and ( max-width: 1279px ) {
      @include shelfLayout(4, 20px, 40px);
    }

    @media ( min-width: 1280px) and ( max-width: 1679px ) {
      @include shelfLayout(5, 20px, 40px);
    }

    @media ( min-width: 1680px) {
      @include shelfLayout(6, 20px, 40px);
    }
  }
}



/*
商品アイテム

商品一覧だけでなく特集商品など、一覧表示される商品アイテムの共通スタイリングになります。
*/
.ec-productItemRole {
  height: 100%;
  display: flex;
  flex-flow: column;

  @include media_desktop {
    position: relative;// 商品属性タグ配置用
  }

  /* 商品画像ブロック */
  & &__image {
    position: relative;// 品切れ表示用

    min-height: 0%;// for IE11（flexでcolumnレイアウトした子要素に画像があるとスペースが開いてしまう対策）

    a {
      display: block;
      @include imageHover;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  /* 品切れ表示（商品画像ブロック上に被せる） */
  & &__stockout {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    font-family: $ff-en_thin;
    font-size: 1.2rem;
    padding: .8em;
    background-color: hsla(0, 100%, 37%, 0.8);
    color: #fff;
    text-align: center;
    pointer-events: none;

    @include media_sphone {
      font-size: 1rem;
      padding: .5em;
    }
  }

  /* 商品情報枠ブロック */
  & &__content {
    text-align: $productItemContentAlign;// コンテンツ自体のaling指定

    /* 一番最後にある商品情報のmargin-bottomを0にします */
    > *:last-child {
      margin-bottom: 0;
    }
  }

  /* 底配置ブロック */
  & &__bottom {
    //padding-top: 1em;// 必要に応じて有効にしてください
    margin: auto 0 0;
  }

  /* 商品名称 */
  & &__title {
    font-family: $ff-min;
    font-size: 1rem;
    font-weight: 400;
    margin: .85em 0 .3em;

    @include media_desktop {
      font-size: 1.25rem;
      margin: 1.25em 0 .5em;
    }

    //テキストが長くて改行される場合は左寄せにするためのspan
    span {
      display: inline-block;
      text-align: left;
    }
  }

  /* 商品コード */
  & &__code {
    font-size: 0.8rem;
    margin: 1em 0;
  }

  /* 商品属性タグ（商品画像の上に被せる JEIA4004） */
  & &__tags {
    margin: 0 0 10px;
    padding: 0;
    margin-left: -3px;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;

    .tag {
      border: solid 1px $clrBorderColor;
      margin: 0;
      margin-left: 3px;
      margin-bottom: 3px;
      border-radius: 3px;
      font-size: 0.8rem;
      padding: 0.4em 0.75em;
      line-height: 1;
      white-space: nowrap;
    }

    @include media_desktop {
      // 商品画像上に被せて配置
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 1;
      //pointer-events: none;

      .tag {
        background-color: #333;
        color: #fff;
        border: none;
        border-radius: 2px;
      }
    }
  }

  /* 商品説明（一覧用） */
  & &__description {
    font-size: 0.933rem;
    line-height: 1.5;
    margin: 1em 0;
    text-align: justify;
    word-wrap: break-word;

    // 行数制限
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;

    @include media_sphone {
      font-size: 0.833rem;
      line-height: 1.35;
    }
  }

  /* 通常価格 */
  & &__priceRegular {
    margin: 0.5em 0;
    text-align: $productItemPriceAlign;// 価格だけのalign指定

    .ec-price__label {
      font-size: 0.8rem;
    }

    .ec-price__price {
      font-size: 0.9rem;
    }

    .ec-price__tax {
      font-size: 0.7rem;
    }

    // 「.ec-price」の構造スタイリングは、_1.2.type.scssにて
  }

  /* 商品価格 */
  & &__price {
    line-height: 1.2;
    margin: .75em 0;
    text-align: $productItemPriceAlign;// 価格だけのalign指定

    .ec-price__label {
      font-size: 0.8rem;
    }

    .ec-price__price {
      font-size: 1.13rem;
    }

    .ec-price__tax {
      font-size: 0.7rem;
    }

    // 「.ec-price」の構造スタイリングは、_1.2.type.scssにて
  }
}



/*
商品アイテム内 カートインアクション

当テンプレートではコメントアウトにて除去している部分ですが、最低限のスタイリングをしております。
カートインアクション機能が必要な場合はlist.twigのカートイン部分とモーダル表示部分のコメントを解除しCSSスタイリングを調整してください。
*/
.ec-productItemRole {

  & &__actions {

    select {
      max-width: 100%;
    }
  }

  /* タイトルラベル */
  & &__label {
    margin: 0;
    margin-top: 1em;
  }

  /* 数量エリア */
  .quantityLabel {
    display: inline-block;
    vertical-align: baseline;
    margin-top: 0;
    margin-right: .5em;
  }
  .ec-quantityRole {
    margin-top: 1em;
    display: inline-flex;
    vertical-align: baseline;

    // スマホ用調整（「数量」のテキストラベルとボタンとが改行されず横に並ぶようボタンサイズを調整）
    @include media_sphone {
      $size: 2.65rem;

      .ec-quantityRole__btn {
        width: $size;
        height: $size;
        line-height: $size;
      }

      .ec-numberInput {
        height: $size;
        input {
          height: $size;
          width: $size;
        }
      }
    }
  }

  /* カートに入れるボタン */
  & &__btn {
    margin-top: 1em;

    .ec-blockBtn--cartin {
      $size: 3rem;
      height: $size;
      line-height: $size;
    }
  }
}
