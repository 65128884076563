///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
カテゴリヘッド

商品一覧ページの「カテゴリヘッドコンテンツ」のスタイルになります。
*/
.ec-categoryHeadRole {
  position: relative;

  // カテゴリヘッド画像
  & &__image {

    // 初期は非表示
    display: none;
    /*
    visibility: hidden;
    opacity: 0;
    transition: 1s;

    // 表示開始
    &.is-active {
      visibility: visible;
      opacity: 1;
    }
    */
  }

  // カテゴリヘッドコンテンツ
  & &__content {

    @include media_sphone {
      padding: $padding-sphone;
    }

    @include media_tablet {
      padding: $padding-tablet;
    }

    // デスクトップ時はコンテンツを画像に被せます
    @include media_desktop {
      position: absolute;
      top: calc(50% - 50px);
      left: 0;
      width: 100%;
      height: 50%;
    }

    /*
    // 初期は非表示
    visibility: hidden;
    opacity: 0;

    // 表示開始
    &.is-active {
      visibility: visible;
      opacity: 1;
    }
    */

    // カテゴリタイトル
    .title {
      font-family: $ff-roboto;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: 0.1em;
      text-align: center;
      margin: 0;

      @include media_sphone {
        font-size: 6vw;
        // タイトルだけを画像の中央に配置
        position: absolute;
        top: calc(285 / 750 * 50vw - 0.5em);
        left: 0;
        width: 100%;
        color: #fff;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      }

      @include media_tablet {
        font-size: 30px;
        // タイトルだけを画像の中央に配置
        position: absolute;
        top: calc(285 / 750 * 50vw - 0.5em);
        left: 0;
        width: 100%;
        color: #fff;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      }
      
      @include media_desktop {
        margin: 0;
        font-size: 70px;
        color: #fff;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
        margin-bottom: .5em;
      }
    }

    // 説明文
    .description {
      font-size: .93rem;

      @include media_sphone {
        //margin-top: 3vw;
      }

      @include media_tablet {
        //margin-top: 15px;
      }
      
      @include media_desktop {
        max-width: 58em;
        margin: 0 auto;
        color: #fff;
      }
    }
  }

  // カテゴリイメージ画像が無い場合（jsが判断してclass「.noimage」を付します）
  &.noimage {

    .ec-categoryHeadRole__image {
      display: none;
    }

    // カテゴリイメージが無い場合はコンテンツの画像被せを解除（スマホ・タブレット用）
    @media (max-width: ($desktop - 1)) {
      .ec-categoryHeadRole__content .title {
        position: static;
        text-shadow: none;
        color: $clrDefaultDark;
        margin-bottom: .75em;
      }
    }

    // カテゴリイメージが無い場合はコンテンツの画像被せを解除（デスクトップ用）
    @include media_desktop {
      .ec-categoryHeadRole__content {
        position: static;
        background: $clrBodyToneBg;
        padding: $padding-desktop;

        .title {
          font-size: 50px;// 画像被せの時より少し小さ目に
        color: $clrDefaultDark;
          text-shadow: none;
        }

        .description {
          color: $clrDefault;
        }
      }
    }
  }
}
