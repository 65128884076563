///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
Sass変数

_variables.scss

各パーツの色やサイズを変数として定義しています。
*/

/*
ヘッダーロゴ
*/
$headerLogoHeight: var(--headerLogoHeight, 70px);// ヘッダーロゴ画像の高さ（JEIA4004以降より少し小さ目）


/*
デバイス別設定
*/
// メディアクエリブレイクポイント
$sphone:  320px;
$tablet:  768px;
$desktop: 1280px;

// ルートフォントサイズ
$sphone-font-size:  14px;
$tablet-font-size:  15px;
$desktop-font-size: 15px;

// ヘッダー高さ
$sphone-header-height:  45px;
$tablet-header-height:  50px;
$desktop-header-height: 70px;// JEIA4004以降より少し小さ目
$headerBottomMargin:    var(--headerBottomMargin, 60px);// ヘッダーの下余白（デスクトップ時のヘッダーとメインコンテンツの余白）

// 余白（※左右余白用に設定してますがセクション間の上下余白にも使っています）
$padding-sphone:  6.25vw;//  ブラウザ幅320px時で余白20pxとし伸縮
$padding-tablet:  3.906vw;// ブラウザ幅768px時で余白30pxとし伸縮
$padding-desktop: 40px;//   余白を40px固定


// コンテナの最大幅
$container-maxwidth:  1280px;// カート、マイページ等のテキストメインのページ
$container-maxwidth2: 1480px;// 商品一覧・詳細といった少し広めにしたい場合のサイズ
$container-maxwidth3: 1680px;// TOPページのコンテンツの幅



/*
btn
*/
// 通常ボタン
$btn-default-bg:            var(--clrBtnDefaultBg, #242930);
$btn-default-color:         var(--clrBtnDefaultText, #fff);
$btn-default-border:        var(--clrBtnDefaultBg, #444);

// プライマリボタン（未使用）
$btn-primary-bg:            #5CB1B1;
$btn-primary-color:         #fff;

// アクションボタン
$btn-action-bg:             var(--clrBtnActionBg, #b20000);
$btn-action-color:          var(--clrBtnActionText, #fff);

// カートインボタン
$btn-cartin-bg:             var(--clrBtnCartinBg, #b20000);
$btn-cartin-color:          var(--clrBtnCartinText, #fff);

// お気に入りボタン
$btn-favorite-bg:           var(--clrBtnFavoriteBg, #f55);
$btn-favorite-color:        var(--clrBtnFavoriteText, #fff);

// キャンセルボタン（←本質はその他のグレー系アクションボタン。命名がよろしくない）
$btn-cancel-bg:             var(--clrBtnCancelBg, #333);
$btn-cancel-color:          var(--clrBtnCancelText, #fff);

// 無効状態
$btn-disabled-bg:           var(--clrBtnDisabledBg, #999);

// ボタン角丸め
$btn-border-radius-base:    0px;

// ボタン高さ（単位：rem推奨）
$btn-height:                3.33rem;

// 数量・カートインが横にセットされた部位の高さ（各部位、ボタンがこのサイズに揃う）
$cartinRow-size:            3.33rem;



/*
文字色・背景色
*/
// 基本背景色
$clrBodyBg:                 var(--clrBodyBg, #fff);

// 基本文字色
$clrDefault:                var(--clrDefault, #222);

// 基本文字色より少し濃いめ
$clrDefaultDark:            var(--clrDefaultDark, #000);

// 基本文字色より少し薄め
$clrDefaultThin:            var(--clrDefaultThin, #444);

// リンクテキスト
$clrLinkText:               var(--clrLinkText, #107aac);

// 赤文字
$clrRed:                    #e2251f;// カートや注文履歴で使用（エラーとは別の趣旨の赤文字）

// エラー
$clrError:                  var(--clrError, #ca0000);

// ボーダーカラー
$clrBorderColor:            var(--clrBorderColor, #ddd);

// アクティブカラー（カートプログレス、ページャ等の配色）
$active-color:              var(--clrActive, #000);// アクティブ
$noactive-color:            var(--clrNoactive, #ddd);//  非アクティブ

// ページヘッダーh1
$clrPageHeaderBg:           var(--clrPageHeaderBg,#f0f0f0);
$clrPageHeaderText:         var(--clrPageHeaderText, #222);

// 背景付き見出しh2
$clrRectHeadingBg:          var(--clrRectHeadingBg, #f3f1eb);
$clrRectHeadingText:        var(--clrRectHeadingText, #222);

// 表tableのストライプ模様、背景色のトーンを少し変化させる部位の色（body背景色より少しトーンの違う色を指定）​
$clrBodyToneBg:             var(--clrBodyToneBg, #f8f8f8);

// HELPナビ（HELP、マイページのナビボタン部分）
$clrHelpNavHover:           var(--clrHelpNavHover, #eee);
$clrHelpNavActiveBg:        var(--clrHelpNavActiveBg, #333);
$clrHelpNavActiveText:      var(--clrHelpNavActiveText, #fff);



/*
ヘッダー・ドロワー
*/
$clrHeaderBgRGB:            var(--clrHeaderBgRGB, 17, 18, 20);// ヘッダー背景色RGB ←#111214
$clrHeaderBgAlfa:           var(--clrHeaderBgAlfa, 0.5);// ヘッダー背景色透過率（TOPページではプロモーションにヘッダが被り背景色が完全透過となりますがhover時にうすく背景色をかける際の透過具合）
$clrHeaderText:             var(--clrHeaderText, #fff);// ヘッダー文字色
$clrHeaderMenuHoverBg:      var(--clrHeaderMenuHoverBg, rgba(255, 255, 255, 0.2));// ヘッダーメニューhover時背景色
$clrHeaderBurger:           var(--clrHeaderBurger, #fff);// バーガーアイコン色

$clrDrawerBase:             var(--clrDrawerBg, #111214);// ドロワー背景色
$clrDrawerText:             var(--clrDrawerText, #fff);// ドロワー文字色
$clrDrawerIcon:             var(--clrDrawerIcon, #aaa);// ドロワー内アイコン
$clrDrawerBorder:           var(--clrDrawerBorder, rgba(255, 255, 255, 0.4));// 各メニューの区切り線
$clrDrawerBorder2:          var(--clrDrawerBorder2, rgba(255, 255, 255, 0.3));// 開閉メニューOPEN時の左線

// ヘッダーポップアップ・商品カテゴリメニュー
$clrHeaderPopupBg:          #fff;// ポップアップ背景色
$clrHeaderPopupText:        #333;// ポップアップ文字色
$clrHeaderPopupHoverBg:     #f0f1f2;// ポップアップhover時背景色
$clrHeaderPopupBorder:      #eee;// ポップアップ区切り罫線



/*
フッター
*/
$clrFooterBase:             var(--clrFooterBg, #252525);// フッター背景色
$clrFooterText:             var(--clrFooterText, #aaa);// フッター文字色
/* JEIA4002では使いません
$clrFooterGuideBg:          var(--clrFooterGuideBg, #1f1f1f);// フッターガイド背景色
$clrFooterGuideText:        var(--clrFooterGuideText,#aaa);// フッターガイド文字色
*/
$clrFooterTitleText:        var(--clrFooterTitleText, #fff);// フッタータイトル文字色
$clrFooterBorder:           var(--clrFooterBorder, #555);// フッターボーダー線色
$clrCalendarHolidayBg:      var(--clrCalendarHolidayBg, #999);// カレンダー休日背景色
$clrCalendarHolidayText:    var(--clrCalendarHolidayText, #000);// カレンダー休日文字色
$clrCalendarTodayBg:        var(--clrCalendarTodayBg, #fff);// カレンダー本日背景色
$clrCalendarTodayText:      var(--clrCalendarTodayText, #000);// カレンダー本日文字色



/*
シェア・SNSカラー
*/
$clr-twitter:      #1B95E0;
$clr-facebook:     #3B5999;
$clr-googleplus:   #db4a39;
$clr-pinterest:    #cb2027;
$clr-instagram:    #f00075;
$clr-line:         #06c755;
$clr-pocket:       #EE4056;
$clr-hatena:       #00a4de;



/*
フォーム部品
*/
$input-color:               #333;// color
$input-bg:                  #fff;// background-color
$input-border:              var(--clrInputBorder, #ccc);// border-color
$input-border-radius:       0;// border-radius
$input-bg-disabled:         #f4f4f4;// disabled bg-color
$input-color-placeholder:   #bbb;// placeholder 文字色
$radio-checked-color:       var(--clrRadioChecked, #0049a3);// ラジオボタンUI チェック時カラー（checkbox兼用）
$input-border-focus:        var(--clrInputFocusBorder, #3c8dbc);// 入力要素のfocus時のborder線色
$clrInputFocusShadow:       var(--clrInputFocusShadow, #cceeff);// 入力要素のfocus時のshadow色
$focusShadowsize:           var(--focusShadowsize, 1px);

$input-error-bg:            #fff9f8;// エラー時 background-color
$input-error-border:        #e75353;// エラー時 border-color



/*
カート
*/
// カート背景色
$cart-bgcolor:              var(--clrCartToneBg, #f3f1eb);// カートテーブルヘッダー背景、数量変更ボタン等のカート予備アクション背景（さし色的なバランス）

// カートエラー背景色
$cart-error-bgcolor:        #ff9999;

// ショッピングフロー
$clrCartTotalBoxBg:         var(--clrCartTotalBoxBg, #f8f8f8);// トータルボックス背景色
$clrCartTotalBoxText:       var(--clrCartTotalBoxText, #444);// トータルボックス文字色



/*
商品一覧
*/
:root {
  --shelfCol: 5;// 商品一覧ページの商品レイアウト数 最大5列表示（980px～1279px時は4列、1280px以上で5列表示）
  --shelfColRelated: 5;// 商品詳細ページの関連商品（関連商品プラグイン）の商品レイアウト数
}

// 商品アイテム情報欄のalign指定
$productItemContentAlign:   var(--productItemContentAlign, center);// 商品アイテム情報欄のalign指定
$productItemPriceAlign:     var(--productItemPriceAlign, center);// 価格部分だけをalign指定したい場合



/*
ヘッドインフォメーション
*/
$clrHeadInfomationBg:       var(--clrHeadInfomationBg, #000);// 背景色
$clrHeadInfomationText:     var(--clrHeadInfomationText, #F7F6F4);// 文字色
$HeadInfomationFontSize:    var(--HeadInfomationFontSize, 0.86rem);// フォントサイズ






// Custom easing functions.
// http://easings.net/ja

// sine
$ease-in-sine:cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out-sine:cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-sine:cubic-bezier(0.445, 0.05, 0.55, 0.95);

// quad
$ease-in-quad:cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad:cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad:cubic-bezier(0.455, 0.03, 0.515, 0.955);

// cubic
$ease-in-cubic:cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic:cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic:cubic-bezier(0.645, 0.045, 0.355, 1);

// quart
$ease-in-quart:cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart:cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart:cubic-bezier(0.77, 0, 0.175, 1);

// quint
$ease-in-quint:cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quint:cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint:cubic-bezier(0.86, 0, 0.07, 1);

// expo
$ease-in-expo:cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo:cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-expo:cubic-bezier(1, 0, 0, 1);

// circ
$ease-in-circ:cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ:cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-circ:cubic-bezier(0.785, 0.135, 0.15, 0.86);

// back
$ease-in-back:cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back:cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back:cubic-bezier(0.68, -0.55, 0.265, 1.55);
