///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
商品一覧ヘッダーナビ

商品一覧ページのカテゴリトピックパスや、検索結果、また並び替えフォームをまとめたコンポーネントになります。
*/
.ec-searchnavRole{
  @include container;
  max-width: 100%;
  background-color: $clrBodyToneBg;

  // スマホ
  @include media_sphone {
    padding: 1rem $padding-sphone;
    margin-bottom: $padding-sphone;
  }

  // タブレット
  @include media_tablet {
    padding: 1rem $padding-tablet;
    margin-bottom: $padding-tablet;
  }

  // デスクトップ
  @include media_desktop {
    background-color: transparent;
    padding: 0 $padding-desktop;
    margin-top: $padding-desktop * 0.5;
    margin-bottom: $padding-desktop * 1.25;
  }

  // パンくず格納枠
  & &__topicpath {
    margin-bottom: .5rem;
  }

  // 商品件数、絞り込みボタン格納枠
  & &__infos{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    border-top: 0;
    padding: 0;
    line-height: 1;

    @include media_desktop {
      flex-direction: column;
    }
  }

  // 表示件数
  & &__counter{
    width: auto;
    font-size: .8rem;
    font-family: $ff-roboto;
    font-weight: 500;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include media_tablet {
      font-size: 1rem;
    }

    @include media_desktop {
      justify-content: center;
      margin-bottom: 1.5rem;
      text-align: center;
      font-size: 1.125rem;
    }

    span {
      font-size: 160%;

      @include media_desktop {
        font-size: 155%;
      }
    }
  }

  & &__actions{
    text-align: right;

    @include media_desktop {
      text-align: center;
    }

    .ec-select {
      margin-bottom: 0;
      white-space: nowrap;

      &-label {
        display: none;

        @include media_desktop {
          display: inline-block;
          margin: 0 0 0 1em;
          font-weight: normal;

          &:first-child {
            margin: 0;
          }
        }
      }

      select {
        background-color: #fff;
      }
    }
  }
}



/*
トピックパス本体
*/
.ec-topicpath {
  line-height: 1;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  & &__item {

    // スマホ時などパスが長くて改行されてしまうケースを考慮
    white-space: nowrap;
    padding: 4px 0;

    a {
      color: inherit;
      @include hoverBottomLine;
    }

    /* 連結の「>」 */
    &:before {
      font-family: "jeiafont";
      content: "\e902";
      font-size: 0.85em;
      margin-left: 4px;
    }

    /* タイトルラベル */
    &.titleLabel {
      font-family: $ff-en_thin;

      &:before {
        display: none;
      }
    }

    /* 検索キーワード */
    &.is-search {

      span {
        margin-left: 0.2em;// 微調整
      }
    }

    /* 最後のli要素を太字 */
    &:last-child {

      a,
      span {
        font-weight: 500;
        pointer-events: none;
      }
    }
  }
}
