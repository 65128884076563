///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
おすすめ商品管理プラグイン

※当テンプレートではカスタマイズした「Block/recommend_product_block.twig」を使用しています。
*/
.ec-recommendProductRole {
  @include container;
  max-width: $container-maxwidth3;//100%;

  @include containerMargin;

  @include media_desktop {
    // slickのprev,nextボタンの余白分、少し大きく確保します。
    $gap: 60px;
    padding-left:  $gap;
    padding-right: $gap; 
  }

  & &__listInner {
  }

  // 商品アイテムスタイルを上書き（商品一覧スタイルは13.2.shelf.scssにて定義されています。当ブロック用に上書き調整します）
  .ec-productItemRole {

    .ec-productItemRole__content {
    }

    // おすすめ商品コメント
    .ec-productItemRole__recommendComment {
      // 商品一覧説明文のスタイルを@extend
      @extend .ec-productItemRole__description;
    }
  }
}
