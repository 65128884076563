@charset "UTF-8";
/*
///////////////////////////////////////////////////////////////////////

This template is part of EC-CUBE Design template.

Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.

http://www.jeia.co.jp/

For the full copyright and license information, please view the LICENSE
file that was distributed with this source code.

本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。

※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
別途、ご利用費用をお支払いただく必要がございます。
※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
※本テンプレートは、すべての設置環境で動作する事は保証しておりません。

///////////////////////////////////////////////////////////////////////
*/
@import url("../../../../../node_modules/normalize.css/normalize.css");
/*
Sass変数

_variables.scss

各パーツの色やサイズを変数として定義しています。
*/
/*
ヘッダーロゴ
*/
/*
デバイス別設定
*/
/*
btn
*/
/*
文字色・背景色
*/
/*
ヘッダー・ドロワー
*/
/*
フッター
*/
/* JEIA4002では使いません
$clrFooterGuideBg:          var(--clrFooterGuideBg, #1f1f1f);// フッターガイド背景色
$clrFooterGuideText:        var(--clrFooterGuideText,#aaa);// フッターガイド文字色
*/
/*
シェア・SNSカラー
*/
/*
フォーム部品
*/
/*
カート
*/
/*
商品一覧
*/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
:root {
  --shelfCol: 5;
  --shelfColRelated: 5;
}

/*
ヘッドインフォメーション
*/
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #333;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #333;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #333;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #333;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}
.btn-link:hover {
  color: #0a58ca;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #0a58ca;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}
.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0d6efd;
}
.link-primary:hover, .link-primary:focus {
  color: #0a58ca;
}

.link-secondary {
  color: #6c757d;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
/*
アニメーションkeyframes

_animation.scss

定義mixin：<br>
@mixin fadeIn<br>
@mixin fadeOut<br>

<br>
定義CSS：<br>
@keyframes fadeIn<br>
@keyframes fadeOut<br>
.bg-load-overlay
*/
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}

/*
ボタン

_btn.scss
*/
/*
ボタンひな形

* @mixin _btn($color, $background, $border)
*/
/*
インラインボタン

* @mixin btn-default
* @mixin btn-action
* @mixin btn-cancel
* @mixin btn-primary
*/
/*
ブロックボタン

* @mixin blockBtn-default
* @mixin blockBtn-action
* @mixin blockBtn-cancel
* @mixin blockBtn-primary
*/
/*
User select

ボタンひな形（@mixin _btn()）から使用されてるmixinで、テキストを選択不可にしている様子。
ほかからの使用は無い様子。

* @mixin user-select
*/
/*
リンクボタン

使用箇所は無い様子

* @mixin linkBtn
*/
/*
Clearfix

_clearfix.scss
*/
/*
フォーム

_form.scss
*/
/*
プレースホルダー

* @mixin placeholder
*/
/*
フォームリセット

* @mixin forms-reset
*/
/*
フォームコントールひな形

* @mixin _form-control
*/
/*
メディア

_media.scss
*/
/*
メディアクエリ

* @mixin media_desktop

EC-CUBE4標準ではモバイルファースト（スマートフォン）でスタイリングしたのち適宜、デスクトップ用にメディアクエリで上書きする形でスタイリングしている様子ですが、
当テンプレートではタブレットも加えた3デバイスのスタイリングしています。

スタイリングの仕方はケースバイケースで、上書きや汚染が起きないようメディアクエリの内容を範囲を大きくとってがっつり分離してスタイリングする場合や、
一度、デスクトップ用（あるいはスマートフォン用）のスタイリングを施してから、適宜上書き用のメディアクエリを書く場合もあります。

メディアクエリに関しては、後からの変更・修正の際に「ごちゃごちゃしてどこをいじればいいのか判りづらい」コードにならないよう気を付けたいと思っています。
ですので上書きの多用は避けたいとしております、が、上書きスタイリングのほうがコードがすっきりするならそのようにします。ケースバイケースです。

* スマホ：320px～767px
* タブレット：768px～1279px
* PC：1280px以上

※デバイスごとのブレイクポイントは_variables.scssにて変数で設定。
*/
/*
コンテナ

コンテナブロックのmax-width、左右余白などブロックスタイルの共通定義。
各セクションの左右余白の統一感はコンテナによってもたらされます。

※ブラウザのフル幅で背景を敷いたデザインも可能とするため、`.ec-layoutRole__contents`側のmax-widthを解除し、こちらの「コンテナ」でmax-widthを定義。
コンテナを適用するセクションでmax-widthを変更したい場合は、そのコンテナで随時上書きします。

※コンテナの左右余白については、コンテナを「box-sizing:content-box;」としておいてから、希望のままの値でmax-widthを指定し、デバイスごとの左右余白を定義。

EC-CUBE4ではマイページ用に調整されたコンテナがありましたが、当テンプレートではマイページも汎用コンテナでスタイリングしています。

* @mixin container
*/
/*
コンテナの上下マージン

サイト内各コンテナの余白に統一感を持たせるために用意したmixinで、単純に上下余白を定義した内容となります。
コンテナのデザインレイアウトによっては上書き調整される事を想定しています。
*/
/*
コンテナの上下マージンをpaddingで実装する場合

コンテナが背景色を持っている場合はこちらを使用します。
*/
/*
ECCUBE 固有のスタイルユーティリティ

_projects.scss

* @mixin borderTop
* @mixin borderBottom
* @mixin reset_link

borderTop, borderBottomは、サイト内で一貫したborder線を敷くために使います。
一応、引数で色も指定できますが、一貫とは別種のborderなら直接borderプロパティでスタイリングしたほうがいいとは思います。

reset_linkは、.ec-link（リンクテキストの表現）をリセットさせる目的のmixinでしょうか。当テンプレートではその場その場で、
同等のスタイリングをしていますので、結果このmixinは使用していません。
*/
/* 以下はEC-CUBE4.2.0標準に記述されているコードですが、当テンプレートでは「_form.scss」に準備したものがあるのでこちらは使用しません。
@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus {
    border-color: $color;
    outline: 0;
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $color-rgba);
  }
}
*/
/* 以下はEC-CUBE4.2.0標準に記述されているコードですが、当テンプレートでは当「_project.scss」に準備したものがあるのでこちらは使用しません。
@mixin tab-focus() {
  // WebKit-specific. Other browsers will keep their default outline style.
  // (Initially tried to also force default via `outline: initial`,
  // but that seems to erroneously remove the outline in Firefox altogether.)
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
*/
/* 以下はEC-CUBE4.2.0標準に記述されているコードですが、当テンプレートでは使用していません。
@mixin opacity($opacity) {
  $opacity-ie: ($opacity * 100);  // IE8 filter
  filter: alpha(opacity=$opacity-ie);
  opacity: $opacity;
}
*/
/*
追加mixin

当テンプレート用にmixinを追加しています。
*/
/*
offGridを無効にするmixin

テンプレート上、レイアウト的に幅狭め（offGird）を無効にしたい場合に適用

※offGrid自体の機能は崩さずそのまま残しておきたいので、部分的に挙動を無効化するmixinを用意。
※offGrid自体の挙動は変更していないが、タブレット時の挙動は別途追加している。
↓
結果的にoffGridの挙動を変更する形にした
*/
/*
画像マウスオーバー

マウスオーバーで画像を拡大・明度上げします。
mixinはa要素に適用してください。
*/
/*
画像マウスオーバー（カラーオーバーレイ）

マウスオーバーで画像にカラーフィルターをオーバーレイします。
mixinはa要素に適用してください。

* $color: フィルターの色
*/
/*
横並びメニューのボタンに短い区切り線を入れる

マイページのメニュー部分に使用しています。

* $length: ボーダー長さ（%のみ）
* $color: ボーダー色
*/
/*
商品一覧等の陳列レイアウト

商品一覧やお気に入り一覧など子アイテム（商品）を陳列レイアウト（縦横に並べる）スタイリングを施します。
横に配置する数、アイテム間の余白を引数で指定できます。

商品詳細の画像ビジュアルナビでも使用しています。

* $amount: 横に配置する数
* $gutter_W: アイテム間の左右余白
* $gutter_h: アイテム間の上下余白
*/
/*
閉じる・削除ボタン

ヘッダーのカート状況のカート削除ボタン、マイぺージお気に入り削除ボタンなど、画像の上に被さるタイプの丸型の削除系ボタンです。

* $size ボタン自体のサイズを指定
*/
/*
テキストhoverで下線が伸びるエフェクト

* $color: 下線の色
*/
/*
ユーザビリティ タブフォーカス

タブフォーカスのデザインをスタイリングします。
*/
/*
ブラウザハック IE11
*/
/*
商品アイテム マウスオーバーアクション

商品名称や価格表示を包含する商品情報要素を最初に非表示にしておき、商品画像にマウスオーバーしたときに商品情報をオーバーレイ表示させるスタイリングです。

商品一覧ページで使用している商品アイテム「.ec-productItemRole」（_13.2.shelf.scss）を上書きスタイリングとなりますので、
商品一覧と同じ商品アイテム「.ec-productItemRole」の中でのみ動作します。
デスクトップ時のみ適用されるようにメディアクエリの中で「.ec-productItemRole」要素に対してこのmixinを適用してください。
*/
/*
折り畳み非表示
*/
/*
ベース
*/
@media only screen and (max-width: 767px) {
  html {
    font-size: 3.7333333333vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  html {
    font-size: 15px;
  }
}
@media only screen and (min-width: 1280px) {
  html {
    font-size: 15px;
  }
}

body {
  background: transparent;
  background-color: var(--clrBodyBg, #fff);
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--clrDefault, #222);
}

a,
a:not([href]):not([class]) {
  color: var(--clrLinkText, #107aac);
  text-decoration: none;
  transform: 0.3s;
  cursor: pointer;
}
a:focus,
a:not([href]):not([class]):focus {
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
pre {
  /*
  background-color: transparent;
  border: none;
  padding: 16px 0;
  */
}

p {
  margin-bottom: 1em;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: var(--clrDefaultDark, #000);
  line-height: 1.2;
  font-weight: 500;
}

img {
  width: 100%;
  height: auto;
}

label {
  margin: 0;
  font-weight: normal;
}

.alt {
  display: none;
}

/*
表組み（シンプル）

table要素、dl要素による表組レイアウト

商品詳細ページのフリーエリアや利用ガイド等の記事の中で差し込まれてくるシンプルな表組みについて基本スタイリングします。

特商や会員登録フォームといったコンテンツの表組みレイアウトは、_1.3.list.scssにて定義しています。
*/
/*
.table_basic 
*/
table.table_basic {
  width: 100%;
  table-layout: fixed;
  font-size: 0.933rem;
  margin: 2rem 0;
  border-top: 1px solid var(--clrBorderColor, #ddd);
}
table.table_basic tr {
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
}
table.table_basic tr:nth-child(odd) {
  background-color: var(--clrBodyToneBg, #f8f8f8);
}
table.table_basic th {
  padding: 0.5em;
  width: 10em;
  white-space: nowrap;
  vertical-align: top;
}
table.table_basic td {
  padding: 0.5em;
  width: auto;
  vertical-align: top;
}

/* .caption */
.caption {
  margin: 0;
  padding: 0.5em 0;
  font-size: 0.933rem;
  color: var(--clrDefaultThin, #444);
}

/*
dl要素をtable風に
*/
.dl_table {
  width: 100%;
  font-size: 0.933rem;
  margin: 2rem 0;
}
.caption + .dl_table {
  margin-top: 0;
}
.dl_table dl {
  margin: 0;
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
}
.dl_table dl:nth-child(odd) {
  background-color: var(--clrBodyToneBg, #f8f8f8);
}
.dl_table dl:first-child {
  border-top: 1px solid var(--clrBorderColor, #ddd);
}
.dl_table dt {
  display: inline-block;
  margin: 0;
  padding: 0.5em;
  min-width: 10em;
}
.dl_table dd {
  display: inline-block;
  margin: 0;
  padding: 0.5em;
}

/*
dl要素レスポンシブ（タブレット・デスクトップ時はtable風で、スマホ時はdtとddを改行して表示）
*/
@media only screen and (max-width: 767px) {
  .dl_table.rwd dl {
    margin: 0;
    border: none !important;
    background-color: transparent !important;
  }
  .dl_table.rwd dt {
    display: block;
    background-color: var(--clrBodyToneBg, #f8f8f8);
  }
  .dl_table.rwd dd {
    display: block;
    padding: 0.75em 0;
  }
}

/*
SP判定
*/
#issp {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  display: block;
}
@media only screen and (min-width: 1280px) {
  #issp {
    display: none;
  }
}

/*
文字列分割
*/
.split-txt span {
  display: inline-block;
  opacity: 0;
  transform: translateY(0.25em);
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.visible .split-txt span {
  opacity: 1;
  transform: translateY(0em);
}
.visible .split-txt span:nth-child(1) {
  transition-delay: 150ms;
}
.visible .split-txt span:nth-child(2) {
  transition-delay: 200ms;
}
.visible .split-txt span:nth-child(3) {
  transition-delay: 250ms;
}
.visible .split-txt span:nth-child(4) {
  transition-delay: 300ms;
}
.visible .split-txt span:nth-child(5) {
  transition-delay: 350ms;
}
.visible .split-txt span:nth-child(6) {
  transition-delay: 400ms;
}
.visible .split-txt span:nth-child(7) {
  transition-delay: 450ms;
}
.visible .split-txt span:nth-child(8) {
  transition-delay: 500ms;
}
.visible .split-txt span:nth-child(9) {
  transition-delay: 550ms;
}
.visible .split-txt span:nth-child(10) {
  transition-delay: 600ms;
}
.visible .split-txt span:nth-child(11) {
  transition-delay: 650ms;
}
.visible .split-txt span:nth-child(12) {
  transition-delay: 700ms;
}
.visible .split-txt span:nth-child(13) {
  transition-delay: 750ms;
}
.visible .split-txt span:nth-child(14) {
  transition-delay: 800ms;
}
.visible .split-txt span:nth-child(15) {
  transition-delay: 850ms;
}
.visible .split-txt span:nth-child(16) {
  transition-delay: 900ms;
}
.visible .split-txt span:nth-child(17) {
  transition-delay: 950ms;
}
.visible .split-txt span:nth-child(18) {
  transition-delay: 1000ms;
}
.visible .split-txt span:nth-child(19) {
  transition-delay: 1050ms;
}
.visible .split-txt span:nth-child(20) {
  transition-delay: 1100ms;
}
.visible .split-txt span:nth-child(21) {
  transition-delay: 1150ms;
}
.visible .split-txt span:nth-child(22) {
  transition-delay: 1200ms;
}
.visible .split-txt span:nth-child(23) {
  transition-delay: 1250ms;
}
.visible .split-txt span:nth-child(24) {
  transition-delay: 1300ms;
}
.visible .split-txt span:nth-child(25) {
  transition-delay: 1350ms;
}
.visible .split-txt span:nth-child(26) {
  transition-delay: 1400ms;
}
.visible .split-txt span:nth-child(27) {
  transition-delay: 1450ms;
}
.visible .split-txt span:nth-child(28) {
  transition-delay: 1500ms;
}
.visible .split-txt span:nth-child(29) {
  transition-delay: 1550ms;
}
.visible .split-txt span:nth-child(30) {
  transition-delay: 1600ms;
}
.visible .split-txt span:nth-child(31) {
  transition-delay: 1650ms;
}
.visible .split-txt span:nth-child(32) {
  transition-delay: 1700ms;
}
.visible .split-txt span:nth-child(33) {
  transition-delay: 1750ms;
}
.visible .split-txt span:nth-child(34) {
  transition-delay: 1800ms;
}
.visible .split-txt span:nth-child(35) {
  transition-delay: 1850ms;
}
.visible .split-txt span:nth-child(36) {
  transition-delay: 1900ms;
}
.visible .split-txt span:nth-child(37) {
  transition-delay: 1950ms;
}
.visible .split-txt span:nth-child(38) {
  transition-delay: 2000ms;
}
.visible .split-txt span:nth-child(39) {
  transition-delay: 2050ms;
}
.visible .split-txt span:nth-child(40) {
  transition-delay: 2100ms;
}
.visible .split-txt span:nth-child(41) {
  transition-delay: 2150ms;
}
.visible .split-txt span:nth-child(42) {
  transition-delay: 2200ms;
}
.visible .split-txt span:nth-child(43) {
  transition-delay: 2250ms;
}
.visible .split-txt span:nth-child(44) {
  transition-delay: 2300ms;
}
.visible .split-txt span:nth-child(45) {
  transition-delay: 2350ms;
}
.visible .split-txt span:nth-child(46) {
  transition-delay: 2400ms;
}
.visible .split-txt span:nth-child(47) {
  transition-delay: 2450ms;
}
.visible .split-txt span:nth-child(48) {
  transition-delay: 2500ms;
}
.visible .split-txt span:nth-child(49) {
  transition-delay: 2550ms;
}
.visible .split-txt span:nth-child(50) {
  transition-delay: 2600ms;
}
.visible .split-txt span:nth-child(51) {
  transition-delay: 2650ms;
}
.visible .split-txt span:nth-child(52) {
  transition-delay: 2700ms;
}
.visible .split-txt span:nth-child(53) {
  transition-delay: 2750ms;
}
.visible .split-txt span:nth-child(54) {
  transition-delay: 2800ms;
}
.visible .split-txt span:nth-child(55) {
  transition-delay: 2850ms;
}
.visible .split-txt span:nth-child(56) {
  transition-delay: 2900ms;
}
.visible .split-txt span:nth-child(57) {
  transition-delay: 2950ms;
}
.visible .split-txt span:nth-child(58) {
  transition-delay: 3000ms;
}
.visible .split-txt span:nth-child(59) {
  transition-delay: 3050ms;
}
.visible .split-txt span:nth-child(60) {
  transition-delay: 3100ms;
}
.visible .split-txt span:nth-child(61) {
  transition-delay: 3150ms;
}
.visible .split-txt span:nth-child(62) {
  transition-delay: 3200ms;
}
.visible .split-txt span:nth-child(63) {
  transition-delay: 3250ms;
}
.visible .split-txt span:nth-child(64) {
  transition-delay: 3300ms;
}
.visible .split-txt span:nth-child(65) {
  transition-delay: 3350ms;
}
.visible .split-txt span:nth-child(66) {
  transition-delay: 3400ms;
}
.visible .split-txt span:nth-child(67) {
  transition-delay: 3450ms;
}
.visible .split-txt span:nth-child(68) {
  transition-delay: 3500ms;
}
.visible .split-txt span:nth-child(69) {
  transition-delay: 3550ms;
}
.visible .split-txt span:nth-child(70) {
  transition-delay: 3600ms;
}
.visible .split-txt span:nth-child(71) {
  transition-delay: 3650ms;
}
.visible .split-txt span:nth-child(72) {
  transition-delay: 3700ms;
}
.visible .split-txt span:nth-child(73) {
  transition-delay: 3750ms;
}
.visible .split-txt span:nth-child(74) {
  transition-delay: 3800ms;
}
.visible .split-txt span:nth-child(75) {
  transition-delay: 3850ms;
}
.visible .split-txt span:nth-child(76) {
  transition-delay: 3900ms;
}
.visible .split-txt span:nth-child(77) {
  transition-delay: 3950ms;
}
.visible .split-txt span:nth-child(78) {
  transition-delay: 4000ms;
}
.visible .split-txt span:nth-child(79) {
  transition-delay: 4050ms;
}
.visible .split-txt span:nth-child(80) {
  transition-delay: 4100ms;
}
.visible .split-txt span:nth-child(81) {
  transition-delay: 4150ms;
}
.visible .split-txt span:nth-child(82) {
  transition-delay: 4200ms;
}
.visible .split-txt span:nth-child(83) {
  transition-delay: 4250ms;
}
.visible .split-txt span:nth-child(84) {
  transition-delay: 4300ms;
}
.visible .split-txt span:nth-child(85) {
  transition-delay: 4350ms;
}
.visible .split-txt span:nth-child(86) {
  transition-delay: 4400ms;
}
.visible .split-txt span:nth-child(87) {
  transition-delay: 4450ms;
}
.visible .split-txt span:nth-child(88) {
  transition-delay: 4500ms;
}
.visible .split-txt span:nth-child(89) {
  transition-delay: 4550ms;
}
.visible .split-txt span:nth-child(90) {
  transition-delay: 4600ms;
}
.visible .split-txt span:nth-child(91) {
  transition-delay: 4650ms;
}
.visible .split-txt span:nth-child(92) {
  transition-delay: 4700ms;
}
.visible .split-txt span:nth-child(93) {
  transition-delay: 4750ms;
}
.visible .split-txt span:nth-child(94) {
  transition-delay: 4800ms;
}
.visible .split-txt span:nth-child(95) {
  transition-delay: 4850ms;
}
.visible .split-txt span:nth-child(96) {
  transition-delay: 4900ms;
}
.visible .split-txt span:nth-child(97) {
  transition-delay: 4950ms;
}
.visible .split-txt span:nth-child(98) {
  transition-delay: 5000ms;
}
.visible .split-txt span:nth-child(99) {
  transition-delay: 5050ms;
}
.visible .split-txt span:nth-child(100) {
  transition-delay: 5100ms;
}

/*
フォントファミリー
*/
body {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
}

button, input, select, textarea {
  font-family: "Noto Sans JP", sans-serif;
}

/*
見出し

ページ内で見出しとして機能する要素のスタイル群です。
*/
/*
商品詳細ページ見出し（未使用）

EC-CUBE4では商品詳細ページのみ見出しに使用されているスタイルですが、当テンプレートでは使用していません。
*/
.ec-headingTitle {
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: normal;
  color: #525263;
}

/*
ページヘッダ（h1見出し）

各種ページで用いられるページヘッダのデザインです。

EC-CUBE4のHTML構成でページヘッダ見出しは「コンテナ」の中に含まれており、コンテナには左右余白が定義されています。<br>
当テンプレートではh1見出しには背景色を敷いたデザインですが、スマホ・タブレット時ではブラウザ幅フルにフィットさせたいので、
これをネガティブマージンを用いて左右余白を相殺することでスタイリングしています。

もし背景色を敷かないデザインに変更するのであればネガティブマージンによる余白相殺と余白の再定義は不要です。

h1要素に下マージンを持たせて、h1タイトル部とページコンテンツ本体との余白としています。
*/
.ec-pageHeader h1, .ec-pageHeader .h1 {
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  margin: 0;
  background-color: var(--clrPageHeaderBg, #f0f0f0);
  color: var(--clrPageHeaderText, #222);
  margin-bottom: 1.5em;
}
@media only screen and (max-width: 767px) {
  .ec-pageHeader h1, .ec-pageHeader .h1 {
    margin-left: -6.25vw;
    margin-right: -6.25vw;
    padding: 0.85em 6.25vw;
    font-size: 1.2rem;
    margin-bottom: 1em;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-pageHeader h1, .ec-pageHeader .h1 {
    margin-left: -3.906vw;
    margin-right: -3.906vw;
    padding: 0.85em 3.906vw;
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-pageHeader h1, .ec-pageHeader .h1 {
    padding: 0.75em 0.75em;
    font-size: 1.3em;
  }
}

/*
サブ見出し（未使用）

文字主体のページで用いられるサブ見出しです。

後述の「.ec-heading-bold」に対して太字でないスタイリングという位置づけでしょうか。
使用箇所はありませんが一応、スタイリングとして調整して残しておきます。
*/
.ec-heading {
  font-weight: 1.1rem;
  font-weight: normal;
  margin-bottom: 1em;
}

/*
サブ見出し(太字)

文字主体のページで用いられるサブ見出しの太字のスタイルです。

EC-CUBE4では「プライバシーポリシー」の太字見出しで使用されているスタイルですが、当テンプレートでは規約条文用のコンポーネントを用意しましたので、
結果、使用箇所なしとなります。
*/
.ec-heading-bold {
  font-weight: 1.1rem;
  font-weight: 500;
  margin-bottom: 1em;
}

/*
背景付き見出し

h2の見出し要素を装飾するスタイリングです。<br>
h3以降の使用箇所はありません。使う場面があればh3以降も追加スタイリングしてください。
*/
.ec-rectHeading {
  margin: 0;
  margin-bottom: 1rem;
}
.ec-rectHeading h2, .ec-rectHeading .h2 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: normal;
  background-color: var(--clrRectHeadingBg, #f3f1eb);
  color: var(--clrRectHeadingText, #222);
  padding: 0.75em 0.75em;
}

/*
メッセージ見出し

ユーザが行った操作に対する、完了報告やエラー表示のページで使用される見出しのスタイルです。
*/
.ec-reportHeading {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 1em;
  padding: 1em 0;
  text-align: center;
  border-top: 1px solid var(--clrBorderColor, #ddd);
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
}
.ec-reportHeading h2, .ec-reportHeading .h2 {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
}
@media only screen and (max-width: 767px) {
  .ec-reportHeading {
    text-align: left;
  }
}

/*
文字装飾

文字装飾をするためのスタイル群です。
*/
/*
テキストリンク

テキストリンクのスタイルです。

※ログインページ（Mypage,Shopping）では上書き調整しておりますのでこちらのスタイリングは反映されない形です。
*/
.ec-link {
  text-decoration: none;
  cursor: pointer;
}
.ec-link:hover {
  text-decoration: none;
}

/*
テキスト（太字）

テキストを太くするためのスタイルです。
*/
.ec-font-bold {
  font-weight: 500;
}

/*
テキスト（グレー）（未使用）

テキストをグレーにするためのスタイルです。
*/
.ec-color-grey {
  color: gray;
}

/*
テキスト（赤）

テキストを赤にするためのスタイルです。
*/
.ec-color-red {
  color: #e2251f;
}

.ec-color-accent {
  color: #e2251f;
}

/*
フォントサイズ（未使用）

フォントサイズを指定するためのスタイルです。
EC-CUBE4ではカートブロックで使用しているようですが、当テンプレートでは未使用です。
*/
.ec-font-size-1 {
  font-size: 12px;
}

.ec-font-size-2 {
  font-size: 14px;
}

.ec-font-size-3 {
  font-size: 16px;
}

.ec-font-size-4 {
  font-size: 20px;
}

.ec-font-size-5 {
  font-size: 32px;
}

.ec-font-size-6 {
  font-size: 40px;
}

/*
テキスト水平位置

テキストをセンタリングするためのスタイルです。
*/
.ec-text-ac {
  text-align: center;
}

/*
価格テキスト

価格表示を構成するスタイリングです。
構成としては、「通常価格」「販売価格」のラベルテキスト、価格部分、「税込」のテキスト、になります。
ここでは各テキスト部位をインラインで配置するスタイリングに留めています。
各テキスト部位ごとのフォントサイズ調整は、「_13.2.shelf.scss」「_14.1.product.scss」側にてスタイリングします。
「販売価格」と「通常価格」で価格部分のフォントサイズは変化をつけたいが、ラベルと税込みテキストは合わせておきたい等の要望はあるかと思い、
そのようにしたほうが調整がしやすいと判断しました。
*/
.ec-price {
  /* 「通常価格」「販売価格」のラベルテキスト */
  /* 価格本体 */
  /* 「税込」のテキスト */
}
.ec-price .ec-price__label {
  display: inline-block;
  vertical-align: baseline;
  margin-right: 0.5em;
}
.ec-price .ec-price__price {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  display: inline-block;
  vertical-align: baseline;
}
.ec-price .ec-price__tax {
  display: inline-block;
  vertical-align: baseline;
}

/*
テキストの位置

テキストや、入れ子にしたインライン要素を「左揃え」「中央揃え」「右揃え」に設定することができます。
*/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/*
メッセージテキスト

ユーザが行った操作に対する、完了報告やエラー表示のページで使用されるテキストのスタイルです。
*/
.ec-reportDescription {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 2em;
  text-align: left;
}
@media only screen and (min-width: 1280px) {
  .ec-reportDescription {
    text-align: center;
  }
}

/*
テキスト下部のスペース

テキストの下に余白を追加することができます。
*/
.ec-para-normal {
  margin-bottom: 1em;
}

/*
箇条書きスタイル

利用ガイド等で使用する箇条書きスタイルです。各リストマークのパターンを用意しています。
箇条書きスタイルはdl要素に対して適用してください。
*/
/* 箇条書き：ベーシック */
.ec-list, .ec-list-icon, .ec-list-decimal, .ec-list-disc, .ec-list-rice {
  margin: 1em 0;
}
.ec-list dt, .ec-list-icon dt, .ec-list-decimal dt, .ec-list-disc dt, .ec-list-rice dt {
  font-weight: 700;
}
.ec-list > dd, .ec-list-icon > dd, .ec-list-decimal > dd, .ec-list-disc > dd, .ec-list-rice > dd {
  line-height: 1.8;
  position: relative;
  padding-left: 1.3em;
  /* マークとテキストの間隔調整 */
}
.ec-list > dd:before, .ec-list-icon > dd:before, .ec-list-decimal > dd:before, .ec-list-disc > dd:before, .ec-list-rice > dd:before {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}

/* 箇条書き：「※」スタイル */
.ec-list-rice > dd:before {
  content: "※";
}

/* 箇条書き：「●」スタイル */
.ec-list-disc > dd:before {
  content: "●";
  color: var(--clrDefaultThin, #444);
}

/* 箇条書き：連番スタイル */
.ec-list-decimal > dd {
  counter-increment: note;
}
.ec-list-decimal > dd:before {
  content: counter(note) ".";
}

/* 箇条書き：アイコンスタイル */
.ec-list-icon > dd:before {
  font-family: "jeiafont";
  content: "\e91a";
  color: #aaa;
}

/*
リスト

シンプルなリストを構成するためのスタイル群です。
*/
/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。
*/
.ec-definitions, .ec-definitions--soft {
  margin: 0.5em 0;
  display: block;
}
.ec-definitions dt, .ec-definitions--soft dt, .ec-definitions dd, .ec-definitions--soft dd {
  display: inline-block;
  margin: 0;
}
.ec-definitions dt, .ec-definitions--soft dt {
  font-weight: 500;
  min-width: 8em;
  margin-right: 1em;
}

.ec-definitions--soft dt {
  font-weight: normal;
}

/*
定義リスト

線が添えられた定義リストのスタイルを定義します。（dlリスト）

フォーム入力ページや、「当サイトについて」「特商表記」など、タイトルと本文のあるコンテンツのスタイル。

メモ：
dtの中では「.ec-label」とセットで使われるのが常のようですが、これはフォームページの名残ともとれますが（違うかもしれませんが）、
「.ec-label」という名前上、フォーム周りのタイトルという先入観が拭えませんのでいささか気になりますが、当定義リストのdt要素が、
あくまでレイアウト役割のみという要素で割り切るなら、見た目のスタイルを当てている「.ec-label」は汎用的ならラベル要素とも取れます。
（ただ、_3.3.form.scssの中で定義されているのでやはり本来はフォームラベルの目的でコーディングされたような気がします）
*/
.ec-borderedDefs dl {
  width: 100%;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .ec-borderedDefs dl {
    padding: 0.5em 0;
  }
  .ec-borderedDefs dt {
    display: block;
    padding-bottom: 0.5em;
    border-bottom: 1px solid var(--clrBorderColor, #ddd);
  }
  .ec-borderedDefs dd {
    display: block;
    padding: 1rem 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-borderedDefs dl {
    display: table;
    border-bottom: 1px solid var(--clrBorderColor, #ddd);
  }
  .ec-borderedDefs dt {
    display: table-cell;
    width: 30%;
    vertical-align: middle;
  }
  .ec-borderedDefs dd {
    display: table-cell;
    width: auto;
    vertical-align: middle;
    padding: 1.5rem 0;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-borderedDefs dl {
    display: table;
    border-bottom: 1px solid var(--clrBorderColor, #ddd);
  }
  .ec-borderedDefs dl:first-child {
    border-top: 1px solid var(--clrBorderColor, #ddd);
  }
  .ec-borderedDefs dt {
    display: table-cell;
    width: 30%;
    vertical-align: middle;
  }
  .ec-borderedDefs dd {
    display: table-cell;
    width: auto;
    vertical-align: middle;
    padding: 1.5rem 0;
  }
}

/*
ボーダーリスト

線が添えられたリストを表示します。

使用場面はショッピングページのカート商品内容部分で、その中身は商品画像と商品詳細の「.ec-imageGrid」（_5.2.layout.scss）が入ります。

「.ec-borderedLis」も「.ec-imageGrid」もそのパターンでしか使われていない様子なので、
まとめて一つのコンポーネントにしても良いかとも思いますが、今のところはそのままにします。

それともう一点、
「.ec-imageGrid」の使用場面は先のショッピングページの商品内容と、マイページ注文履歴詳細の商品内容とになりますが、
ショッピングページではこれをリスト化（ul li要素）して括っているのが「.ec-borderedList」であるのに対して、
注文履歴詳細のほうではそのリストが含まれておりません。

どちらもさらにその外側で「.ec-orderDelivery__item」で括っているのですが、さらに、
ショッピングページ側では、「.ec-orderDelivery__item」は1つのみでその中にリストのul要素があり、商品ごとにli要素で
括っているのに対して、注文履歴詳細はリストが無く、商品ごとに「.ec-orderDelivery__item」で括っているという違いがあります。

意味合い的にもスタイリングにしても同一なものなので統一してもらいたい所ですが、今のところはCSSで統一させるに留めます。

まず、「.ec-orderDelivery__item」には何もスタイリングしなようにしておき、注文履歴詳細ページのみに働くように
「.ec-orderDelivery__item」に「.ec-borderedList」と同じ形のスタイリングを施しています。

〇ショッピングページ側
構成（※スタイルガイドページでは崩れますのでscssファイルでご覧ください）
.ec-orderDelivery__item
  > ul.ec-borderedList
    > li > .ec-imageGrid
    > li > .ec-imageGrid
    > li > .ec-imageGrid


〇注文履歴詳細ページ側
構成（※スタイルガイドページでは崩れますのでscssファイルでご覧ください）
.ec-orderDelivery__item > .ec-imageGrid
.ec-orderDelivery__item > .ec-imageGrid
.ec-orderDelivery__item > .ec-imageGrid
*/
.ec-borderedList {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid var(--clrBorderColor, #ddd);
}
.ec-borderedList li {
  padding: 10px 0;
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
}

/*
注文履歴詳細用
本来は_15.2.order.scssにてスタイリングすべきですが（※これも厳密にいえばショッピングとマイページコンテンツとで分けておくべきかとも思いますが）、
上記のメモ書きの理由により、混雑しないようにこちらで「.ec-borderedList」と合わせてスタイリングします。
*/
body#page_mypage_history .ec-orderDelivery__item {
  padding: 10px 0;
  border-top: 1px solid var(--clrBorderColor, #ddd);
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
}
body#page_mypage_history .ec-orderDelivery__item + .ec-orderDelivery__item {
  border-top: none;
}

/*
ボタン生成

mixinを利用して各パターンのボタンを生成します。
*/
/*
通常ボタン（インラインボタン）

インラインの要素としてボタンを定義出来ます。
*/
.ec-inlineBtn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
  color: var(--clrBtnDefaultText, #fff);
  background-color: var(--clrBtnDefaultBg, #242930);
  border-color: var(--clrBtnDefaultBg, #444);
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  font-size: 0.9rem;
}
.ec-inlineBtn:focus, .ec-inlineBtn.focus {
  color: var(--clrBtnDefaultText, #fff);
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-inlineBtn:hover {
  text-decoration: none;
  color: var(--clrBtnDefaultText, #fff);
  filter: brightness(1.1);
}
.ec-inlineBtn.disabled, .ec-inlineBtn[disabled], fieldset[disabled] .ec-inlineBtn {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
  cursor: not-allowed;
}
.ec-inlineBtn.disabled:hover, .ec-inlineBtn.disabled:focus, .ec-inlineBtn.disabled.focus, .ec-inlineBtn[disabled]:hover, .ec-inlineBtn[disabled]:focus, .ec-inlineBtn[disabled].focus, fieldset[disabled] .ec-inlineBtn:hover, fieldset[disabled] .ec-inlineBtn:focus, fieldset[disabled] .ec-inlineBtn.focus {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
}
.ec-inlineBtn .badge {
  color: var(--clrBtnDefaultBg, #242930);
  background-color: var(--clrBtnDefaultText, #fff);
}

.ec-inlineBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
}
.ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus {
  color: #fff;
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-inlineBtn--primary:hover {
  text-decoration: none;
  color: #fff;
  filter: brightness(1.1);
}
.ec-inlineBtn--primary.disabled, .ec-inlineBtn--primary[disabled], fieldset[disabled] .ec-inlineBtn--primary {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
  cursor: not-allowed;
}
.ec-inlineBtn--primary.disabled:hover, .ec-inlineBtn--primary.disabled:focus, .ec-inlineBtn--primary.disabled.focus, .ec-inlineBtn--primary[disabled]:hover, .ec-inlineBtn--primary[disabled]:focus, .ec-inlineBtn--primary[disabled].focus, fieldset[disabled] .ec-inlineBtn--primary:hover, fieldset[disabled] .ec-inlineBtn--primary:focus, fieldset[disabled] .ec-inlineBtn--primary.focus {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
}
.ec-inlineBtn--primary .badge {
  color: #5CB1B1;
  background-color: #fff;
}

.ec-inlineBtn--action {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
  color: var(--clrBtnActionText, #fff);
  background-color: var(--clrBtnActionBg, #b20000);
  border-color: var(--clrBtnActionBg, #b20000);
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
}
.ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus {
  color: var(--clrBtnActionText, #fff);
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-inlineBtn--action:hover {
  text-decoration: none;
  color: var(--clrBtnActionText, #fff);
  filter: brightness(1.1);
}
.ec-inlineBtn--action.disabled, .ec-inlineBtn--action[disabled], fieldset[disabled] .ec-inlineBtn--action {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
  cursor: not-allowed;
}
.ec-inlineBtn--action.disabled:hover, .ec-inlineBtn--action.disabled:focus, .ec-inlineBtn--action.disabled.focus, .ec-inlineBtn--action[disabled]:hover, .ec-inlineBtn--action[disabled]:focus, .ec-inlineBtn--action[disabled].focus, fieldset[disabled] .ec-inlineBtn--action:hover, fieldset[disabled] .ec-inlineBtn--action:focus, fieldset[disabled] .ec-inlineBtn--action.focus {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
}
.ec-inlineBtn--action .badge {
  color: var(--clrBtnActionBg, #b20000);
  background-color: var(--clrBtnActionText, #fff);
}
.ec-inlineBtn--action:hover {
  filter: brightness(1.1);
}

.ec-inlineBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
  color: var(--clrBtnCancelText, #fff);
  background-color: var(--clrBtnCancelBg, #333);
  border-color: var(--clrBtnCancelBg, #333);
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
}
.ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus {
  color: var(--clrBtnCancelText, #fff);
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-inlineBtn--cancel:hover {
  text-decoration: none;
  color: var(--clrBtnCancelText, #fff);
  filter: brightness(1.1);
}
.ec-inlineBtn--cancel.disabled, .ec-inlineBtn--cancel[disabled], fieldset[disabled] .ec-inlineBtn--cancel {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
  cursor: not-allowed;
}
.ec-inlineBtn--cancel.disabled:hover, .ec-inlineBtn--cancel.disabled:focus, .ec-inlineBtn--cancel.disabled.focus, .ec-inlineBtn--cancel[disabled]:hover, .ec-inlineBtn--cancel[disabled]:focus, .ec-inlineBtn--cancel[disabled].focus, fieldset[disabled] .ec-inlineBtn--cancel:hover, fieldset[disabled] .ec-inlineBtn--cancel:focus, fieldset[disabled] .ec-inlineBtn--cancel.focus {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
}
.ec-inlineBtn--cancel .badge {
  color: var(--clrBtnCancelBg, #333);
  background-color: var(--clrBtnCancelText, #fff);
}

/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。
*/
.ec-blockBtn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
  color: var(--clrBtnDefaultText, #fff);
  background-color: var(--clrBtnDefaultBg, #242930);
  border-color: var(--clrBtnDefaultBg, #444);
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  display: block;
  width: 100%;
  height: 3.33rem;
  line-height: 3.33rem;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn:focus, .ec-blockBtn.focus {
  color: var(--clrBtnDefaultText, #fff);
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-blockBtn:hover {
  text-decoration: none;
  color: var(--clrBtnDefaultText, #fff);
  filter: brightness(1.1);
}
.ec-blockBtn.disabled, .ec-blockBtn[disabled], fieldset[disabled] .ec-blockBtn {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
  cursor: not-allowed;
}
.ec-blockBtn.disabled:hover, .ec-blockBtn.disabled:focus, .ec-blockBtn.disabled.focus, .ec-blockBtn[disabled]:hover, .ec-blockBtn[disabled]:focus, .ec-blockBtn[disabled].focus, fieldset[disabled] .ec-blockBtn:hover, fieldset[disabled] .ec-blockBtn:focus, fieldset[disabled] .ec-blockBtn.focus {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
}
.ec-blockBtn .badge {
  color: var(--clrBtnDefaultBg, #242930);
  background-color: var(--clrBtnDefaultText, #fff);
}

.ec-blockBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  display: block;
  width: 100%;
  height: 3.33rem;
  line-height: 3.33rem;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus {
  color: #fff;
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-blockBtn--primary:hover {
  text-decoration: none;
  color: #fff;
  filter: brightness(1.1);
}
.ec-blockBtn--primary.disabled, .ec-blockBtn--primary[disabled], fieldset[disabled] .ec-blockBtn--primary {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
  cursor: not-allowed;
}
.ec-blockBtn--primary.disabled:hover, .ec-blockBtn--primary.disabled:focus, .ec-blockBtn--primary.disabled.focus, .ec-blockBtn--primary[disabled]:hover, .ec-blockBtn--primary[disabled]:focus, .ec-blockBtn--primary[disabled].focus, fieldset[disabled] .ec-blockBtn--primary:hover, fieldset[disabled] .ec-blockBtn--primary:focus, fieldset[disabled] .ec-blockBtn--primary.focus {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
}
.ec-blockBtn--primary .badge {
  color: #5CB1B1;
  background-color: #fff;
}

.ec-blockBtn--action {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
  color: var(--clrBtnActionText, #fff);
  background-color: var(--clrBtnActionBg, #b20000);
  border-color: var(--clrBtnActionBg, #b20000);
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  display: block;
  width: 100%;
  height: 3.33rem;
  line-height: 3.33rem;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn--action:focus, .ec-blockBtn--action.focus {
  color: var(--clrBtnActionText, #fff);
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-blockBtn--action:hover {
  text-decoration: none;
  color: var(--clrBtnActionText, #fff);
  filter: brightness(1.1);
}
.ec-blockBtn--action.disabled, .ec-blockBtn--action[disabled], fieldset[disabled] .ec-blockBtn--action {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
  cursor: not-allowed;
}
.ec-blockBtn--action.disabled:hover, .ec-blockBtn--action.disabled:focus, .ec-blockBtn--action.disabled.focus, .ec-blockBtn--action[disabled]:hover, .ec-blockBtn--action[disabled]:focus, .ec-blockBtn--action[disabled].focus, fieldset[disabled] .ec-blockBtn--action:hover, fieldset[disabled] .ec-blockBtn--action:focus, fieldset[disabled] .ec-blockBtn--action.focus {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
}
.ec-blockBtn--action .badge {
  color: var(--clrBtnActionBg, #b20000);
  background-color: var(--clrBtnActionText, #fff);
}
.ec-blockBtn--action:hover {
  filter: brightness(1.1);
}

.ec-blockBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
  color: var(--clrBtnCancelText, #fff);
  background-color: var(--clrBtnCancelBg, #333);
  border-color: var(--clrBtnCancelBg, #333);
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  display: block;
  width: 100%;
  height: 3.33rem;
  line-height: 3.33rem;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus {
  color: var(--clrBtnCancelText, #fff);
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-blockBtn--cancel:hover {
  text-decoration: none;
  color: var(--clrBtnCancelText, #fff);
  filter: brightness(1.1);
}
.ec-blockBtn--cancel.disabled, .ec-blockBtn--cancel[disabled], fieldset[disabled] .ec-blockBtn--cancel {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
  cursor: not-allowed;
}
.ec-blockBtn--cancel.disabled:hover, .ec-blockBtn--cancel.disabled:focus, .ec-blockBtn--cancel.disabled.focus, .ec-blockBtn--cancel[disabled]:hover, .ec-blockBtn--cancel[disabled]:focus, .ec-blockBtn--cancel[disabled].focus, fieldset[disabled] .ec-blockBtn--cancel:hover, fieldset[disabled] .ec-blockBtn--cancel:focus, fieldset[disabled] .ec-blockBtn--cancel.focus {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
}
.ec-blockBtn--cancel .badge {
  color: var(--clrBtnCancelBg, #333);
  background-color: var(--clrBtnCancelText, #fff);
}

/*
カートインボタン
*/
.ec-blockBtn--cartin {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
  color: var(--clrBtnCartinText, #fff);
  background-color: var(--clrBtnCartinBg, #b20000);
  border-color: var(--clrBtnCartinBg, #b20000);
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  display: block;
  width: 100%;
  height: 3.33rem;
  line-height: 3.33rem;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn--cartin:focus, .ec-blockBtn--cartin.focus {
  color: var(--clrBtnCartinText, #fff);
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-blockBtn--cartin:hover {
  text-decoration: none;
  color: var(--clrBtnCartinText, #fff);
  filter: brightness(1.1);
}
.ec-blockBtn--cartin.disabled, .ec-blockBtn--cartin[disabled], fieldset[disabled] .ec-blockBtn--cartin {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
  cursor: not-allowed;
}
.ec-blockBtn--cartin.disabled:hover, .ec-blockBtn--cartin.disabled:focus, .ec-blockBtn--cartin.disabled.focus, .ec-blockBtn--cartin[disabled]:hover, .ec-blockBtn--cartin[disabled]:focus, .ec-blockBtn--cartin[disabled].focus, fieldset[disabled] .ec-blockBtn--cartin:hover, fieldset[disabled] .ec-blockBtn--cartin:focus, fieldset[disabled] .ec-blockBtn--cartin.focus {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
}
.ec-blockBtn--cartin .badge {
  color: var(--clrBtnCartinBg, #b20000);
  background-color: var(--clrBtnCartinText, #fff);
}
.ec-blockBtn--cartin:before {
  font-family: "jeiafont";
  content: "\e900";
  margin-right: 0.5em;
  display: inline-block;
  transform: scale(1.4);
}
.ec-blockBtn--cartin:hover {
  filter: brightness(1.1);
}

/*
お気に入りボタン

お気に入りボタンのサイズは商品詳細ページの「カートイン枠（横一列）」に合わせて、$cartinRow-sizeで生成しています。
*/
.ec-blockBtn--favorite {
  display: block;
}
.ec-blockBtn--favorite i {
  display: block;
  margin: 0;
  width: 3.33rem;
  height: 3.33rem;
  text-align: center;
  border-radius: 50%;
  background-color: #666;
  color: var(--clrBtnFavoriteText, #fff);
  transition: 0.2s;
  position: relative;
  /* ポップアップ */
}
.ec-blockBtn--favorite i:before {
  font-size: 1.665rem;
  line-height: 3.663rem;
}
.ec-blockBtn--favorite i span {
  position: absolute;
  bottom: calc(100% + 12px);
  left: calc(50% - 18px);
  display: block;
  padding: 0.75em 1em;
  background-color: #333;
  color: #fff;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 10px, 0);
  transition: 0.2s;
}
@media (max-width: 1650px) {
  .ec-blockBtn--favorite i span {
    left: auto;
    right: 0;
  }
}
.ec-blockBtn--favorite i span:after {
  content: "";
  width: 0;
  height: 0;
  border: solid 8px transparent;
  border-top-color: #333;
  position: absolute;
  top: 100%;
  left: 10px;
}
@media (max-width: 1650px) {
  .ec-blockBtn--favorite i span:after {
    left: auto;
    right: 10px;
  }
}
.ec-blockBtn--favorite i:hover {
  background-color: var(--clrBtnFavoriteBg, #f55);
}
.ec-blockBtn--favorite i:hover span {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.ec-blockBtn--favorite.added i {
  background-color: var(--clrBtnFavoriteBg, #f55);
}

/*
アイコンボタン
*/
/*
閉じるボタン
*/
.ec-closeBtn--circle:after {
  font-family: "jeiafont";
  content: "\e918";
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 21px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  color: #333;
  border: solid 1px #aaa;
  box-sizing: border-box;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  transition: 0.2s;
}
.ec-closeBtn--circle:hover:after {
  text-decoration: none;
  transform: translate3d(0, 1px, 0);
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.3);
}
.ec-closeBtn--circle:hover {
  text-decoration: none;
}
.ec-closeBtn--circle .ec-icon {
  display: none;
}

/*
その他のボタン

通常のボタンや、アイコンボタン以外のボタンを定義します。
*/
/*
ページトップボタン

ページトップボタンのスタイリングです。
*/
.ec-blockTopBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 22px;
  width: 2.182em;
  height: 2.182em;
  color: #999;
  border: solid 3px #999;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  /* ボタン表示 */
}
@media only screen and (max-width: 767px) {
  .ec-blockTopBtn {
    font-size: 19px;
    right: 10px;
    bottom: 10px;
  }
}
body.scrolled .ec-blockTopBtn {
  visibility: visible;
  opacity: 1;
}
.ec-blockTopBtn i {
  transform: translate3d(0, 1px, 0);
  transition: 0.5s;
}
.ec-blockTopBtn:hover {
  filter: brightness(1.2);
}
.ec-blockTopBtn:hover i {
  transform: translate3d(0, -1px, 0);
}

/*
フォーム部品（入力要素）

テキストや数値の入力項目に関するフォーム要素の基本整形をスタイリングします。
*/
/*
テキスト入力フォーム
*/
.ec-input input, .ec-telInput input, .ec-zipInput input, .ec-numberInput input, .ec-halfInput input {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.5em 0.75em;
  font-size: 16px;
  line-height: 1.3;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid var(--clrInputBorder, #ccc);
  border-radius: 0;
  -webkit-appearance: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .ec-input input, .ec-telInput input, .ec-zipInput input, .ec-numberInput input, .ec-halfInput input {
    transition: none;
  }
}
.ec-input input:focus, .ec-telInput input:focus, .ec-zipInput input:focus, .ec-numberInput input:focus, .ec-halfInput input:focus {
  /*
  outline: none;//0;
  border-color: $input-border-focus;
  box-shadow: 0 0 0 $focusShadowsize $clrInputFocusShadow;
  */
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-input input::-moz-placeholder, .ec-telInput input::-moz-placeholder, .ec-zipInput input::-moz-placeholder, .ec-numberInput input::-moz-placeholder, .ec-halfInput input::-moz-placeholder {
  color: #bbb;
  opacity: 1;
}
.ec-input input:-ms-input-placeholder, .ec-telInput input:-ms-input-placeholder, .ec-zipInput input:-ms-input-placeholder, .ec-numberInput input:-ms-input-placeholder, .ec-halfInput input:-ms-input-placeholder {
  color: #bbb;
}
.ec-input input::-webkit-input-placeholder, .ec-telInput input::-webkit-input-placeholder, .ec-zipInput input::-webkit-input-placeholder, .ec-numberInput input::-webkit-input-placeholder, .ec-halfInput input::-webkit-input-placeholder {
  color: #bbb;
}
.ec-input input::-ms-expand, .ec-telInput input::-ms-expand, .ec-zipInput input::-ms-expand, .ec-numberInput input::-ms-expand, .ec-halfInput input::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input input[disabled], .ec-telInput input[disabled], .ec-zipInput input[disabled], .ec-numberInput input[disabled], .ec-halfInput input[disabled], .ec-input input[readonly], .ec-telInput input[readonly], .ec-zipInput input[readonly], .ec-numberInput input[readonly], .ec-halfInput input[readonly], fieldset[disabled] .ec-input input, fieldset[disabled] .ec-telInput input, fieldset[disabled] .ec-zipInput input, fieldset[disabled] .ec-numberInput input, fieldset[disabled] .ec-halfInput input {
  background-color: #f4f4f4;
  opacity: 1;
}
.ec-input input[disabled], .ec-telInput input[disabled], .ec-zipInput input[disabled], .ec-numberInput input[disabled], .ec-halfInput input[disabled], fieldset[disabled] .ec-input input, fieldset[disabled] .ec-telInput input, fieldset[disabled] .ec-zipInput input, fieldset[disabled] .ec-numberInput input, fieldset[disabled] .ec-halfInput input {
  cursor: not-allowed;
}
@media only screen and (min-width: 1280px) {
  .ec-input input, .ec-telInput input, .ec-zipInput input, .ec-numberInput input, .ec-halfInput input {
    font-size: 1rem;
  }
}
.ec-input textarea, .ec-telInput textarea, .ec-zipInput textarea, .ec-numberInput textarea, .ec-halfInput textarea {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.5em 0.75em;
  font-size: 16px;
  line-height: 1.3;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid var(--clrInputBorder, #ccc);
  border-radius: 0;
  -webkit-appearance: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  height: auto;
  min-height: 8em;
}
@media (prefers-reduced-motion: reduce) {
  .ec-input textarea, .ec-telInput textarea, .ec-zipInput textarea, .ec-numberInput textarea, .ec-halfInput textarea {
    transition: none;
  }
}
.ec-input textarea:focus, .ec-telInput textarea:focus, .ec-zipInput textarea:focus, .ec-numberInput textarea:focus, .ec-halfInput textarea:focus {
  /*
  outline: none;//0;
  border-color: $input-border-focus;
  box-shadow: 0 0 0 $focusShadowsize $clrInputFocusShadow;
  */
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-input textarea::-moz-placeholder, .ec-telInput textarea::-moz-placeholder, .ec-zipInput textarea::-moz-placeholder, .ec-numberInput textarea::-moz-placeholder, .ec-halfInput textarea::-moz-placeholder {
  color: #bbb;
  opacity: 1;
}
.ec-input textarea:-ms-input-placeholder, .ec-telInput textarea:-ms-input-placeholder, .ec-zipInput textarea:-ms-input-placeholder, .ec-numberInput textarea:-ms-input-placeholder, .ec-halfInput textarea:-ms-input-placeholder {
  color: #bbb;
}
.ec-input textarea::-webkit-input-placeholder, .ec-telInput textarea::-webkit-input-placeholder, .ec-zipInput textarea::-webkit-input-placeholder, .ec-numberInput textarea::-webkit-input-placeholder, .ec-halfInput textarea::-webkit-input-placeholder {
  color: #bbb;
}
.ec-input textarea::-ms-expand, .ec-telInput textarea::-ms-expand, .ec-zipInput textarea::-ms-expand, .ec-numberInput textarea::-ms-expand, .ec-halfInput textarea::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input textarea[disabled], .ec-telInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-halfInput textarea[disabled], .ec-input textarea[readonly], .ec-telInput textarea[readonly], .ec-zipInput textarea[readonly], .ec-numberInput textarea[readonly], .ec-halfInput textarea[readonly], fieldset[disabled] .ec-input textarea, fieldset[disabled] .ec-telInput textarea, fieldset[disabled] .ec-zipInput textarea, fieldset[disabled] .ec-numberInput textarea, fieldset[disabled] .ec-halfInput textarea {
  background-color: #f4f4f4;
  opacity: 1;
}
.ec-input textarea[disabled], .ec-telInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-halfInput textarea[disabled], fieldset[disabled] .ec-input textarea, fieldset[disabled] .ec-telInput textarea, fieldset[disabled] .ec-zipInput textarea, fieldset[disabled] .ec-numberInput textarea, fieldset[disabled] .ec-halfInput textarea {
  cursor: not-allowed;
}
@media only screen and (min-width: 1280px) {
  .ec-input textarea, .ec-telInput textarea, .ec-zipInput textarea, .ec-numberInput textarea, .ec-halfInput textarea {
    font-size: 1rem;
  }
}
.ec-input + p, .ec-telInput + p, .ec-zipInput + p, .ec-numberInput + p, .ec-halfInput + p {
  margin: 0.75em 0 0;
}
.ec-input + .ec-input, .ec-telInput + .ec-input, .ec-input + .ec-telInput, .ec-telInput + .ec-telInput, .ec-zipInput + .ec-input, .ec-zipInput + .ec-telInput, .ec-input + .ec-zipInput, .ec-telInput + .ec-zipInput, .ec-zipInput + .ec-zipInput, .ec-numberInput + .ec-input, .ec-numberInput + .ec-telInput, .ec-numberInput + .ec-zipInput, .ec-input + .ec-numberInput, .ec-telInput + .ec-numberInput, .ec-zipInput + .ec-numberInput, .ec-numberInput + .ec-numberInput, .ec-halfInput + .ec-input, .ec-halfInput + .ec-telInput, .ec-halfInput + .ec-zipInput, .ec-halfInput + .ec-numberInput, .ec-input + .ec-halfInput, .ec-telInput + .ec-halfInput, .ec-zipInput + .ec-halfInput, .ec-numberInput + .ec-halfInput, .ec-halfInput + .ec-halfInput {
  margin-top: 1rem;
}
.ec-input.error input:not(:focus), .error.ec-telInput input:not(:focus), .error.ec-zipInput input:not(:focus), .error.ec-numberInput input:not(:focus), .error.ec-halfInput input:not(:focus), .ec-input.error textarea:not(:focus), .error.ec-telInput textarea:not(:focus), .error.ec-zipInput textarea:not(:focus), .error.ec-numberInput textarea:not(:focus), .error.ec-halfInput textarea:not(:focus) {
  border-color: #e75353;
  background-color: #fff9f8;
}

/*
テキスト入力フォーム（左右2分割）

姓名など入力フォームが分割されている項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。
*/
.ec-halfInput {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
}
.ec-halfInput > input,
.ec-halfInput > .ec-errorMessage,
.ec-halfInput > span.customer-form {
  flex: 0 0 auto;
  width: calc(( 100% - 1em - 1px ) / 2);
  margin-left: 1em;
}
.ec-halfInput > input:first-of-type,
.ec-halfInput > .ec-errorMessage:first-of-type,
.ec-halfInput > span.customer-form:first-of-type {
  margin-left: 0;
}
.ec-halfInput > span.customer-form input {
  width: 100%;
  margin: 0;
}

/*
数量ボタン

数量を表示するための小さなコンポーネントです。
*/
.ec-numberInput input[type=number] {
  width: auto;
  max-width: 5em;
  text-align: center;
}

/*
数量ボタン（増減ボタンセット）
*/
.ec-quantityRole {
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  border: solid 1px var(--clrBorderColor, #ddd);
}
.ec-quantityRole .ec-quantityRole__btn {
  width: 3.33rem;
  height: 3.33rem;
  line-height: 3.33rem;
  text-align: center;
  font-size: 1.2em;
  background-color: #f0f0f0;
  color: #222;
  cursor: pointer;
}
.ec-quantityRole .ec-numberInput {
  height: 3.33rem;
  padding: 0;
  border: none;
  border-left: solid 1px var(--clrBorderColor, #ddd);
  border-right: solid 1px var(--clrBorderColor, #ddd);
}
.ec-quantityRole .ec-numberInput input {
  height: 3.33rem;
  border: none;
  width: 3.33rem;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-size: 1.3em;
}

/*
郵便番号フォーム
*/
.ec-zipInput input {
  display: inline-block;
  width: auto;
  max-width: 9em;
  margin-left: 0.5em;
}
@media only screen and (max-width: 767px) {
  .ec-zipInput input {
    max-width: 8em;
  }
}
.ec-zipInput > span {
  display: inline-block;
}

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 1em;
}
.ec-zipInputHelp .ec-zipInputHelp__icon {
  display: none;
}
.ec-zipInputHelp a {
  font-size: 12px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  height: 2em;
  border-radius: 3px;
  background-color: #16457e;
  color: #fff;
  padding-left: 0.5em;
  padding-right: 0.5em;
  transition: 0.3s;
}
.ec-zipInputHelp a:before {
  font-family: "jeiafont";
  content: "\e901";
  margin-right: 0.5em;
  padding-right: 0.5em;
  border-right: solid 1px rgba(255, 255, 255, 0.3);
}
.ec-zipInputHelp a:hover {
  text-decoration: none;
  filter: brightness(1.2);
}

.ec-zipInput + .ec-select, .ec-zipInput + .ec-birth {
  margin-top: 1em;
  margin-bottom: 1em;
}

/*
電話番号ボタン
*/
.ec-telInput input {
  max-width: 12em;
}

/*
エラーメッセージ

エラー項目にポップアップ形式で表示するタイプにしたいが、.ec-harfInputの場合に2つ目のエラーも左に出てしまう。これを制御するにはTwig側に手をいれないといけないので断念。
普通にエラー項目の下にテキスト表示するに留めます。
*/
.ec-errorMessage {
  font-size: 0.9rem;
  margin: 0.5em 0 0;
  color: var(--clrError, #ca0000);
}
.ec-errorMessage:before {
  font-family: "jeiafont";
  font-weight: normal;
  content: "\e915";
  font-size: 20px;
  display: inline-block;
  vertical-align: -4px;
  margin-right: 3px;
}

/* その他 */
/*
input[type="number"]のスピンボタンを非表示

使用箇所：
利用ポイント、商品数量入力
*/
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/*
フォーム部品(ラジオ、チェックボックス、セレクト)

ラジオ、チェックボックス、セレクト要素の基本整形をスタイリングします。
*/
/*
ラジオボタン（横配置）

各要素をlabelでくくって、コーディングします。←マチガイ（ver4.0.2では、input + label の構造になっている様子（全部がそうかは未確認））<br>
input要素をlabelでくくってしまうと独自UIは表示されないので注意

ラジオボタンの独自UIはアイコンフォントを使っています。
*/
.ec-radio, .ec-blockRadio {
  margin-top: -0.5em;
}
.ec-radio input[type=radio], .ec-blockRadio input[type=radio] {
  opacity: 0;
  appearance: none;
}
.ec-radio input[type=radio] + label, .ec-blockRadio input[type=radio] + label {
  display: inline-block;
  margin: 0;
  font-size: 1.05rem;
  font-weight: normal;
  line-height: 1;
  margin-right: 1em;
  margin-top: 0.5em;
}
.ec-radio input[type=radio] + label:before, .ec-blockRadio input[type=radio] + label:before {
  font-family: "jeiafont";
  content: "\e90c";
  font-size: 22px;
  display: inline-block;
  vertical-align: -3px;
  margin-right: 0;
  color: #999;
  transition: 0.3s;
}
.ec-radio input[type=radio] + label span, .ec-blockRadio input[type=radio] + label span {
  display: inline-block;
}
.ec-radio input[type=radio]:checked + label:before, .ec-blockRadio input[type=radio]:checked + label:before {
  font-family: "jeiafont";
  content: "\e90d";
  color: var(--clrRadioChecked, #0049a3);
}
.ec-radio input[type=radio]:focus + label, .ec-blockRadio input[type=radio]:focus + label {
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-radio.error, .error.ec-blockRadio {
  background-color: #fff9f8;
}

/*
ラジオボタン(縦配置)

垂直に並ぶラジオボタンフィールドです。

※ショッピングページ「shopping/index.twig」においてはinputとlabelごとをdiv要素（ブロック要素）でくくる形でコーディングされているためCSSスタイリングせずとも縦配置になる。
一応、このブロック要素がない場合でも縦配置になるスタイリングは準備しておく。

EC-CUBE4標準において`.ec-blockRadio`が使われている箇所はshopping/confirm.twigだけの様子だが（お支払いとポイント）、確認画面ページなので表示要素は1点のみであるし、
またラジオボタンですらないので、厳密にいえばここで`.ec-blockRadio`を使うのはミスではないかと思われる。
*/
.ec-blockRadio {
  margin-right: 0;
  margin-top: -1em;
}
.ec-blockRadio input[type=radio] + label {
  display: block;
  margin-right: 0;
  margin-top: 1em;
}

/*
チェックボックス （横配置）

各要素をlabelでくくって、コーディングします。←マチガイ<br>
input要素をlabelでくくってしまうと独自UIは表示されないので注意
*/
.ec-checkbox, .ec-blockCheckbox {
  margin-top: -0.5em;
}
.ec-checkbox input[type=checkbox], .ec-blockCheckbox input[type=checkbox] {
  opacity: 0;
  appearance: none;
}
.ec-checkbox input[type=checkbox] + label, .ec-blockCheckbox input[type=checkbox] + label {
  display: inline-block;
  margin: 0;
  font-size: 1.05rem;
  font-weight: normal;
  line-height: 1;
  margin-right: 1em;
  margin-top: 0.5em;
}
.ec-checkbox input[type=checkbox] + label:before, .ec-blockCheckbox input[type=checkbox] + label:before {
  font-family: "jeiafont";
  content: "\e90e";
  font-size: 22px;
  display: inline-block;
  vertical-align: -3px;
  margin-right: 0.2em;
  color: #999;
  transition: 0.3s;
}
.ec-checkbox input[type=checkbox] + label span, .ec-blockCheckbox input[type=checkbox] + label span {
  display: inline-block;
}
.ec-checkbox input[type=checkbox]:checked + label:before, .ec-blockCheckbox input[type=checkbox]:checked + label:before {
  font-family: "jeiafont";
  content: "\e90f";
  color: var(--clrRadioChecked, #0049a3);
}
.ec-checkbox input[type=checkbox]:focus + label, .ec-blockCheckbox input[type=checkbox]:focus + label {
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-checkbox.error, .error.ec-blockCheckbox {
  background-color: #fff9f8;
}

/* 会員登録の利用規約チェックだけlabelがつけ方が通常のコーディングルールと違うのでCSS側で吸収 */
.ec-registerRole__actions .ec-checkbox input + label, .ec-registerRole__actions .ec-blockCheckbox input + label {
  margin-right: 0;
}

/*
チェックボックス (縦配置)

垂直に並ぶチェックボックス フィールドです。
*/
.ec-blockCheckbox {
  margin-right: 0;
  margin-top: -1em;
}
.ec-blockCheckbox input[type=checkbox] + label {
  display: block;
  margin-right: 0;
  margin-top: 1em;
}

/*
セレクトボックス

独自UIを施しています。
*/
.ec-select select, .ec-birth select {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.5em 0.75em;
  font-size: 16px;
  line-height: 1.3;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid var(--clrInputBorder, #ccc);
  border-radius: 0;
  -webkit-appearance: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: auto;
  max-width: 100%;
  background-color: #f8f8f8;
  cursor: pointer;
  padding-right: calc(20px + 0.75em);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .ec-select select, .ec-birth select {
    transition: none;
  }
}
.ec-select select:focus, .ec-birth select:focus {
  /*
  outline: none;//0;
  border-color: $input-border-focus;
  box-shadow: 0 0 0 $focusShadowsize $clrInputFocusShadow;
  */
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-select select::-moz-placeholder, .ec-birth select::-moz-placeholder {
  color: #bbb;
  opacity: 1;
}
.ec-select select:-ms-input-placeholder, .ec-birth select:-ms-input-placeholder {
  color: #bbb;
}
.ec-select select::-webkit-input-placeholder, .ec-birth select::-webkit-input-placeholder {
  color: #bbb;
}
.ec-select select::-ms-expand, .ec-birth select::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-select select[disabled], .ec-birth select[disabled], .ec-select select[readonly], .ec-birth select[readonly], fieldset[disabled] .ec-select select, fieldset[disabled] .ec-birth select {
  background-color: #f4f4f4;
  opacity: 1;
}
.ec-select select[disabled], .ec-birth select[disabled], fieldset[disabled] .ec-select select, fieldset[disabled] .ec-birth select {
  cursor: not-allowed;
}
@media only screen and (min-width: 1280px) {
  .ec-select select, .ec-birth select {
    font-size: 1rem;
  }
}
.ec-select select::-ms-expand, .ec-birth select::-ms-expand {
  display: none;
}
.ec-select .select_ui, .ec-birth .select_ui {
  display: inline-block;
  font-weight: normal;
  margin: 0;
  position: relative;
}
.ec-select .select_ui:after, .ec-birth .select_ui:after {
  font-family: "jeiafont";
  content: "\e913";
  font-size: 16px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: calc(100% - 2px);
  background: #ddd;
  color: #555;
  position: absolute;
  top: 1px;
  right: 1px;
  border-radius: 0;
  pointer-events: none;
}
.ec-select + .ec-select, .ec-birth + .ec-select, .ec-select + .ec-birth, .ec-birth + .ec-birth {
  margin-top: 1rem;
}
.ec-select.error select, .error.ec-birth select {
  border-color: #e75353;
  background-color: #fff9f8;
}
.ec-select label, .ec-birth label {
  margin: 0;
}
.ec-select label + .select_ui, .ec-birth label + .select_ui {
  margin-left: 0.5em;
}

.ec-select__delivery {
  display: block;
}
@media only screen and (min-width: 1280px) {
  .ec-select__delivery {
    display: inline-block;
  }
}

.ec-select__time {
  display: block;
}
@media only screen and (min-width: 1280px) {
  .ec-select__time {
    display: inline-block;
    margin-left: 1rem;
  }
}

/*
生年月日選択
*/
.ec-birth select {
  padding-left: 0.5em;
  padding-right: calc(20px + 0.5em);
}
.ec-birth span {
  display: inline-block;
  margin: 0 0.5em;
}

/*
フォーム部品（ラベル、他）

フォームのラベルに関する要素を定義します。
*/
/*
タイトルラベル

リストレイアウトでのタイトル部分など、会員登録ページでいえば「お名前」の部分のスタイリングになります。

メモ：
フォーム周りのタイトルラベルという位置づけの印象が強いですが、EC-CUBE4では「当サイトについて」や「特商」のページでも使われており、
どちらかというと汎用的なタイトルラベルという使い方なのかもしれません。
*/
.ec-label {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  margin: 0;
  color: var(--clrDefaultDark, #000);
  letter-spacing: 0.05em;
}
@media only screen and (max-width: 767px) {
  .ec-label {
    font-weight: 500;
  }
}

/*
必須ラベル

上記のタイトルラベルに付随する「必須」項目を示す部位になります。
*/
.ec-required {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  font-weight: normal;
  line-height: 1;
  padding: 0.4em 0.4em;
  background-color: #e2251f;
  color: #fff;
  margin-left: 1em;
}

/*
その他の調整

コーディングルールに則っていない（？）部位の調整。<br>
ミスなのか意図的なのかは不明ですがこれまでのフォーム構成（ルール）とは逸れている箇所があり、ミスであるならば本来はTwigを修正すべきですが、
テンプレート的にはTwigの編集は必要以外は極力しないでおきたいですので（バージョンアップの事も考慮しつつ）CSS側で吸収を試みます。

* なるべくピンポイントに働くように、他の部位に影響・汚染の無いよう心掛ける。
* もしバージョンアップでTwigの修正が施された際に悪影響のないようにしておきたい。
*/
/*
ショッピングページ「お客様情報」の変更フォーム

「都道府県」入力欄がここではテキスト入力だが、これを括る要素が.ec-selectとなっておりCSSルールから外れてしまっているので補正
*/
.ec-select .customer-address_pref input, .ec-birth .customer-address_pref input {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.5em 0.75em;
  font-size: 16px;
  line-height: 1.3;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid var(--clrInputBorder, #ccc);
  border-radius: 0;
  -webkit-appearance: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .ec-select .customer-address_pref input, .ec-birth .customer-address_pref input {
    transition: none;
  }
}
.ec-select .customer-address_pref input:focus, .ec-birth .customer-address_pref input:focus {
  /*
  outline: none;//0;
  border-color: $input-border-focus;
  box-shadow: 0 0 0 $focusShadowsize $clrInputFocusShadow;
  */
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-select .customer-address_pref input::-moz-placeholder, .ec-birth .customer-address_pref input::-moz-placeholder {
  color: #bbb;
  opacity: 1;
}
.ec-select .customer-address_pref input:-ms-input-placeholder, .ec-birth .customer-address_pref input:-ms-input-placeholder {
  color: #bbb;
}
.ec-select .customer-address_pref input::-webkit-input-placeholder, .ec-birth .customer-address_pref input::-webkit-input-placeholder {
  color: #bbb;
}
.ec-select .customer-address_pref input::-ms-expand, .ec-birth .customer-address_pref input::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-select .customer-address_pref input[disabled], .ec-birth .customer-address_pref input[disabled], .ec-select .customer-address_pref input[readonly], .ec-birth .customer-address_pref input[readonly], fieldset[disabled] .ec-select .customer-address_pref input, fieldset[disabled] .ec-birth .customer-address_pref input {
  background-color: #f4f4f4;
  opacity: 1;
}
.ec-select .customer-address_pref input[disabled], .ec-birth .customer-address_pref input[disabled], fieldset[disabled] .ec-select .customer-address_pref input, fieldset[disabled] .ec-birth .customer-address_pref input {
  cursor: not-allowed;
}
@media only screen and (min-width: 1280px) {
  .ec-select .customer-address_pref input, .ec-birth .customer-address_pref input {
    font-size: 1rem;
  }
}

/*
問い合わせフォーム

問い合わせフォームで、.ec-zipInputHelpが、.ec-zipInputの中ではなく外に出てしまっている
↓
※4.2.0でHTML構造が修正されたのでこのCSS調整は不要ですのでコメントアウト（削除可能）しています。
*/
/*
#page_contact {

  .ec-zipInput {
    display: inline-block;
  }

  // .ec-zipInputに続く都道府県selectと住所Input間のマージン調整
  .ec-zipInputHelp {

    & + .ec-select {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
}
*/
/*
アイコン・WEBフォント

EC-CUBE4ではアイコンに[Font Awesome 5](https://fontawesome.com/) を使用しています。<br>
Font Awesome 5 で利用可能なフォント群については、 Font Awesome 5 公式サイト をご確認ください。

当テンプレートではそのほか、独自のWEBフォントと[Google Material Design](https://material.io/" target="_blank">Material Design)を使用しています。
*/
/*
SVGアイコン

デフォルトテンプレートのアイコンは`.ec-icon`>`img`タグで使用することができます
*/
.ec-icon img {
  max-width: 80px;
  max-height: 80px;
}

/*
JEIA WEBフォント

当テンプレート用に独自のWEBフォントを使用しています。

WEBフォントの読み込みは__default_frame.twig__のheadの中で行っています。

当テンプレートのWEBフォントには[Apache 2.0ライセンス](http://www.apache.org/licenses/LICENSE-2.0)の
[Google Material Design](https://material.io/" target="_blank">Material Design)のアイコンと、
[FontAwesome](https://fontawesome.com/ target="_blank")のアイコンを含めています。

[アイコンリスト](/html/template/dtJEIA4002/assets/icon/jeiafont/demo.html)
*/
i.jeiafont {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "jeiafont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle;
}

/* アイコン回転 */
i.rotate-90 {
  transform-origin: center center;
  transform: rotate(90deg);
}

/* 以下アイコン追加があれば適宜追加 */
.jeia-cart:before {
  content: "\e900";
}

.jeia-search:before {
  content: "\e901";
}

.jeia-arrow:before {
  content: "\e902";
}

.jeia-heart:before {
  content: "\e903";
}

.jeia-heart_fill:before {
  content: "\e904";
}

.jeia-user:before {
  content: "\e905";
}

.jeia-user_fill:before {
  content: "\e906";
}

.jeia-lock:before {
  content: "\e907";
}

.jeia-lock_fill:before {
  content: "\e908";
}

.jeia-cross:before {
  content: "\e909";
}

.jeia-menu:before {
  content: "\e90a";
}

.jeia-more:before {
  content: "\e90b";
}

.jeia-radio:before {
  content: "\e90c";
}

.jeia-radio_checked:before {
  content: "\e90d";
}

.jeia-checkbox:before {
  content: "\e90e";
}

.jeia-checkbox_checked:before {
  content: "\e90f";
}

.jeia-call:before {
  content: "\e910";
}

.jeia-mail:before {
  content: "\e911";
}

.jeia-place:before {
  content: "\e912";
}

.jeia-more_angle:before {
  content: "\e913";
}

.jeia-warning:before {
  content: "\e914";
}

.jeia-error:before {
  content: "\e915";
}

.jeia-add:before {
  content: "\e916";
}

.jeia-remove:before {
  content: "\e917";
}

.jeia-clear:before {
  content: "\e918";
}

.jeia-star:before {
  content: "\e919";
}

.jeia-caret:before {
  content: "\e91a";
}

.jeia-arrow-left:before {
  content: "\e91b";
}

.jeia-arrow_b1:before {
  content: "\e91c";
}

.jeia-arrow_b2:before {
  content: "\e91d";
}

.jeia-truck:before {
  content: "\e91e";
}

.jeia-coins:before {
  content: "\e91f";
}

.jeia-creditcard:before {
  content: "\e920";
}

.jeia-twitter:before {
  content: "\e921";
}

.jeia-facebook:before {
  content: "\e922";
}

.jeia-googleplus:before {
  content: "\e923";
}

.jeia-instagram:before {
  content: "\e924";
}

.jeia-volume-mute:before {
  content: "\e925";
}

.jeia-volume-up:before {
  content: "\e926";
}

.jeia-angleup:before {
  content: "\e927";
}

.jeia-info:before {
  content: "\e928";
}

.jeia-crown:before {
  content: "\e929";
}

.jeia-eye:before {
  content: "\e92a";
}

.jeia-btn:before {
  content: "\e92b";
}

.jeia-fax:before {
  content: "\e92c";
}

.jeia-grid:before {
  content: "\e92d";
}

.jeia-cart2:before {
  content: "\e92e";
}

.jeia-cart3:before {
  content: "\e92f";
}

.jeia-amazon:before {
  content: "\e930";
}

.jeia-pinterest:before {
  content: "\e931";
}

.jeia-get-pocket:before {
  content: "\e932";
}

.jeia-line:before {
  content: "\e933";
}

.jeia-external-link:before {
  content: "\e934";
}

.jeia-printer:before {
  content: "\e935";
}

.jeia-facebook2:before {
  content: "\e936";
}

.jeia-line2:before {
  content: "\e937";
}

.jeia-hatenabookmark:before {
  content: "\e938";
}

.jeia-play-circle:before {
  content: "\e939";
}

/*
グリッド

画面を12分割し、グリッドレイアウトに対応するためのスタイルです。
*/
/*
2分割グリッド

画面２分割のグリッドです。
Bootstrap の col-sm-6 相当のグリッドを提供します。
*/
.ec-grid2 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-grid2 {
    display: flex;
    justify-content: center;
  }
}
.ec-grid2 .ec-grid2__cell {
  min-height: 1px;
}
@media only screen and (min-width: 1280px) {
  .ec-grid2 .ec-grid2__cell {
    width: 50%;
  }
}
.ec-grid2 .ec-grid2__cell2 {
  min-height: 1px;
}
@media only screen and (min-width: 1280px) {
  .ec-grid2 .ec-grid2__cell2 {
    width: 100%;
  }
}

/*
3分割グリッド

画面３分割のグリッドです。
*/
.ec-grid3 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-grid3 {
    display: flex;
    justify-content: center;
  }
}
.ec-grid3 .ec-grid3__cell {
  min-height: 1px;
}
@media only screen and (min-width: 1280px) {
  .ec-grid3 .ec-grid3__cell {
    width: 33.3333333333%;
  }
}
.ec-grid3 .ec-grid3__cell2 {
  min-height: 1px;
}
@media only screen and (min-width: 1280px) {
  .ec-grid3 .ec-grid3__cell2 {
    width: 66.6666666667%;
  }
}
.ec-grid3 .ec-grid3__cell3 {
  min-height: 1px;
}
@media only screen and (min-width: 1280px) {
  .ec-grid3 .ec-grid3__cell3 {
    width: 100%;
  }
}

/*
4分割グリッド

画面４分割のグリッドです。
*/
.ec-grid4 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-grid4 {
    display: flex;
    justify-content: center;
  }
}
.ec-grid4 .ec-grid4__cell {
  min-height: 1px;
}
@media only screen and (min-width: 1280px) {
  .ec-grid4 .ec-grid4__cell {
    width: 25%;
  }
}

/*
6分割グリッド

2つにまとめた cell2 や 3つをまとめた cell3 タグも使用可能です。
*/
.ec-grid6 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-grid6 {
    display: flex;
    justify-content: center;
  }
}
.ec-grid6 .ec-grid6__cell {
  min-height: 1px;
}
@media only screen and (min-width: 1280px) {
  .ec-grid6 .ec-grid6__cell {
    width: 16.6666666667%;
  }
}
.ec-grid6 .ec-grid6__cell2 {
  min-height: 1px;
}
@media only screen and (min-width: 1280px) {
  .ec-grid6 .ec-grid6__cell2 {
    width: 33.3333333333%;
  }
}
.ec-grid6 .ec-grid6__cell3 {
  min-height: 1px;
}
@media only screen and (min-width: 1280px) {
  .ec-grid6 .ec-grid6__cell3 {
    width: 50%;
  }
}

/*
中央寄せグリッド 10/12

左右にマージンを持つ、中央寄せグリッドを提供します。12分の10グリッドです<br>

※当テンプレートでは、この.ec-off1Gridのスタイリングはオフにしております。
EC-CUBE標準で備わっている機能は殺したくない所ですが、この.ec-off1Gridはデザイン的に外しておきたく、本来であればTwig側で調整すべきですが、
テンプレート的にTwigの編集は必要以外、極力しない方向でいきたいと考えております。
*/
/*
中央寄せグリッド 8/12

左右にマージンを持つ、中央寄せグリッドを提供します。12分の8グリッドです
*/
.ec-off2Grid {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-off2Grid {
    display: flex;
    justify-content: center;
  }
}
.ec-off2Grid .ec-off2Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-off2Grid .ec-off2Grid__cell {
    min-height: 1px;
  }
}
@media only screen and (min-width: 1280px) and (min-width: 1280px) {
  .ec-off2Grid .ec-off2Grid__cell {
    width: 66.6666666667%;
  }
}

/*
中央寄せグリッド 6/12

左右にマージンを持つ、中央寄せグリッドを提供します。12分の6グリッドです
*/
.ec-off3Grid {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-off3Grid {
    display: flex;
    justify-content: center;
  }
}
.ec-off3Grid .ec-off3Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-off3Grid .ec-off3Grid__cell {
    min-height: 1px;
  }
}
@media only screen and (min-width: 1280px) and (min-width: 1280px) {
  .ec-off3Grid .ec-off3Grid__cell {
    width: 50%;
  }
}

/*
中央寄せグリッド 4/12

左右にマージンを持つ、中央寄せグリッドを提供します。12分の4グリッドです
*/
.ec-off4Grid {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-off4Grid {
    display: flex;
    justify-content: center;
  }
}
.ec-off4Grid .ec-off4Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-off4Grid .ec-off4Grid__cell {
    min-height: 1px;
  }
}
@media only screen and (min-width: 1280px) and (min-width: 1280px) {
  .ec-off4Grid .ec-off4Grid__cell {
    width: 33.3333333333%;
  }
}

/*
グリッドオプション

グリッドのセルに対して「左寄せ」「中央寄せ」「右寄せ」のオプションを付与することができます。
*/
/*
グリッドセルの左寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。
*/
.ec-grid--left {
  justify-content: flex-start;
}

/*
グリッドセルの右寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。
*/
.ec-grid--right {
  justify-content: flex-end;
}

/*
グリッドセルの中央寄せ

.ec-gridに.ec-grid--centerを付与すると内包してるセルを中央寄せにすることができます。（※デフォルトで中央寄せなので不要かと思われる）
*/
.ec-grid--center {
  justify-content: center;
}

/*
グリッドのタブレット時の挙動を追加

offGridを使う場面とは、メインコンテンツ幅に対して少し幅の狭いブロックでレイアウトデザインを組みたい場面と言えると思います。
（EC-CUBE標準ではメインコンテンツ自体を少し幅を狭めるorr1Gridも多用されておりますが…）
offGridはデスクトップ向けのスタイリングですのでタブレットでは適用されずフル幅になってしまいます。
ここではタブレット時もoffGridのスタイリングが加味されるよう調整しています。
*/
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-off2Grid,
.ec-off3Grid,
.ec-off4Grid {
    display: block;
    margin: 0;
  }
  .ec-off2Grid .ec-off2Grid__cell,
.ec-off2Grid .ec-off3Grid__cell,
.ec-off2Grid .ec-off4Grid__cell,
.ec-off3Grid .ec-off2Grid__cell,
.ec-off3Grid .ec-off3Grid__cell,
.ec-off3Grid .ec-off4Grid__cell,
.ec-off4Grid .ec-off2Grid__cell,
.ec-off4Grid .ec-off3Grid__cell,
.ec-off4Grid .ec-off4Grid__cell {
    width: 450px;
    margin: 0 auto;
  }
}
/*
レイアウト

様々なレイアウトを変更する為のスタイル群です。
*/
/*
画像レイアウト

画像とテキストを水平に並べるレイアウトです。
*/
.ec-imageGrid {
  display: flex;
  width: 100%;
}
.ec-imageGrid .ec-imageGrid__img {
  flex: 0 0 auto;
  width: 22vw;
  max-width: 120px;
}
.ec-imageGrid .ec-imageGrid__img img {
  width: 100%;
}
.ec-imageGrid .ec-imageGrid__content {
  flex: 1 1 auto;
  padding-left: 1em;
}
.ec-imageGrid .ec-imageGrid__content p {
  font-size: 0.9em;
  margin: 0;
}
.ec-imageGrid .ec-imageGrid__content p:first-child {
  font-weight: 700;
}
.ec-imageGrid .ec-imageGrid__content p span {
  display: inline-block;
  margin-left: 1em;
}

/*
ログイン

主にログインフォームのスタイルを表示します。
*/
/*
ログインフォーム

ログインフォームを表示します。
*/
.ec-login .ec-login__icon .ec-icon {
  margin-bottom: 10px;
  text-align: center;
}
@media only screen and (min-width: 1280px) {
  .ec-login .ec-login__icon .ec-icon {
    text-align: left;
  }
}
.ec-login .ec-login__icon .ec-icon img {
  width: 50px;
  height: 50px;
}
.ec-login .ec-login__input {
  margin-bottom: 10px;
}
.ec-login .ec-login__input input + input {
  margin-top: 1em;
}
.ec-login .ec-login__input .ec-checkbox, .ec-login .ec-login__input .ec-blockCheckbox {
  margin-top: 0.5em;
}
.ec-login .ec-login__input .ec-checkbox label, .ec-login .ec-login__input .ec-blockCheckbox label {
  font-size: 0.9rem;
}
.ec-login .ec-login__input .ec-checkbox label .form-check, .ec-login .ec-login__input .ec-blockCheckbox label .form-check {
  padding-left: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-login .ec-login__input {
    margin-bottom: 20px;
  }
}
.ec-login .ec-login__link {
  margin-top: 0.5em;
}
@media only screen and (min-width: 1280px) {
  .ec-login .ec-login__link {
    margin-top: 0;
    margin-left: 1.5em;
    margin-bottom: 0.5em;
  }
}
.ec-login .ec-login__link a {
  color: inherit;
  padding-top: 2px;
  padding-bottom: 2px;
  position: relative;
  text-decoration: none;
}
.ec-login .ec-login__link a:hover {
  text-decoration: none !important;
}
.ec-login .ec-login__link a:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left bottom;
  transform: scaleX(0);
  transition: transform 0.3s;
}
.ec-login .ec-login__link a:hover:after {
  transform: scaleX(1);
}
.ec-login .ec-login__link a:hover {
  text-decoration: underline;
}

/*
ゲスト購入

ゲスト購入ボタンとそのフォームを表示します。
*/
.ec-guest {
  display: table;
  width: 100%;
  margin-top: 20px;
  padding: 1.5em;
  box-sizing: border-box;
  background: var(--clrBodyToneBg, #f8f8f8);
}
@media only screen and (min-width: 1280px) {
  .ec-guest {
    width: auto;
    margin: 0;
    height: 100%;
    margin-left: 40px;
  }
}
.ec-guest .ec-guest__inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.ec-guest .ec-guest__inner p {
  margin-bottom: 1em;
}
.ec-guest .ec-guest__actions {
  display: block;
  text-align: center;
}

/*
商品掲載アイテムバナー？（使用箇所なし）

トップページに商品掲載するスタイルガイド群です。
*/
/*
検索・一覧表示

検索欄や、一覧表示に使用するスタイル群です。
*/
/*
ページャ

ページャは商品一覧ページ、マイページ注文履歴、お気に入りページで使用されています。
*/
.ec-pager {
  list-style: none;
  margin: 30px 0 0;
  padding: 0;
  font-family: "Oswald", "Noto Sans JP", sans-serif;
  font-size: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
@media only screen and (min-width: 1280px) {
  .ec-pager {
    margin-top: 60px;
  }
}
.ec-pager .ec-pager__item {
  margin: 3px;
}
.ec-pager .ec-pager__item a {
  display: block;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0 0.75em;
  background-color: var(--clrNoactive, #ddd);
  color: #fff;
  transition: 0.2s;
}
.ec-pager .ec-pager__item a:hover {
  text-decoration: none;
  background-color: #000;
}
.ec-pager .ec-pager__item.is-active a {
  background-color: var(--clrActive, #000);
  pointer-events: none;
}
.ec-pager .ec-pager__item.item--prev a {
  padding-left: 0.3em;
}
.ec-pager .ec-pager__item.item--prev a:before {
  font-family: "jeiafont";
  content: "\e91b";
  margin-right: 0.2em;
}
.ec-pager .ec-pager__item.item--next a {
  padding-right: 0.3em;
}
.ec-pager .ec-pager__item.item--next a:after {
  font-family: "jeiafont";
  content: "\e902";
  margin-left: 0.2em;
}

/*
カート

ショッピングカートに関するスタイルです。
*/
/*
プログレス表示

購入完了までの手順や、現在の進捗状況をプログレス表示します。
*/
.ec-progress {
  list-style: none;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .ec-progress {
    width: calc(100% + 20px);
    transform: translateX(-10px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-progress {
    max-width: 62.5vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-progress {
    max-width: 600px;
  }
}
.ec-progress .ec-progress__item {
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  font-size: 1.3rem;
  display: table-cell;
  text-align: center;
  position: relative;
  z-index: 10;
}
@media only screen and (max-width: 767px) {
  .ec-progress .ec-progress__item {
    font-size: 1.2rem;
  }
}
.ec-progress .ec-progress__item:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--clrBorderColor, #ddd);
  position: absolute;
  top: 1em;
  left: 50%;
  transform: translate(0, -50%);
  z-index: -1;
}
.ec-progress .ec-progress__item:last-child:after {
  display: none;
}
.ec-progress .ec-progress__number {
  background-color: var(--clrNoactive, #ddd);
  color: #fff;
  display: inline-block;
  line-height: 2em;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 0.25em;
}
.ec-progress .ec-progress__label {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 0.8rem;
  padding: 0;
  line-height: 1.2;
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .ec-progress .ec-progress__label {
    font-size: 0.7rem;
  }
}
.ec-progress .is-complete .ec-progress__number {
  background-color: var(--clrActive, #000);
}
.ec-progress .is-complete .ec-progress__label {
  color: var(--clrActive, #000);
}

/*
「カートナビゲーション」は、_11.2.header.scssへ移動
*/
/*
総計

会計時の合計金額、総計を表示します。
*/
.ec-totalBox {
  background-color: var(--clrCartTotalBoxBg, #f8f8f8);
  color: var(--clrCartTotalBoxText, #444);
  border: solid 1px var(--clrBorderColor, #ddd);
  padding: 1.5em;
  padding-top: 1em;
  /* 各項目 */
  /* 合計額 */
  /* 合計額：価格 */
  /* 合計額：「税込み」ラベル */
  /* お支払い合計 */
  /* 税率表示 */
  /* ポイント利用 */
  /* ボタンエリア */
}
@media only screen and (max-width: 767px) {
  .ec-totalBox {
    padding: 0.5em 1em 1em;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-totalBox {
    padding: 0.5em 1em 1em;
  }
}
.ec-totalBox .ec-totalBox__spec {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
  padding: 0.5em 0.25em;
  margin: 1em 0;
  line-height: 1;
}
.ec-totalBox .ec-totalBox__spec dt {
  font-weight: normal;
  margin: 0;
}
.ec-totalBox .ec-totalBox__spec dt .ec-font-bold {
  font-weight: normal;
}
.ec-totalBox .ec-totalBox__spec dd {
  text-align: right;
  margin: 0;
}
.ec-totalBox .ec-totalBox__spec:first-child {
  margin-top: 0;
}
.ec-totalBox .ec-totalBox__total {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
  padding: 0.5em 0.25em;
  line-height: 1;
  font-weight: 500;
  margin: 1em 0;
}
.ec-totalBox .ec-totalBox__price {
  flex: 1 1 auto;
  font-size: 1.6rem;
  color: var(--clrDefaultDark, #000);
  text-align: right;
  margin-bottom: -3px;
}
.ec-totalBox .ec-totalBox__taxLabel {
  margin-left: 5px;
  font-size: 0.85rem;
  font-weight: normal;
}
.ec-totalBox .ec-totalBox__paymentTotal {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
  padding: 0.5em 0.25em;
  line-height: 1;
  font-weight: 500;
  margin: 1em 0 1.5em;
}
.ec-totalBox .ec-totalBox__paymentTotal .ec-totalBox__price,
.ec-totalBox .ec-totalBox__paymentTotal .ec-totalBox__taxLabel {
  color: #e2251f;
}
.ec-totalBox .ec-totalBox__taxRate {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5em;
  font-size: 0.85rem;
}
.ec-totalBox .ec-totalBox__taxRate dt {
  font-weight: normal;
}
.ec-totalBox .ec-totalBox__taxRate dd {
  margin-left: 1em;
}
.ec-totalBox .ec-totalBox__btn {
  margin-top: 2em;
}
.ec-totalBox .ec-totalBox__btn .ec-blockBtn--action {
  margin-bottom: 10px;
}

/*
お知らせ（未使用）

新着情報やバナーなどの掲載項目を紹介していきます。
*/
/*
バナー
*/
/*
マイページ

マイページで利用するためのスタイルガイド群です。

メモ：
マイページのほぼすべてのページ（login.twig, navi.twig以外）において、一番外枠に「ec-layoutRole__main」要素が挿入されています。
「ec-layoutRole__main」は、__default_frame.twig__が出力するフレームですので、「ec-layoutRole__main」が2重になっています。
レイアウトには全く影響はないのでスルーしておいてもいいとは思いますが、一応…

「ec-layoutRole__main」は1カラムレイアウト時に出力されるフレームで、2～3カラム時は別のフレームが出力されます。
2～3カラム時は、そのメインカラムの中で1カラム用「ec-layoutRole__main」が入る形になりますが、デザインレイアウト上の問題はありません。
気になる場合はマイページに挿入された「ec-layoutRole__main」を削除するといいと思います。（時期バージョンで解消希望）
*/
/*
マイページ メニューナビ

マイページで表示するメニューナビです。

スマホ時はこのメニューナビを非表示にしています。
代わりに、MENUボタンを配置しそのボタンによりドロワーをOPEN、ドロワー内にマイページのメニューが含まれています。

※スマホ用MENUボタンはjsで生成して挿入するようにしていますが、navi.twigにHTML記述しておいてもいいかもしれません。
*/
.ec-navlistRole {
  font-size: 1rem;
  margin-bottom: 1em;
}
.ec-navlistRole .ec-navlistRole__navlist {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.933rem;
  display: flex;
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
  padding-bottom: 1em;
  margin-bottom: 1em;
}
.ec-navlistRole .ec-navlistRole__item {
  property: 27.5%, 72.5%;
  border-left: solid 1px transparent;
  border-image-width: 0 0 0 1px;
  border-image: linear-gradient(to top, transparent 27.5%, var(--clrBorderColor, #ddd) 27.5%, var(--clrBorderColor, #ddd) 72.5%, transparent 72.5%);
  border-image-slice: 1;
  transition: 0.2s;
}
.ec-navlistRole .ec-navlistRole__item:first-child, .ec-navlistRole .ec-navlistRole__item:hover, .ec-navlistRole .ec-navlistRole__item:hover + li, .ec-navlistRole .ec-navlistRole__item.active, .ec-navlistRole .ec-navlistRole__item.active + li {
  border-image: linear-gradient(to top, transparent 0%, transparent 100%);
}
.ec-navlistRole .ec-navlistRole__item a {
  display: block;
  color: inherit;
  padding: 0.65em 1em;
  text-align: center;
  transition: 0.3s;
}
.ec-navlistRole .ec-navlistRole__item a:hover {
  text-decoration: none;
  background-color: var(--clrHelpNavHover, #eee);
}
.ec-navlistRole .ec-navlistRole__item.active a {
  background-color: var(--clrHelpNavActiveBg, #333);
  color: var(--clrHelpNavActiveText, #fff);
}
.ec-navlistRole .mypageMenuBtn {
  display: none;
}
@media only screen and (max-width: 767px) {
  .ec-navlistRole {
    position: relative;
  }
  .ec-navlistRole .ec-navlistRole__navlist {
    display: none;
  }
  .ec-navlistRole .mypageMenuBtn {
    display: block;
    font-size: 0.8rem;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding-left: 0.5em;
  }
  .ec-navlistRole .mypageMenuBtn i {
    display: inline-block;
    vertical-align: -0.18em;
    font-size: 1.4em;
    margin-right: 3px;
    opacity: 0.6;
  }
}

/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。
*/
.ec-welcomeMsg {
  margin-bottom: 2em;
  margin-left: -1em;
  font-size: 0.933rem;
}
@media only screen and (min-width: 1280px) {
  .ec-welcomeMsg {
    margin-bottom: 60px;
  }
}
.ec-welcomeMsg p {
  display: inline-block;
  margin: 0;
  margin-left: 1em;
}

/*
マイページ：注文履歴一覧

→ _16.1.history.scss
*/
/*
マイページ：お気に入り一覧
*/
.ec-favoriteRole .ec-favoriteRole__header {
  margin-bottom: 1em;
}
.ec-favoriteRole .ec-favoriteRole__itemList {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .ec-favoriteRole .ec-favoriteRole__itemList {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -20px;
  }
  .ec-favoriteRole .ec-favoriteRole__itemList > * {
    flex: 0 0 auto;
    width: calc((100% - 10px ) / 2);
    margin-left: 10px;
    margin-top: 20px;
  }
  .ec-favoriteRole .ec-favoriteRole__itemList > *:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-favoriteRole .ec-favoriteRole__itemList {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -20px;
  }
  .ec-favoriteRole .ec-favoriteRole__itemList > * {
    flex: 0 0 auto;
    width: calc((100% - 60px ) / 4);
    margin-left: 20px;
    margin-top: 20px;
  }
  .ec-favoriteRole .ec-favoriteRole__itemList > *:nth-child(4n+1) {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-favoriteRole .ec-favoriteRole__itemList {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -20px;
  }
  .ec-favoriteRole .ec-favoriteRole__itemList > * {
    flex: 0 0 auto;
    width: calc((100% - 120px ) / 5);
    margin-left: 30px;
    margin-top: 20px;
  }
  .ec-favoriteRole .ec-favoriteRole__itemList > *:nth-child(5n+1) {
    margin-left: 0;
  }
}
.ec-favoriteRole .ec-favoriteRole__item {
  position: relative;
}
.ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle {
  position: absolute;
  right: 7px;
  top: 7px;
  z-index: 9;
}
.ec-favoriteRole .ec-favoriteRole__item-image {
  margin: 0;
}
.ec-favoriteRole .ec-favoriteRole__item-image img {
  width: 100%;
  height: auto;
}
.ec-favoriteRole .ec-favoriteRole__itemThumb {
  display: block;
  display: block;
  overflow: hidden;
  /* HTML構造によってはIE11でちらつく事があるので保険 */
}
.ec-favoriteRole .ec-favoriteRole__itemThumb * {
  overflow: hidden;
}
.ec-favoriteRole .ec-favoriteRole__itemThumb img {
  transition: 0.6s;
}
.ec-favoriteRole .ec-favoriteRole__itemThumb:hover img {
  transform: scale(1.1);
  filter: brightness(1.05);
}
.ec-favoriteRole .ec-favoriteRole__itemTitle {
  margin: 0;
  margin-top: 0.5em;
  font-weight: 700;
}
.ec-favoriteRole .ec-favoriteRole__itemPrice {
  margin: 0;
}

/*
レイアウトフレーム

当テンプレートは1カラムデザインとなっています。ここでは2～3カラムレイアウト定義は省いて1カラムデザインのみスタイリングしています。
*/
.ec-layoutRole {
  width: 100%;
  transition: transform 0.3s;
  background: transparent;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}
.ec-layoutRole .ec-layoutRole__contentTop {
  padding: 0;
}
.ec-layoutRole .ec-layoutRole__contents {
  width: 100%;
}
.ec-layoutRole .ec-layoutRole__main {
  width: 100%;
}
.ec-layoutRole .ec-layoutRole__footer {
  margin-top: auto;
  margin-bottom: 0;
}

/*
レイアウトフレーム（2～3カラム）

当テンプレートは1カラムデザインとなっており、2～3カラムレイアウトは使っておりませんが、
別レイアウトの追加や、またカスタマイズが可能なように定義は準備してあります。

左カラム・右カラムに配置されたブロックは、EC-CUBE4ではモバイル時は（スマホ・タブレット時）非表示扱いのようですが、
当テンプレートではメインフレームの下に配置するようにしてあります。
*/
/*
.ec-layoutRole {

  @include media_sphone {
    & &__contents {
      display: flex;
      flex-flow: row wrap;
    }

    & &__mainWithColumn,
    & &__mainBetweenColumn {
      order: 1;
      width: 100%;
      max-width: 100%;
    }

    & &__left,
    & &__right {
      order: 2;
      width: 100%;
      max-width: 100%;
      margin-top: $padding-sphone;
      padding-left: $padding-sphone;
      padding-right: $padding-sphone;
    }

    & &__right {
      order: 3;
    }
  }

  @include media_tablet {
    & &__contents {
      display: flex;
      flex-flow: row wrap;
    }

    & &__mainWithColumn,
    & &__mainBetweenColumn {
      order: 1;
      width: 100%;
      max-width: 100%;
    }

    & &__left,
    & &__right {
      order: 2;
      width: 100%;
      max-width: 100%;
      margin-top: $padding-tablet;
      padding-left: $padding-tablet;
      padding-right: $padding-tablet;
    }

    & &__right {
      order: 3;
    }
  }

  @include media_desktop {
    & &__contents {
      display: flex;
      flex-flow: row wrap;
    }

    & &__mainWithColumn {
      order: 2;
      width: 75%;
      max-width: 75%;// for IE11
    }

    & &__mainBetweenColumn {
      order: 2;
      width: 50%;
      max-width: 50%;// for IE11
    }

    & &__left {
      order: 1;
      flex: 0 0 25%;
      max-width: 25%;// for IE11

      box-sizing: border-box;
      padding-left: $padding-desktop;
    }

    & &__right {
      order: 3;
      flex: 0 0 25%;
      max-width: 25%;// for IE11

      box-sizing: border-box;
      padding-right: $padding-desktop;
    }
  }
}
*/
/*
汎用コンテナ

汎用的なコンテナブロックです。

mixin「コンテナ」を@includeしています。
mixin「コンテナ」は、コンテナ自体のmax-width、左右余白などブロックスタイルの共通スタイリングを施しています。
各セクションの左右余白の統一感はコンテナによってもたらされます。

背景色をブラウザ幅フルに敷く場合は「max-width:100%;」上書きしておいて、コンテナの中にinner的要素を設けてそちらで改めてmax-widthを指定します。
*/
.ec-role {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-role {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-role {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-role {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-role, .ec-layoutRole__mainBetweenColumn .ec-role {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

/*
マイページコンテナ

マイページ専用のコンテナブロックです。

※当テンプレートでは汎用コンテナと同一です。
*/
.ec-mypageRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-mypageRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-mypageRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-mypageRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-mypageRole, .ec-layoutRole__mainBetweenColumn .ec-mypageRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

/*
その他コンテナ

その都度、セクションごとに「@include container;」してコンテナ化させています。
上記、マイページコンテナもマイページ定義のscss側に移動したほうがいいかとも思います。
*/
/*
ヘッダー＆ドロワー
*/
/*
ヘッダーフレーム構造

まずはヘッダーのフレーム構造について定義しています。
ヘッダーは横3列のフレーム構造になっております（デスクトップ時）。
スマホ・タブレット時では中央枠のフレーム（ヘッダーメニュー部分）が非表示となります。
*/
.ec-layoutRole__header {
  /*
  ※デスクトップ用
  4.1.1以前ではヘッダー高さの指定はヘッダー枠「.ec-headerRole」に対して指定していましたが、こちらレイアウトフレーム枠の「.ec-layoutRole__header」にも同じく高さを入れておきます。
  ヘッダー枠にもそれを格納するレイアウトフレーム枠にも両方、高さを指定する形になります。
  その理由とは、スクロール判定でヘッダー高さが縮む挙動を入れておりますが（デスクトップ時）、ヘッダーの固定は昔はfixedで実装してたのをこれをstickyに切り替えた事で、
  ヘッダーが縮むとそれに伴い、ページ全体の高さも変化するためにスクロール量自体が変わり、結果、ヘッダー縮みの挙動がぺこぺこしてしまう事があります（Chromeで顕著に出現）。
  その対策として、ヘッダー枠「.ec-headerRole」は縮むがその格納レイアウトフレーム枠「.ec-layoutRole__header」は縮まず高さを維持する事からページ全体高さに影響しないようにします。
  この対策に伴い、ヘッダーの背景色はこちらの格納レイアウトフレーム枠にではなくヘッダー枠「.ec--headerRole」側で定義させる必要があります。（※背景色定義は末尾の「ヘッダー挙動」にて）

  ※これによりヘッダーレイアウトフレーム枠にはヘッダー以外を入れてはいけない事になります。「ヘッダーインフォメーション」はjsでレイアウトフレームの外に出す事にしました（他ToDoあり）。
  */
}
@media only screen and (min-width: 1280px) {
  .ec-layoutRole__header {
    height: 70px;
    pointer-events: none;
  }
  .ec-layoutRole__header > * {
    pointer-events: auto;
  }
}

/* .ec-headerRole フレーム構造 */
.ec-headerRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: rgb(var(--clrHeaderBgRGB, 17, 18, 20));
}
@media only screen and (max-width: 767px) {
  .ec-headerRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-headerRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-headerRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-headerRole, .ec-layoutRole__mainBetweenColumn .ec-headerRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ec-headerRole {
    height: 45px;
    padding: 0;
  }
  .ec-headerRole .ec-headerRole__left {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
  }
  .ec-headerRole .ec-headerRole__center {
    display: none;
  }
  .ec-headerRole .ec-headerRole__right {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-headerRole {
    height: 50px;
    padding: 0;
  }
  .ec-headerRole .ec-headerRole__left {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
  }
  .ec-headerRole .ec-headerRole__center {
    display: none;
  }
  .ec-headerRole .ec-headerRole__right {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-headerRole {
    height: 70px;
    /* 各ボタンなど改行されないよう幅調整をしてください（中央枠のサイズを決定してそれをもとに、左枠と右枠の幅が同一になるようにして中央枠をセンタリングしています） */
  }
  .ec-headerRole .ec-headerRole__left {
    flex: 1 1 35%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .ec-headerRole .ec-headerRole__center {
    flex: 1 1 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ec-headerRole .ec-headerRole__right {
    flex: 1 1 35%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

/*
ヘッダーナビ
*/
.ec-headerNav {
  font-family: "Georgia";
  font-size: 15px;
  color: var(--clrHeaderText, #fff);
}
.ec-headerNav__item {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  /* サブメニュー枠組み */
}
.ec-headerNav__item:last-child {
  margin-right: 0;
}
.ec-headerNav__item > a {
  display: block;
  color: inherit;
  padding-top: 2px;
  padding-bottom: 2px;
  position: relative;
  text-decoration: none;
}
.ec-headerNav__item > a:hover {
  text-decoration: none !important;
}
.ec-headerNav__item > a:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--clrHeaderText, #fff);
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left bottom;
  transform: scaleX(0);
  transition: transform 0.3s;
}
.ec-headerNav__item > a:hover:after {
  transform: scaleX(1);
}
.ec-headerNav__item.has-submenu {
  position: relative;
}
.ec-headerNav__item.has-submenu .submenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: auto;
  min-width: 300px;
  padding-top: 14px;
  /*くちばし分の余白*/
  pointer-events: none;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transform: translate3d(0, -15px, 0);
  transition: 0.2s;
}
.ec-headerNav__item.has-submenu .submenu__body {
  background-color: #fff;
  color: #333;
  padding: 15px;
  filter: drop-shadow(0 0 1px #ddd) drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  position: relative;
  /* くちばし */
}
.ec-headerNav__item.has-submenu .submenu__body:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  left: 15px;
  border: solid 10px transparent;
  border-bottom-color: #fff;
}
.ec-headerNav__item.has-submenu:hover .submenu {
  visibility: visible;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
}

/* サブメニュー内容（カテゴリツリー） */
.ec-categoryTree {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 0.933rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.ec-categoryTree li {
  margin: 0;
  padding: 0;
  border-bottom: solid 1px #eee;
  white-space: nowrap;
  position: relative;
}
.ec-categoryTree li a {
  display: block;
  line-height: 1;
  padding: 1em 0.5em;
  transition: 0.2s;
  color: inherit;
}
.ec-categoryTree li a:hover {
  text-decoration: none;
  background-color: #f0f1f2;
}
.ec-categoryTree li ul.child {
  margin: 0;
  padding: 0;
  list-style-type: none;
  min-width: 10em;
  position: absolute;
  top: 0.5em;
  left: calc(100% - 0.5em);
  background-color: #fff;
  color: inherit;
  padding: 15px;
  z-index: 9;
  border: solid 1px #ddd;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
}
.ec-categoryTree li:hover > ul.child {
  visibility: visible;
  opacity: 1;
}

/*
ドロワー開閉ボタン

ドロワー開閉ボタンはスマホ・タブレット時に表示されます。
*/
/* ドロワーボタン（サイドメニュー開閉ボタン） */
.ec-drawerButton {
  display: block;
  cursor: pointer;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .ec-drawerButton {
    width: 45px;
    height: 45px;
    padding: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-drawerButton {
    width: 50px;
    height: 50px;
    padding: 12px;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-drawerButton {
    display: none;
  }
}
.ec-drawerButton .burger {
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--clrHeaderBurger, #fff);
  position: relative;
  top: 50%;
  text-indent: -9999px;
  transition: 0.3s;
  /* ドロワー（サイドメニュー）表示時 */
}
.ec-drawerButton .burger:before, .ec-drawerButton .burger:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--clrHeaderBurger, #fff);
  position: absolute;
  left: 0;
  transition: 0.3s;
}
.ec-drawerButton .burger:before {
  top: -8px;
}
.ec-drawerButton .burger:after {
  bottom: -8px;
}
body.have_curtain .ec-drawerButton .burger {
  background-color: transparent;
}
body.have_curtain .ec-drawerButton .burger:before {
  top: 0;
  transform: rotate(225deg);
}
body.have_curtain .ec-drawerButton .burger:after {
  bottom: 0;
  transform: rotate(135deg);
}

/*
ヘッダータイトル
*/
.ec-headerTitle {
  display: block;
  /*
   * デスクトップ時はページスクロールされるとヘッダー高さを縮小させますので、縮小に伴い、
   * ヘッダーロゴ画像が連動して縮小されるように高さに合わせて幅が変動するように準備します。（※ヘッダー縮小の挙動スタイリングは当scssの最後の方で記述しています）
   * ロゴ画像自体の高さ（Sass変数）をmax-heightに設定しておき、ロゴ画像がそれ以上大きくならないようにしています。
   *
   * ロゴ画像のデザインにもよりますが、モバイル時のロゴの収まりも優先して画像生成して、デスクトップ時はそれに合わせて調整（上下中央配置）できた方がいいと思います。
   */
}
.ec-headerTitle a {
  transition: 0.3s;
}
.ec-headerTitle a:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 767px) {
  .ec-headerTitle {
    height: 100%;
    /* 中央配置する場合はコメント解除してください */
    /*
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    */
  }
  .ec-headerTitle a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .ec-headerTitle a img {
    height: 40px;
    width: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-headerTitle {
    height: 100%;
  }
  .ec-headerTitle a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .ec-headerTitle a img {
    height: 45px;
    width: auto;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-headerTitle {
    height: 100%;
  }
  .ec-headerTitle a {
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
  }
  .ec-headerTitle a img {
    width: auto;
    height: 100%;
    max-height: var(--headerLogoHeight, 70px);
  }
}

/*
ヘッダーメニュー

ヘッダーメニューは、会員登録、ログインや、商品検索、カートといったユーザメニューで構成されています。
またマウスオーバーでボタンガイド的なポップアップが表示されるようにしています。
*/
.ec-headerMenu {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--clrHeaderText, #fff);
}
.ec-headerMenu .ec-headerMenu__item {
  padding: 0;
  /* スマホ時に非表示とするボタン */
  /* 最後のitemは右余白を除去 */
}
.ec-headerMenu .ec-headerMenu__item > a {
  display: block;
  color: inherit;
  line-height: 1;
  padding: 7px 7px;
  border-radius: 18px;
  border: solid 1px transparent;
  transition: 0.2s;
}
.ec-headerMenu .ec-headerMenu__item > a:hover {
  text-decoration: none;
  background-color: var(--clrHeaderMenuHoverBg, rgba(255, 255, 255, 0.2));
}
@media only screen and (max-width: 767px) {
  .ec-headerMenu .ec-headerMenu__item.hidden_mobile {
    display: none;
  }
}
.ec-headerMenu .ec-headerMenu__item:last-child {
  padding-right: 0;
}
.ec-headerMenu .ec-headerMenu__itemIcon {
  font-size: 20px;
}
.ec-headerMenu .ec-headerMenu__itemText {
  line-height: 20px;
}
@media only screen and (max-width: 767px) {
  .ec-headerMenu .ec-headerMenu__itemText {
    display: none;
  }
}

/* ポップアップ */
.ec-headerMenu .ec-headerMenu__item.has-popup {
  position: relative;
}
.ec-headerMenu .ec-headerMenu__item.has-popup:hover .ec-headerMenu__itemPopup {
  visibility: visible;
  opacity: 1;
  transform: translate3d(-50%, 0, 0);
  pointer-events: auto;
}
.ec-headerMenu .ec-headerMenu__itemPopup {
  position: absolute;
  top: 100%;
  left: 50%;
  width: auto;
  min-width: 200px;
  padding-top: 15px;
  /*くちばし分の余白*/
  pointer-events: none;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transform: translate3d(-50%, -15px, 0);
  transition: 0.2s;
}
.ec-headerMenu .ec-headerMenu__itemPopup .itemPopupInner {
  background-color: #fff;
  color: #333;
  padding: 20px;
  text-align: center;
  white-space: nowrap;
  filter: drop-shadow(0 0 1px #ddd) drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  position: relative;
  /* くちばし */
}
.ec-headerMenu .ec-headerMenu__itemPopup .itemPopupInner:after {
  content: "";
  width: 0;
  height: 0;
  border: solid 10px transparent;
  border-bottom-color: #fff;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 767px) {
  .ec-headerMenu .ec-headerMenu__itemPopup {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-headerMenu .ec-headerMenu__itemPopup {
    display: none;
  }
}

/*
商品検索ボックス

ヘッダーメニューにある虫眼鏡ボタンをクリックする事で表示される商品検索ボックスのコンポーネントです。

※当テンプレートでは商品検索ボックスはヘッダーのみに実装する考えです。モバイル時でも検索ボックスの開閉ボタンがヘッダーに常駐します。
*/
.ec-headerSearch {
  /* 検索ボックス開閉ボタン */
  /* 検索ボックス全体枠 */
  /* 検索ボックス：カテゴリ&入力欄 レイアウト枠 */
  /* 検索ボックス：カテゴリ選択 */
  /* 検索ボックス：入力欄 */
  /* 検索ボタン */
}
.ec-headerSearch .ec-headerSearch__wrap {
  display: flex;
  align-items: center;
  background-color: #ccc;
  padding: 5px;
}
.ec-headerSearch .ec-headerSearch__category {
  margin-right: 1px;
}
.ec-headerSearch .ec-headerSearch__category .ec-select, .ec-headerSearch .ec-headerSearch__category .ec-birth {
  margin: 0;
}
.ec-headerSearch .ec-headerSearch__category .ec-select .select_ui:after, .ec-headerSearch .ec-headerSearch__category .ec-birth .select_ui:after {
  display: none;
}
.ec-headerSearch .ec-headerSearch__category .ec-select .select_ui select, .ec-headerSearch .ec-headerSearch__category .ec-birth .select_ui select {
  display: block;
  font-size: 13px;
  height: 40px;
  padding: 5px 0.7em !important;
  background-color: #fff;
  border: none;
}
.ec-headerSearch .ec-headerSearch__keyword {
  flex: 1 1 auto;
  position: relative;
}
.ec-headerSearch .ec-headerSearch__keyword input[type=search] {
  border: none;
  margin: 0;
  border-radius: 0;
  font-size: 16px;
  height: 40px;
  padding-left: 1em;
  padding-right: 40px;
}
@media only screen and (min-width: 1280px) {
  .ec-headerSearch .ec-headerSearch__keyword input[type=search] {
    font-size: 13px;
  }
}
.ec-headerSearch .ec-headerSearch__keywordBtn {
  border: none;
  background: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  font-size: 20px;
  color: #555;
}

/*
ヘッダーメニューに設置した商品検索コンポーネントのスタイリング
「商品検索ブロック」はレイアウト管理でドロワー内にも設置可能なので、お互いにスタイリングが汚染影響しないようヘッダーメニュー内に限定したスタイリングを施す
*/
.ec-headerMenu {
  /* 検索ボックス全体枠 */
}
.ec-headerMenu .ec-headerSearch__body {
  position: absolute;
  top: 100%;
  z-index: 998;
  visibility: hidden;
  opacity: 0;
  transform: translate3d(0, -15px, 0);
  transition: 0.3s;
}
.ec-headerMenu .ec-headerSearch__body.is-active {
  visibility: visible;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@media only screen and (max-width: 767px) {
  .ec-headerRole {
    position: relative;
  }

  .ec-headerMenu .ec-headerSearch__body {
    width: 100%;
    left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-headerRole {
    position: relative;
  }

  .ec-headerMenu .ec-headerSearch__body {
    width: 100%;
    left: 0;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-headerMenu {
    position: relative;
  }
  .ec-headerMenu .ec-headerSearch__body {
    left: 0;
    width: 380px;
    padding-top: 14px;
  }
  .ec-headerMenu .ec-headerSearch__wrap {
    position: relative;
  }
  .ec-headerMenu .ec-headerSearch__wrap:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    left: 10px;
    border: solid 10px transparent;
    border-bottom-color: #ccc;
  }
}
/*
カートナビゲーション

カートインがあればカートアイコンに数量をバッジ表示します。
カートボタンにマウスオーバーでカートナビゲーション（カート内容詳細）を表示します。
*/
.ec-cartNavi {
  position: relative;
  /* カート数バッジ */
  /* カート内容詳細ポップアップ枠組み */
}
.ec-cartNavi .ec-cartNavi__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  font-size: 10px;
  line-height: 1;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  background-color: #f55;
  color: #fff;
  pointer-events: none;
  position: absolute;
  top: 0px;
  right: 0px;
}
.ec-cartNavi .ec-cartNavi__body {
  position: absolute;
  top: 100%;
  right: 0;
  width: 320px;
  display: inline-block;
  padding-top: 14px;
  /*くちばし分の余白*/
  pointer-events: none;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transform: translate3d(0, -15px, 0);
  transition: 0.2s;
}
.ec-cartNavi .ec-cartNavi__body .ec-cartNaviWrap {
  background-color: #fff;
  color: #333;
  padding: 20px;
  filter: drop-shadow(0 0 1px #ddd) drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  position: relative;
  /* くちばし */
}
.ec-cartNavi .ec-cartNavi__body .ec-cartNaviWrap:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 10px;
  border: solid 10px transparent;
  border-bottom-color: #fff;
}
.ec-cartNavi:hover .ec-cartNavi__body {
  visibility: visible;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
}

/*
カートナビゲーションのポップアップ(カートイン在り)

カートに商品が追加されている場合のカートナビゲーションのスタイリングです。
*/
.ec-cartNaviIsset {
  font-size: 13px;
  /* 商品画像 */
  /* カート削除ボタン */
  /* 商品詳細 */
  /* 商品名 */
  /* 商品規格 */
  /* 商品価格 */
}
.ec-cartNaviIsset .ec-cartNaviIsset__cart {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #eee;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartImage {
  flex: 0 0 90px;
  order: 2;
  position: relative;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartImage > a {
  display: block;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartDelete a {
  position: absolute;
  top: -10px;
  right: -10px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartDelete a:after {
  font-family: "jeiafont";
  content: "\e918";
  display: block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  color: #333;
  border: solid 1px #aaa;
  box-sizing: border-box;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  transition: 0.2s;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartDelete a:hover:after {
  text-decoration: none;
  transform: translate3d(0, 1px, 0);
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.3);
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartDelete a:hover {
  text-decoration: none;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContent {
  flex: 0 1 auto;
  order: 1;
  padding-right: 10px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentTitle {
  margin-bottom: 0.5em;
  font-weight: 700;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentClass {
  margin: 0;
  margin-left: 10px;
  margin-bottom: 0.5em;
  font-size: 0.85em;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 10px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice .price {
  display: inline-block;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice .tax {
  display: inline-block;
  font-size: 0.85em;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice .quantity {
  display: inline-block;
  margin-left: 10px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__TotalPrice {
  margin: 0;
  display: flex;
  justify-content: space-between;
  font-size: 1.1em;
  font-weight: 500;
}
.ec-cartNaviIsset .ec-cartNaviIsset__TotalPrice dt {
  font-weight: inherit;
}
.ec-cartNaviIsset .ec-cartNaviIsset__action {
  margin-top: 30px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__action .ec-blockBtn--action {
  background-color: #000;
  color: #fff;
  border: none;
}

/*
カートナビゲーションのポップアップ(カートイン無し)

カートが空の状態でのカートナビゲーションのスタイリングです。
*/
.ec-cartNaviNull {
  font-size: 13px;
}
.ec-cartNaviNull .ec-cartNaviNull__message {
  text-align: center;
}
.ec-cartNaviNull .ec-cartNaviNull__message p {
  margin: 0;
}

/*
ドロワー

スマホ・タブレット時に出現するドロワー（サイドメニュー）コンポーネントです。

ここではドロワーの枠組みを定義しています。
EC-CUBE標準ではドロワーOPEN時はドロワー閉ボタンも表示されますが当テンプレートではヘッダーにあるドロワー開閉ボタンがありますので他の閉ボタンは排除しています。
*/
.ec-drawerRole {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  background-color: var(--clrDrawerBg, #111214);
  color: var(--clrDrawerText, #fff);
  visibility: hidden;
  transform: translate3d(-100%, 0, 0);
  transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .ec-drawerRole {
    height: calc(100vh - 45px);
    top: 45px;
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-drawerRole {
    max-width: 400px;
    height: calc(100vh - 50px);
    top: 50px;
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-drawerRole {
    display: none;
  }
}
.ec-drawerRole.is-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

/* ドロワー閉ボタン（※不使用） */
.ec-drawerRoleClose {
  display: none;
}

/* ドロワー背景カーテン */
.ec-overlayRole {
  position: fixed;
  width: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
  /* ドロワー同様、背景カーテンもヘッダーの高さ分だけ短くしておきます */
  /* 表示状態 */
}
@media only screen and (max-width: 767px) {
  .ec-overlayRole {
    height: calc(100vh - 45px);
    top: 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-overlayRole {
    height: calc(100vh - 50px);
    top: 50px;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-overlayRole {
    display: none;
  }
}
body.have_curtain .ec-overlayRole {
  visibility: visible;
  opacity: 1;
}

/*
ドロワーメニュー

ドロワーの中身をこちらでスタイリングしています。
中身のコンテンツはブロック「ログインナビ（SP)」（login_sp.twig）になります。このブロックの中にすべてのコンテンツを記述しています。

EC-CUBE標準だと検索、カテゴリ、各メニューがそれぞれブロックで分離されておりますが、当テンプレートではブロック1つで実装する形にしています。
*/
.ec-drawerMenu {
  font-size: 0.9285rem;
  /*
  カテゴリ折り畳み表示
  商品カテゴリとマイページカテゴリにて使用
  */
  /* ドロワーフッターナビ */
}
.ec-drawerMenu:last-child {
  padding-bottom: 50px;
}
.ec-drawerMenu .ec-drawerMenu__item {
  border-bottom: dotted 1px var(--clrDrawerBorder, rgba(255, 255, 255, 0.4));
}
.ec-drawerMenu .ec-drawerMenu__item a {
  display: block;
  padding: 0.75em 0;
  padding-right: 2em;
  color: inherit;
  position: relative;
}
.ec-drawerMenu .ec-drawerMenu__itemIcon {
  display: inline-block;
  vertical-align: middle;
  color: var(--clrDrawerIcon, #aaa);
  margin-left: 5px;
}
.ec-drawerMenu .ec-drawerMenu__itemBadge {
  display: inline-block;
  font-size: 10px;
  line-height: 1.6em;
  width: 1.6em;
  height: 1.6em;
  text-align: center;
  border-radius: 50%;
  background-color: #f55;
  color: #fff;
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  pointer-events: none;
}
.ec-drawerMenu .ec-drawerMenu__item.has-category {
  position: relative;
}
.ec-drawerMenu .categoryOpenBtn:after {
  font-family: "jeiafont";
  content: "\e902";
  font-size: 1em;
  display: block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  cursor: pointer;
  text-align: center;
  position: absolute;
  top: 0.5em;
  right: 0;
  transition: 0.2s;
}
.ec-drawerMenu .categoryOpenBtn.is-open:after {
  transform: rotate(90deg);
}
.ec-drawerMenu .ec-drawerMenu__category ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.ec-drawerMenu .ec-drawerMenu__category ul li {
  margin: 0;
  padding: 0;
}
.ec-drawerMenu .ec-drawerMenu__category ul li a {
  display: block;
  padding: 0.5em 0;
  padding-right: 2em;
  color: inherit;
}
.ec-drawerMenu .ec-drawerMenu__category ul li.has-child {
  position: relative;
}
.ec-drawerMenu .ec-drawerMenu__category ul.parent {
  display: none;
  margin-bottom: 1em;
}
.ec-drawerMenu .ec-drawerMenu__category ul.parent > li > a:before {
  font-family: "jeiafont";
  content: "\e91a";
  color: var(--clrDrawerIcon, #aaa);
}
.ec-drawerMenu .ec-drawerMenu__category ul.child {
  margin-left: 0.75em;
  padding-left: 0.75em;
  border-left: solid 1px var(--clrDrawerBorder2, rgba(255, 255, 255, 0.3));
  display: none;
}
.ec-drawerMenu.footerNav {
  margin-top: 20px;
}

/*
ヘッダー挙動 その１

当テンプレートではヘッダーをページ上部に固定させる仕様となっております。
デスクトップ時はページスクロールが発生するとヘッダーの高さを縮小させます。
*/
.ec-layoutRole .ec-layoutRole__header {
  position: sticky;
  top: 0;
  z-index: 1001;
  transition: 0.3s;
}
@media only screen and (min-width: 1280px) {
  .ec-layoutRole .ec-layoutRole__header {
    margin-bottom: var(--headerBottomMargin, 60px);
  }
  .ec-layoutRole .ec-layoutRole__header .ec-headerRole {
    transition: 0.3s;
  }
  body.scrolled .ec-layoutRole .ec-layoutRole__header .ec-headerRole {
    height: 42px;
  }
}

/*
ヘッダー挙動 その２

デスクトップ時のみの挙動ですが、
トップページでは、トッププロモーションにヘッダーが被る形となり、ヘッダー背景は透過状態になります。
ただし、背景を透過する条件は、「ページがスクロールされていない」場合とします。
*/
@media only screen and (min-width: 1280px) {
  body#page_homepage .ec-headPromotionRole {
    margin-top: -70px;
  }
  body#page_homepage .ec-layoutRole .ec-layoutRole__header {
    margin-bottom: 0;
  }
  body#page_homepage:not(.scrolled) .ec-layoutRole__header .ec-headerRole {
    background-color: transparent;
  }
  body#page_homepage:not(.scrolled) .ec-layoutRole__header .ec-headerRole:hover {
    background-color: rgba(var(--clrHeaderBgRGB, 17, 18, 20), var(--clrHeaderBgAlfa, 0.5));
  }
}
/*
ヘッダー挙動 商品一覧ページ用
*/
@media only screen and (min-width: 1280px) {
  body#page_product_list .ec-layoutRole .ec-layoutRole__header {
    margin-bottom: 0;
  }
}
/*
メンテナンス表示（EC-CUBE4.1.2）
当テンプレートではヘッダーをsticky常駐にしているのでメンテナンス表示が被ってヘッダーを邪魔しないようページ左下に配置させています
*/
.ec-maintenanceAlert {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 998;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
  padding: 1.5em 2em;
  background: steelblue;
  color: white;
}
.ec-maintenanceAlert > div {
  display: flex;
  align-items: center;
}
.ec-maintenanceAlert .ec-maintenanceAlert__icon {
  display: block;
  margin-right: 0.5em;
  width: 20px;
  height: 20px;
}
@media only screen and (max-width: 767px) {
  .ec-maintenanceAlert {
    width: 100%;
  }
}

/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。
*/
@media only screen and (max-width: 767px) {
  .ec-layoutRole__footer {
    padding-top: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-layoutRole__footer {
    padding-top: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-layoutRole__footer {
    padding-top: 100px;
  }
}

.ec-footerRole {
  background-color: var(--clrFooterBg, #252525);
  color: var(--clrFooterText, #aaa);
}
@media only screen and (max-width: 767px) {
  .ec-footerRole {
    font-size: 0.9285rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-footerRole {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-footerRole {
    font-size: 1rem;
  }
}
/*
フッターナビ
*/
.ec-footerNavi {
  background-color: var(--clrFooterBg, #252525);
  color: var(--clrFooterText, #aaa);
  padding: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-footerNavi {
    padding: 1.75rem;
  }
}
.ec-footerNavi ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: center;
}
.ec-footerNavi ul li {
  display: block;
}
@media only screen and (min-width: 1280px) {
  .ec-footerNavi ul li {
    display: inline-block;
  }
}
.ec-footerNavi ul li:nth-child(n+2) {
  border-top: solid 1px var(--clrFooterBorder, #555);
}
@media only screen and (min-width: 1280px) {
  .ec-footerNavi ul li:nth-child(n+2) {
    border-top: none;
    border-left: solid 1px var(--clrFooterBorder, #555);
  }
}
.ec-footerNavi ul li a {
  display: block;
  color: inherit;
  padding: 0.75em;
}
@media only screen and (min-width: 1280px) {
  .ec-footerNavi ul li a {
    padding: 0 1em;
  }
}

/*
フッターガイド
*/
.ec-footerGuide {
  border-top: 1px solid var(--clrFooterBorder, #555);
  border-bottom: 1px solid var(--clrFooterBorder, #555);
}
.ec-footerGuide .ec-footerRow {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .ec-footerGuide .ec-footerRow {
    display: block;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-footerGuide .ec-footerRow {
    max-width: 1340px;
    margin: 0 auto;
  }
}
.ec-footerGuide .ec-footerRow .ec-footerColumn {
  border-top: 1px solid var(--clrFooterBorder, #555);
  display: flex;
  flex-flow: column;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .ec-footerGuide .ec-footerRow .ec-footerColumn {
    padding: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-footerGuide .ec-footerRow .ec-footerColumn {
    flex: 0 0 auto;
    width: 33.3%;
    padding: 3.906vw;
    border-top: none;
    border-left: 1px solid var(--clrFooterBorder, #555);
  }
}
@media only screen and (min-width: 1280px) {
  .ec-footerGuide .ec-footerRow .ec-footerColumn {
    flex: 0 0 auto;
    width: 33.3%;
    padding: 30px;
    border-top: none;
    border-left: 1px solid var(--clrFooterBorder, #555);
  }
}
.ec-footerGuide .ec-footerRow .ec-footerColumn:first-child {
  border: none;
}
.ec-footerGuide .ec-footerRow .ec-footerColumn__item:nth-child(n+2) {
  margin-top: 2rem;
}
.ec-footerGuide .ec-footerRow .ec-footerColumn__title {
  margin: 0;
  margin-bottom: 1.3em;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-size: 1.13rem;
  font-weight: normal;
  color: var(--clrFooterTitleText, #fff);
  text-align: center;
}
.ec-footerGuide .ec-footerRow .ec-footerColumn__txt p {
  font-size: 0.93em;
  line-height: 1.7;
  margin: 0;
}
.ec-footerGuide .ec-footerRow .ec-footerColumn__txt p:nth-child(n+2) {
  margin-top: 1em;
}

/*
支払い方法
*/
.ec-footerPayment ul {
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 0;
}
.ec-footerPayment ul li {
  display: inline-block;
  margin: 0 0.5rem;
}
.ec-footerPayment ul li img {
  width: auto;
  height: 2rem;
}

/*
フッター ソーシャルボタン
*/
.ec-footerSocial {
  margin-top: -1rem;
}
.ec-footerSocial .ec-footerSocial__itemList {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.ec-footerSocial .ec-footerSocial__itemList li {
  display: inline-block;
  margin: 5px;
  /* デスクトップ時はマウスオーバーで色が付くようにします */
}
.ec-footerSocial .ec-footerSocial__itemList li a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  background-color: transparent;
  color: inherit;
}
.ec-footerSocial .ec-footerSocial__itemList li a:hover {
  text-decoration: none;
  color: #fff;
}
.ec-footerSocial .ec-footerSocial__itemList li i {
  display: block;
}
@media only screen and (min-width: 1280px) {
  .ec-footerSocial .ec-footerSocial__itemList li a {
    color: var(--clrFooterText, #aaa);
    transition: 0.2s;
  }
  .ec-footerSocial .ec-footerSocial__itemList li.facebook a:hover {
    background-color: #3B5999;
  }
  .ec-footerSocial .ec-footerSocial__itemList li.twitter a:hover {
    background-color: #1B95E0;
  }
  .ec-footerSocial .ec-footerSocial__itemList li.instagram a:hover {
    background-color: #f00075;
  }
}

/*
フッターカレンダー

EC-CUBE4.1.1より追加された「カレンダーブロック」になりますが、当テンプレートではフッターカレンダーとして使用しています。
標準でふた月分のカレンダーが表示されますが、当テンプレートではひと月ごとスライド表示させるようにしています。
*/
.ec-footerCalendar {
  width: 100%;
  color: var(--clrFooterText, #aaa);
  position: relative;
}
@media only screen and (min-width: 1280px) {
  .ec-footerCalendar {
    width: 300px;
    margin: 0 auto;
  }
}
.ec-footerCalendar .ec-footerCalendar__slick {
  border-bottom: solid 1px var(--clrFooterBorder, #555);
  visibility: hidden;
  opacity: 0;
  transition: 1s;
}
.ec-footerCalendar .ec-footerCalendar__slick.slick-initialized.slick-initialized {
  visibility: visible;
  opacity: 1;
}
.ec-footerCalendar .slick-appendArrows {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ec-footerCalendar .slick-appendArrows .slick-arrow {
  font-size: 0.86rem;
}
.ec-footerCalendar .slick-appendArrows .slick-arrow:not(.slick-disabled) {
  cursor: pointer;
}
.ec-footerCalendar .slick-appendArrows .slick-arrow.slick-disabled {
  opacity: 0.25;
}
.ec-footerCalendar .ec-footerCalendar__monthTitle {
  text-align: center;
  font-size: 1rem;
  line-height: 1;
}
.ec-footerCalendar .ec-footerCalendar__footnote {
  font-size: 0.8rem;
  color: inherit;
  line-height: 1.2;
  margin-top: 1em;
}
.ec-footerCalendar .ec-footerCalendar__footnote .holiday {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: var(--clrCalendarHolidayBg, #999);
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.ec-calendar {
  border-top: solid 1px var(--clrFooterBorder, #555);
  padding: 0.5rem 0;
  margin-top: 0.5rem;
}
.ec-calendar table {
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  font-size: 0.8rem;
}
@media only screen and (min-width: 1280px) {
  .ec-calendar table {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}
.ec-calendar table th, .ec-calendar table td {
  padding: 1px 0;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
}
.ec-calendar table th span, .ec-calendar table td span {
  display: inline-block;
  width: 1.7em;
  line-height: 1.7;
  border: solid 1px transparent;
}
.ec-calendar .ec-calendar__holiday span {
  background-color: var(--clrCalendarHolidayBg, #999);
  color: var(--clrCalendarHolidayText, #000);
}
.ec-calendar .ec-calendar__today span {
  background-color: var(--clrCalendarTodayBg, #fff);
  color: var(--clrCalendarTodayText, #000);
}

.ec-footerTitle {
  padding: 1rem;
}
@media only screen and (min-width: 1280px) {
  .ec-footerTitle {
    padding: 1.75rem;
  }
}
.ec-footerTitle .ec-footerTitle__copyright {
  text-align: center;
}

/*
TOPヘッドプロモーション
*/
.ec-headPromotionRole .item {
  position: relative;
  width: 100%;
  background: #ccc;
}
@media only screen and (max-width: 767px) {
  .ec-headPromotionRole .item {
    height: 128vw;
  }
}
@media only screen and (min-width: 768px) {
  .ec-headPromotionRole .item {
    height: 38.0208333333vw;
  }
}
.ec-headPromotionRole .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ec-headPromotionRole .item a {
  display: block;
  width: 100%;
  height: 100%;
}
.ec-headPromotionRole .item .item-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  text-decoration: none;
  transition: 1s;
}
.ec-headPromotionRole .item .item-overlay .content {
  position: absolute;
}
@media only screen and (max-width: 767px) {
  .ec-headPromotionRole .item .item-overlay .content {
    left: 0;
    right: 0;
    bottom: calc(2rem + 11px);
    padding: 0 6.25vw;
  }
}
@media only screen and (min-width: 768px) {
  .ec-headPromotionRole .item .item-overlay .content {
    left: 6.6vw;
    right: 6.6vw;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
  }
}
.ec-headPromotionRole .item .item-catch {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-weight: normal;
  line-height: 1.2;
  /*
  display: inline-block;
  background-color: $clrBodyBg;
  color: $clrDefaultDark;
  padding: 0 .75em;
  */
  opacity: 0;
  clip-path: inset(0 0 100% 0);
  transition: clip-path 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0.3s;
}
@media only screen and (max-width: 767px) {
  .ec-headPromotionRole .item .item-catch {
    font-size: 1.2rem;
    margin-bottom: 0.75em;
  }
}
@media only screen and (min-width: 768px) {
  .ec-headPromotionRole .item .item-catch {
    font-size: 1.5vw;
    position: absolute;
    bottom: calc(100% + 1.5vw);
    left: 0;
  }
}
.ec-headPromotionRole .item .item-name {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-weight: 700;
  overflow-wrap: break-word;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  opacity: 0;
  clip-path: inset(0 100% 0 0);
  transition: clip-path 1.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 1s;
}
@media only screen and (max-width: 767px) {
  .ec-headPromotionRole .item .item-name {
    font-size: 8vw;
    line-height: 1.2;
  }
}
@media only screen and (min-width: 768px) {
  .ec-headPromotionRole .item .item-name {
    font-size: 4vw;
    line-height: 1.2;
  }
}
.ec-headPromotionRole .item .item-more {
  opacity: 0;
  transform: translateY(1em);
  transition: opacity 0.7s linear, transform 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 1.6s;
}
@media only screen and (max-width: 767px) {
  .ec-headPromotionRole .item .item-more {
    font-size: 0.73rem;
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 768px) {
  .ec-headPromotionRole .item .item-more {
    font-size: 0.86rem;
    position: absolute;
    top: calc(100% + 4vw);
    left: 0;
  }
}
.ec-headPromotionRole .item .item-more span {
  display: inline-flex;
  align-items: center;
  padding-bottom: 0.4em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  line-height: 1;
}
.ec-headPromotionRole .item .item-more span:after {
  font-family: jeiafont;
  content: "\e91d";
  margin-left: 0.5em;
  padding-top: 0.2em;
}
.ec-headPromotionRole .item.is-visible .item-catch {
  opacity: 1;
  clip-path: inset(0 0 0 0);
}
.ec-headPromotionRole .item.is-visible .item-name {
  opacity: 1;
  clip-path: inset(0 0 0 0);
}
.ec-headPromotionRole .item.is-visible .item-more {
  opacity: 1;
  transform: translateY(0);
}
.ec-headPromotionRole .item.is-visibleEnd .item-catch,
.ec-headPromotionRole .item.is-visibleEnd .item-name,
.ec-headPromotionRole .item.is-visibleEnd .item-more {
  transition-delay: 0;
  transition: 1s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  opacity: 0;
  transform: translate3d(0, 1em, 0);
}
.ec-headPromotionRole .slick-slider .slick-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
}
@media only screen and (min-width: 1280px) {
  .ec-headPromotionRole .slick-slider .slick-dots {
    padding: 40px;
  }
}

/*
商品詳細

商品詳細 スライド部に関する Project コンポーネントを定義します。
*/
@media only screen and (min-width: 1280px) {
  .ec-sliderItemRole {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-sliderItemRole .item_visual {
    flex: 1 0 auto;
    width: auto;
    margin: 0;
    margin-left: 10px;
    border: 1px solid var(--clrBorderColor, #ddd);
  }
}
.ec-sliderItemRole .item_visual__container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
}
.ec-sliderItemRole .item_visual__inner {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-start;
  transition: left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ec-sliderItemRole .item_visual .slide-item {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.ec-sliderItemRole .item_nav {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .ec-sliderItemRole .item_nav {
    height: calc((100vw - 1px * 4) / 5);
    margin-top: 1px;
    overflow: hidden;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-sliderItemRole .item_nav {
    height: calc((100vw - 1px * 6) / 7);
    margin-top: 1px;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-sliderItemRole .item_nav {
    flex: 0 0 auto;
    width: 15%;
    height: auto;
  }
}
.ec-sliderItemRole .item_nav__wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip-path: inset(0 0);
}
.ec-sliderItemRole .item_nav__inner {
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  transition: left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), top 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media only screen and (min-width: 1280px) {
  .ec-sliderItemRole .item_nav__inner {
    flex-direction: column;
  }
}
.ec-sliderItemRole .item_nav__prev, .ec-sliderItemRole .item_nav__next {
  position: absolute;
  top: 0;
  width: 20px;
  height: 100%;
  cursor: pointer;
}
@media only screen and (min-width: 1280px) {
  .ec-sliderItemRole .item_nav__prev, .ec-sliderItemRole .item_nav__next {
    width: 100%;
    height: 20px;
  }
}
.ec-sliderItemRole .item_nav__prev .arw, .ec-sliderItemRole .item_nav__next .arw {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 30px;
  margin-top: -15px;
  background-color: #000;
}
@media only screen and (min-width: 1280px) {
  .ec-sliderItemRole .item_nav__prev .arw, .ec-sliderItemRole .item_nav__next .arw {
    top: 0;
    left: 50%;
    width: 30px;
    height: 100%;
    margin-top: 0;
    margin-left: -15px;
  }
}
.ec-sliderItemRole .item_nav__prev .arw:before, .ec-sliderItemRole .item_nav__next .arw:before {
  position: absolute;
  top: 50%;
  width: 8px;
  height: 8px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  margin-top: -4px;
  content: "";
}
.ec-sliderItemRole .item_nav__prev {
  left: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-sliderItemRole .item_nav__prev {
    top: -7px;
  }
}
.ec-sliderItemRole .item_nav__prev .arw:before {
  left: 8px;
  transform: rotate(-45deg);
}
@media only screen and (min-width: 1280px) {
  .ec-sliderItemRole .item_nav__prev .arw:before {
    left: 11px;
    margin-top: -2px;
    transform: rotate(45deg);
  }
}
.ec-sliderItemRole .item_nav__next {
  right: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-sliderItemRole .item_nav__next {
    top: auto;
    bottom: -7px;
  }
}
.ec-sliderItemRole .item_nav__next .arw:before {
  right: 8px;
  transform: rotate(135deg);
}
@media only screen and (min-width: 1280px) {
  .ec-sliderItemRole .item_nav__next .arw:before {
    right: 11px;
    margin-top: -5px;
    transform: rotate(225deg);
  }
}
.ec-sliderItemRole .slideThumb {
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .ec-sliderItemRole .slideThumb {
    width: calc((100vw - 1px * 4) / 5);
    margin-right: 1px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-sliderItemRole .slideThumb {
    width: calc((100vw - 1px * 6) / 7);
    margin-right: 1px;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-sliderItemRole .slideThumb {
    width: 100%;
    margin-bottom: 10px;
  }
}
.ec-sliderItemRole .slideThumb:last-child {
  margin: 0;
}
.ec-sliderItemRole .slideThumb:focus {
  outline: none;
}
.ec-sliderItemRole .slideThumb img {
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s;
}
.ec-sliderItemRole .slideThumb:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  box-sizing: border-box;
  border: solid 3px #000;
  transition: 0.3s;
  content: "";
  visibility: hidden;
  opacity: 0;
}
.ec-sliderItemRole .slideThumb:hover:after, .ec-sliderItemRole .slideThumb.current:after {
  visibility: visible;
  opacity: 1;
}
.ec-sliderItemRole .slideThumb:hover img {
  filter: brightness(1.05);
}

/*
slick

トッププロモーションや商品ビジュアル画像、商品リストのスライド表示にはEC-CUBE4標準と同じく「slick」を使用しています。

slickを適用する要素は共通してclass「.ec-slickContainer」を設置してその要素に対して適用させるようにしています。

slick適用要素は、slickの準備ができるまではエリアを非表示としておき、準備が整ってからフェードイン表示としています。
slickの準備が完了するまでは画像が一瞬大きく表示されてしまう事がありますのでそれを見せないための処置です。

メモ：
slickドットとslickアロー（prev,next）と両方適用させると、アローボタンは上下中央よりずれてしまいます。
*/
.ec-slickContainer {
  visibility: hidden;
  opacity: 0;
  filter: blur(5px);
  transition: 1s;
}
.ec-slickContainer.slick-initialized.is-readiness {
  visibility: visible;
  opacity: 1;
  filter: blur(0);
}

/*
slick用パーツ

slick用パーツの共通スタイリングです。

* prev,nextボタン
* ドットボタン
* slickガター
* prev, nextボタン（slick領域外側配置）
* キャプション表示
*/
/*
prev,nextボタン

ボタンを画像に被せて左右に配置するるタイプのスタイリングです。
もしボタンをslick適用領域の外側に配置する場合は後述の「slickArrowOuter」を適用してください。
*/
.slick-slider {
  position: relative;
}
.slick-slider .slick-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  font-size: 3rem;
  color: #fff;
  cursor: pointer;
  opacity: 0.5;
  transition: 0.2s;
}
.slick-slider .slick-arrow.slick-prev {
  left: 0;
}
.slick-slider .slick-arrow.slick-next {
  right: 0;
}
.slick-slider .slick-arrow:hover {
  opacity: 1;
}
.slick-slider .slick-arrow i {
  display: block;
}

/*
ドットボタン
*/
.slick-slider .slick-dots {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  padding-top: 16.5px;
}
.slick-slider .slick-dots li {
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: var(--clrNoactive, #ddd);
  cursor: pointer;
}
.slick-slider .slick-dots li button {
  display: none;
}
.slick-slider .slick-dots li.slick-active {
  background-color: var(--clrActive, #000);
}
.slick-slider .slick-dots li + li {
  margin-left: 11px;
}

/*
slickガター

slick適用内のアイテム間にガター（間隔）を設けます。

slickを適用する要素（基本は.ec-ec-slickContainer）に併せてclass「slickGutter」を記述しておきます。

※なお、レスポンシブでデバイス事にガター幅を可変できるようにmixin化しています。

メモ：
普通に、.slick-slide要素にマージンを設定し、先祖要素である.slick-listにネガティブマージンを設定して調整します。
しかし、それだけではスライド時に左側にわずかに商品アイテムがはみ出した形で見えてしまいます。これを防ぐ為には、
slick適用の要素（基本は.ec-ec-slickContainer）に「overflow:hidden;」すれば解決しますが、
prev,nectボタンをslick領域の外側に配置したい場合があり（position:absolute;で外に出しています）、overflowだと
この外側に配置したpreve,nextボタンが隠されてしまい見えない事になります。
ですので、ネガティブマージンをかけた.slick-list要素に「clip-path」プロパティを使い表示領域をクリップすることで対応しています。

※IE11、Edgeはclip-path非対応なのでクリップパスは適用されず結果、スライド時に左側が少しはみ出して見えます（2019/09）
*/
.slickGutter .slick-list {
  margin-left: -10px;
  clip-path: inset(0 0 0 10px);
}
.slickGutter .slick-list .slick-slide {
  margin-left: 10px;
}
@media only screen and (min-width: 1280px) {
  .slickGutter .slick-list {
    margin-left: -20px;
    clip-path: inset(0 0 0 20px);
  }
  .slickGutter .slick-list .slick-slide {
    margin-left: 20px;
  }
}

/*
prev, nextボタン（slick領域外側配置）

slick適用領域の外側にprev,nextボタンを配置するタイプのスタイリングです。
スマホ・タブレット時はslick領域の上部の外、右側につめて配置させます。

slickを適用する要素（基本は.ec-slickContainer）に併せてclass「slickArrowOuter」を記述しておきます。
*/
.slickArrowOuter .slick-arrow {
  color: #666;
}
.slickArrowOuter .slick-arrow:hover {
  color: #333;
}
@media only screen and (max-width: 1279px) {
  .slickArrowOuter .slick-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    position: absolute;
    top: auto;
    bottom: 100%;
    transform: translate(0, 0);
    font-size: 24.5px;
  }
  .slickArrowOuter .slick-arrow.slick-prev {
    left: auto;
    right: 35px;
  }
  .slickArrowOuter .slick-arrow.slick-next {
    left: auto;
    right: 0;
  }
}
@media only screen and (min-width: 1280px) {
  .slickArrowOuter .slick-arrow {
    width: 60px;
  }
  .slickArrowOuter .slick-arrow.slick-prev {
    left: auto;
    right: 100%;
  }
  .slickArrowOuter .slick-arrow.slick-next {
    right: auto;
    left: 100%;
  }
}

/*
slick キャプション表示

slick適用の各アイテムの中に「.caption」要素（説明文）を記述しておくと画像に被さる形でテキストが表示されます。

商品詳細ページでは、slickを適用する画像ビジュアル部分が動的に出力されますのでキャプション要素を追加できませんが、
フリーエリア内にてキャプション要素を記述しておき、javascirptで各キャプションを各画像アイテム内に挿入させて実装しています。
*/
/*
トップページ ヘッドプロモーション

slickを使用してスライド表示します。

slickキャプション表示と、ドットボタンについては、上記のslick用パーツの定義を上書き調整してスタイリングしています。
↓
JEIA4002では「_12.1.slider_4002.scss」にてTOPプロモーションと商品詳細プロモーションをスタイリングしています。
*/
/*
特集商品

トップページで使用されている特集商品ブロックのスタイルです。
*/
.ec-featuredProductsRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 1680px;
}
@media only screen and (max-width: 767px) {
  .ec-featuredProductsRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-featuredProductsRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-featuredProductsRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-featuredProductsRole, .ec-layoutRole__mainBetweenColumn .ec-featuredProductsRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ec-featuredProductsRole {
    margin-top: 6.25vw;
    margin-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-featuredProductsRole {
    margin-top: 3.906vw;
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-featuredProductsRole {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-featuredProductsRole {
    padding-left: 60px;
    padding-right: 60px;
  }
}

/*
ボタン

トップページで使用されているボタンのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.3
*/
/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

Markup:
.ec-inlineBtn--top more

Styleguide 12.3.1
*/
.ec-inlineBtn--top {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
  color: white;
  background-color: black;
  border-color: black;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
}
.ec-inlineBtn--top:focus, .ec-inlineBtn--top.focus {
  color: white;
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-inlineBtn--top:hover {
  text-decoration: none;
  color: white;
  filter: brightness(1.1);
}
.ec-inlineBtn--top.disabled, .ec-inlineBtn--top[disabled], fieldset[disabled] .ec-inlineBtn--top {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
  cursor: not-allowed;
}
.ec-inlineBtn--top.disabled:hover, .ec-inlineBtn--top.disabled:focus, .ec-inlineBtn--top.disabled.focus, .ec-inlineBtn--top[disabled]:hover, .ec-inlineBtn--top[disabled]:focus, .ec-inlineBtn--top[disabled].focus, fieldset[disabled] .ec-inlineBtn--top:hover, fieldset[disabled] .ec-inlineBtn--top:focus, fieldset[disabled] .ec-inlineBtn--top.focus {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
}
.ec-inlineBtn--top .badge {
  color: black;
  background-color: white;
}

/*
ロングボタン（全幅）

ロングタイプのボタンです。

Markup:
.ec-blockBtn--top 商品一覧へ

Styleguide 2.1.2
*/
.ec-blockBtn--top {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
  color: white;
  background-color: black;
  border-color: black;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  display: block;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn--top:focus, .ec-blockBtn--top.focus {
  color: white;
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
.ec-blockBtn--top:hover {
  text-decoration: none;
  color: white;
  filter: brightness(1.1);
}
.ec-blockBtn--top.disabled, .ec-blockBtn--top[disabled], fieldset[disabled] .ec-blockBtn--top {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
  cursor: not-allowed;
}
.ec-blockBtn--top.disabled:hover, .ec-blockBtn--top.disabled:focus, .ec-blockBtn--top.disabled.focus, .ec-blockBtn--top[disabled]:hover, .ec-blockBtn--top[disabled]:focus, .ec-blockBtn--top[disabled].focus, fieldset[disabled] .ec-blockBtn--top:hover, fieldset[disabled] .ec-blockBtn--top:focus, fieldset[disabled] .ec-blockBtn--top.focus {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
}
.ec-blockBtn--top .badge {
  color: black;
  background-color: white;
}
@media only screen and (min-width: 1280px) {
  .ec-blockBtn--top {
    max-width: 260px;
  }
}

/*
見出し

トップページで使用されている見出しのスタイルです。
*/
/*
セクション見出し
*/
.ec-secHeading {
  margin-bottom: 1.5rem;
  color: var(--clrDefaultDark, #000);
  text-align: center;
  overflow: hidden;
}
@media only screen and (min-width: 1280px) {
  .ec-secHeading {
    margin-bottom: 60px;
  }
}
.ec-secHeading .ec-secHeading__maintitle {
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: 1.5rem;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-weight: 700;
  letter-spacing: 0;
}
@media only screen and (min-width: 1280px) {
  .ec-secHeading .ec-secHeading__maintitle {
    font-size: 2.5rem;
  }
}
.ec-secHeading .ec-secHeading__maintitle:before, .ec-secHeading .ec-secHeading__maintitle:after {
  position: absolute;
  top: 50%;
  display: block;
  width: 100vw;
  max-width: 110px;
  height: 1px;
  background-color: var(--clrBorderColor, #ddd);
  content: "";
}
.ec-secHeading .ec-secHeading__maintitle:before {
  right: calc(100% + 0.75em);
}
.ec-secHeading .ec-secHeading__maintitle:after {
  left: calc(100% + 0.75em);
}
.ec-secHeading .ec-secHeading__subtitle {
  margin: 0.5em 0 0 0;
  text-align: center;
  font-size: 0.93rem;
  font-family: "Noto Serif JP", serif;
}
@media only screen and (min-width: 1280px) {
  .ec-secHeading .ec-secHeading__subtitle {
    font-size: 1rem;
    margin-top: 0.75em;
  }
}

/*
トピック
*/
.ec-topicRole {
  background: var(--clrBodyToneBg, #f8f8f8);
}
@media only screen and (max-width: 767px) {
  .ec-topicRole {
    margin-top: 6.25vw;
    margin-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-topicRole {
    margin-top: 3.906vw;
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-topicRole {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}
.ec-topicRole .ec-topicRole__inner {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 1680px;
}
@media only screen and (max-width: 767px) {
  .ec-topicRole .ec-topicRole__inner {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-topicRole .ec-topicRole__inner {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-topicRole .ec-topicRole__inner {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-topicRole .ec-topicRole__inner, .ec-layoutRole__mainBetweenColumn .ec-topicRole .ec-topicRole__inner {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ec-topicRole .ec-topicRole__inner {
    padding-top: 6.25vw;
    padding-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-topicRole .ec-topicRole__inner {
    padding-top: 3.906vw;
    padding-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-topicRole .ec-topicRole__inner {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .ec-topicRole .ec-topicRole__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -20px;
  }
  .ec-topicRole .ec-topicRole__list > * {
    flex: 0 0 auto;
    width: calc((100% - 0px ) / 1);
    margin-left: 0px;
    margin-top: 20px;
  }
  .ec-topicRole .ec-topicRole__list > *:nth-child(1n+1) {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-topicRole .ec-topicRole__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: 0px;
  }
  .ec-topicRole .ec-topicRole__list > * {
    flex: 0 0 auto;
    width: calc((100% - 40px ) / 3);
    margin-left: 20px;
    margin-top: 0px;
  }
  .ec-topicRole .ec-topicRole__list > *:nth-child(3n+1) {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-topicRole .ec-topicRole__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: 0px;
  }
  .ec-topicRole .ec-topicRole__list > * {
    flex: 0 0 auto;
    width: calc((100% - 60px ) / 3);
    margin-left: 30px;
    margin-top: 0px;
  }
  .ec-topicRole .ec-topicRole__list > *:nth-child(3n+1) {
    margin-left: 0;
  }
}
.ec-topicRole .ec-topicRole__listItem a {
  display: block;
  overflow: hidden;
  background-color: #000;
}
.ec-topicRole .ec-topicRole__listItem a img {
  display: block;
  transition: transform 0.3s ease-out, opacity 0.3s linear;
}
.ec-topicRole .ec-topicRole__listItem a:hover img {
  transform: scale(1.1);
  opacity: 0.5;
}
.ec-topicRole .ec-topicRole__listItemTitle {
  margin: 0.5em 0 0;
  font-family: "Noto Serif JP", serif;
  font-size: 0.93rem;
}
@media only screen and (min-width: 1280px) {
  .ec-topicRole .ec-topicRole__listItemTitle {
    margin-top: 1em;
    font-size: 1rem;
  }
}

/*
新着商品

トップページで使用されている新着商品ブロックのスタイルです。
*/
.ec-newItemRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 1680px;
}
@media only screen and (max-width: 767px) {
  .ec-newItemRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-newItemRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-newItemRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-newItemRole, .ec-layoutRole__mainBetweenColumn .ec-newItemRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ec-newItemRole {
    margin-top: 6.25vw;
    margin-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-newItemRole {
    margin-top: 3.906vw;
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-newItemRole {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-newItemRole {
    padding-left: 60px;
    padding-right: 60px;
  }
}

/*
バナー

トップページで使用されているバナーブロックのスタイルです。
*/
.ec-bannersRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 1680px;
}
@media only screen and (max-width: 767px) {
  .ec-bannersRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-bannersRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-bannersRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-bannersRole, .ec-layoutRole__mainBetweenColumn .ec-bannersRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ec-bannersRole {
    margin-top: 6.25vw;
    margin-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-bannersRole {
    margin-top: 3.906vw;
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-bannersRole {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .ec-bannersRole .ec-bannersRole__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -10px;
  }
  .ec-bannersRole .ec-bannersRole__list > * {
    flex: 0 0 auto;
    width: calc((100% - 0px ) / 1);
    margin-left: 0px;
    margin-top: 10px;
  }
  .ec-bannersRole .ec-bannersRole__list > *:nth-child(1n+1) {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-bannersRole .ec-bannersRole__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -10px;
  }
  .ec-bannersRole .ec-bannersRole__list > * {
    flex: 0 0 auto;
    width: calc((100% - 10px ) / 2);
    margin-left: 10px;
    margin-top: 10px;
  }
  .ec-bannersRole .ec-bannersRole__list > *:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-bannersRole .ec-bannersRole__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -30px;
  }
  .ec-bannersRole .ec-bannersRole__list > * {
    flex: 0 0 auto;
    width: calc((100% - 30px ) / 2);
    margin-left: 30px;
    margin-top: 30px;
  }
  .ec-bannersRole .ec-bannersRole__list > *:nth-child(2n+1) {
    margin-left: 0;
  }
}
.ec-bannersRole .ec-bannersRole__item {
  position: relative;
}
.ec-bannersRole .ec-bannersRole__item a {
  display: block;
}
@media only screen and (min-width: 1280px) {
  .ec-bannersRole .ec-bannersRole__item a:hover .ec-bannersRole__image img {
    transform: scale(1.1);
  }
  .ec-bannersRole .ec-bannersRole__item a:hover .ec-bannersRole__content {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .ec-bannersRole .ec-bannersRole__item a:hover .morebtn:after {
    transform: translate3d(0.25em, 0, 0);
  }
}
.ec-bannersRole .ec-bannersRole__image {
  overflow: hidden;
}
.ec-bannersRole .ec-bannersRole__image img {
  transition: 1s;
}
.ec-bannersRole .ec-bannersRole__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5%;
  pointer-events: none;
  transition: 1s;
}
.ec-bannersRole .ec-bannersRole__content .title {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
}
@media only screen and (min-width: 1280px) {
  .ec-bannersRole .ec-bannersRole__content .title {
    font-size: 1.75rem;
  }
}
.ec-bannersRole .ec-bannersRole__content .text {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-size: 0.86rem;
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
  margin: 0.75em 0;
}
@media only screen and (min-width: 1280px) {
  .ec-bannersRole .ec-bannersRole__content .text {
    font-size: 1rem;
  }
}
.ec-bannersRole .ec-bannersRole__content .morebtn {
  display: inline-block;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-size: 0.86rem;
  font-weight: 500;
  line-height: 1;
  padding: 1em 2em;
  padding-right: 3em;
  margin-top: 1rem;
  background-color: #fff;
  color: #333;
  transition: 0.3s;
  position: relative;
}
.ec-bannersRole .ec-bannersRole__content .morebtn:after {
  font-family: jeiafont;
  content: "\e902";
  font-size: 1.2em;
  line-height: 1;
  position: absolute;
  top: calc(50% - 0.4em);
  right: 0.5em;
  transform: translate3d(0, 0, 0);
  transition: 0.3s;
}
@media only screen and (min-width: 1280px) {
  .ec-bannersRole .ec-bannersRole__content .morebtn {
    font-size: 0.93rem;
  }
}

/*
トップカテゴリ

トップページで使用されているトップカテゴリのスタイルです。
セクション要素の高さ、ギャップ余白はCSSカスタムプロパティ化してありますのでCSS管理から（custome.css）調整が可能です。
*/
@media only screen and (max-width: 767px) {
  :root {
    --topCategoryGap: 0vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  :root {
    --topCategoryGap: 0vw;
  }
}
.ec-topCategoryRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 100%;
  padding: 0 !important;
}
@media only screen and (max-width: 767px) {
  .ec-topCategoryRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-topCategoryRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-topCategoryRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-topCategoryRole, .ec-layoutRole__mainBetweenColumn .ec-topCategoryRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
.ec-topCategoryRole .ec-topCategoryRole__list {
  padding: var(--topCategoryGap, 1vw);
  overflow: hidden;
}
.ec-topCategoryRole .ec-topCategoryRole__list:after {
  content: "";
  display: table;
  clear: both;
}
.ec-topCategoryRole .ec-topCategoryRole__item {
  position: relative;
  width: calc((99.99% - var(--topCategoryGap, 1vw)) * 0.5);
  float: left;
}
.ec-topCategoryRole .ec-topCategoryRole__item:nth-child(1) {
  margin-right: var(--topCategoryGap, 1vw);
}
.ec-topCategoryRole .ec-topCategoryRole__item:nth-child(1) img {
  object-fit: cover;
  height: var(--topCategoryHeight, 42vw);
}
.ec-topCategoryRole .ec-topCategoryRole__item:nth-child(2) img, .ec-topCategoryRole .ec-topCategoryRole__item:nth-child(3) img {
  object-fit: cover;
  height: calc(( var(--topCategoryHeight, 42vw) - var(--topCategoryGap, 1vw) ) * 0.5);
}
.ec-topCategoryRole .ec-topCategoryRole__item:nth-child(3) {
  margin-top: var(--topCategoryGap, 1vw);
}
@media only screen and (max-width: 767px) {
  .ec-topCategoryRole .ec-topCategoryRole__item:nth-child(1), .ec-topCategoryRole .ec-topCategoryRole__item:nth-child(2), .ec-topCategoryRole .ec-topCategoryRole__item:nth-child(3) {
    width: 100%;
    margin-top: var(--topCategoryGap, 1vw);
  }
  .ec-topCategoryRole .ec-topCategoryRole__item:nth-child(1) img, .ec-topCategoryRole .ec-topCategoryRole__item:nth-child(2) img, .ec-topCategoryRole .ec-topCategoryRole__item:nth-child(3) img {
    height: var(--topCategoryHeight, 42vw);
  }
  .ec-topCategoryRole .ec-topCategoryRole__item:nth-child(1) {
    margin-top: 0;
  }
}
.ec-topCategoryRole .ec-topCategoryRole__item a {
  display: block;
}
.ec-topCategoryRole .ec-topCategoryRole__item a:hover .ec-topCategoryRole__content {
  background-color: rgba(0, 0, 0, 0.5);
}
.ec-topCategoryRole .ec-topCategoryRole__item a:hover .ec-topCategoryRole__content .title {
  transform: translate3d(0, -0.5em, 0);
}
.ec-topCategoryRole .ec-topCategoryRole__image {
  display: block;
  overflow: hidden;
  /* HTML構造によってはIE11でちらつく事があるので保険 */
}
.ec-topCategoryRole .ec-topCategoryRole__image * {
  overflow: hidden;
}
.ec-topCategoryRole .ec-topCategoryRole__image img {
  transition: 0.6s;
}
.ec-topCategoryRole .ec-topCategoryRole__image:hover img {
  transform: scale(1.1);
  filter: brightness(1.05);
}
.ec-topCategoryRole .ec-topCategoryRole__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 2.5vw;
  background-color: transparent;
  transition: 1s;
}
.ec-topCategoryRole .ec-topCategoryRole__content .title {
  margin: 0;
  color: #fff;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-size: 3vw;
  font-weight: 700;
  line-height: 1.2;
  transition: 1s;
}
@media only screen and (max-width: 767px) {
  .ec-topCategoryRole .ec-topCategoryRole__content .title {
    font-size: 7vw;
  }
}

/*
新着情報

トップページで使用されている新着情報のスタイルです。
*/
.ec-newsRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 1680px;
}
@media only screen and (max-width: 767px) {
  .ec-newsRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-newsRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-newsRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-newsRole, .ec-layoutRole__mainBetweenColumn .ec-newsRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ec-newsRole {
    margin-top: 6.25vw;
    margin-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-newsRole {
    margin-top: 3.906vw;
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-newsRole {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}
.ec-newsRole .ec-newsRole__news {
  max-width: 1280px;
  margin: 0 auto;
  border: 6px solid var(--clrBodyToneBg, #f8f8f8);
  padding: 1rem;
}
@media only screen and (min-width: 1280px) {
  .ec-newsRole .ec-newsRole__news {
    padding: 40px;
  }
}
.ec-newsRole .ec-newsRole__newsItem:nth-child(n+2) {
  border-top: 1px solid var(--clrBorderColor, #ddd);
  padding-top: 1rem;
  margin-top: 1rem;
}
@media only screen and (min-width: 1280px) {
  .ec-newsRole .ec-newsRole__newsItem:nth-child(n+2) {
    margin-top: 2rem;
  }
}
.ec-newsRole .ec-newsRole__newsHeading {
  font-family: "Noto Serif JP", serif;
  font-size: 1.13rem;
  display: flex;
  flex-flow: row wrap;
}
@media only screen and (min-width: 1280px) {
  .ec-newsRole .ec-newsRole__newsHeading {
    font-size: 1.4rem;
  }
}
.ec-newsRole .ec-newsRole__newsDate {
  flex: 0 0 6em;
}
@media only screen and (max-width: 767px) {
  .ec-newsRole .ec-newsRole__newsDate {
    flex-basis: 100%;
    font-size: 1rem;
  }
}
@media only screen and (max-width: 767px) {
  .ec-newsRole .ec-newsRole__newsTitle {
    margin: 0.5em 0;
  }
}
.ec-newsRole .ec-newsRole__newsDescription {
  margin: 1em 0 0;
  font-size: 0.93rem;
  line-height: 1.8;
}
.ec-newsRole .ec-newsRole__newsMore {
  margin: 0.25em 0 0 0;
  font-size: 0.93rem;
  font-weight: bold;
}
.ec-newsRole .ec-newsRole__newsMore a {
  color: inherit;
}
.ec-newsRole .ec-newsRole__newsMore a:active, .ec-newsRole .ec-newsRole__newsMore a:hover {
  outline: 0;
  text-decoration: none;
}
.ec-newsRole .ec-newsRole__newsMore a .ec-newsRole__newsMoreClose {
  display: none;
}
.ec-newsRole .ec-newsRole__newsMoreIcon {
  position: relative;
  display: inline-block;
  width: 0.85em;
  height: 0.85em;
  margin: 0 0.5em 0 0;
  vertical-align: -0.05em;
}
.ec-newsRole .ec-newsRole__newsMoreIcon:before, .ec-newsRole .ec-newsRole__newsMoreIcon:after {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: #333;
  content: "";
}
.ec-newsRole .ec-newsRole__newsMoreIcon:before {
  transform: rotate(-90deg);
  transition: transform 0.2s ease-out;
}
.ec-newsRole .ec-newsRole__newsMore a.is_active .ec-newsRole__newsMoreIcon:before {
  transform: rotate(0deg);
}
.ec-newsRole .ec-newsRole__newsMore a.is_active .ec-newsRole__newsMoreText {
  display: none;
}
.ec-newsRole .ec-newsRole__newsMore a.is_active .ec-newsRole__newsMoreClose {
  display: inline;
}
.ec-newsRole .ec-newsRole__link a {
  display: inline-block;
  font-size: 0.83rem;
  background-color: #000;
  color: #fff;
  line-height: 1.2;
  padding: 0.5em 1em;
  border-radius: 4px;
  margin-top: 1em;
}

/*
カレンダー

※EC-CUBE4.1.1より追加された「カレンダーブロック」になりますが、当テンプレートではフッターカレンダーとして使用しています。

フッターカレンダーのCSSスタイリングは「_11.3.footer.scss」で行っています。
*/
/*
商品一覧ヘッダーナビ

商品一覧ページのカテゴリトピックパスや、検索結果、また並び替えフォームをまとめたコンポーネントになります。
*/
.ec-searchnavRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 100%;
  background-color: var(--clrBodyToneBg, #f8f8f8);
}
@media only screen and (max-width: 767px) {
  .ec-searchnavRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-searchnavRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-searchnavRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-searchnavRole, .ec-layoutRole__mainBetweenColumn .ec-searchnavRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ec-searchnavRole {
    padding: 1rem 6.25vw;
    margin-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-searchnavRole {
    padding: 1rem 3.906vw;
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-searchnavRole {
    background-color: transparent;
    padding: 0 40px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
.ec-searchnavRole .ec-searchnavRole__topicpath {
  margin-bottom: 0.5rem;
}
.ec-searchnavRole .ec-searchnavRole__infos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 0;
  padding: 0;
  line-height: 1;
}
@media only screen and (min-width: 1280px) {
  .ec-searchnavRole .ec-searchnavRole__infos {
    flex-direction: column;
  }
}
.ec-searchnavRole .ec-searchnavRole__counter {
  width: auto;
  font-size: 0.8rem;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-searchnavRole .ec-searchnavRole__counter {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-searchnavRole .ec-searchnavRole__counter {
    justify-content: center;
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: 1.125rem;
  }
}
.ec-searchnavRole .ec-searchnavRole__counter span {
  font-size: 160%;
}
@media only screen and (min-width: 1280px) {
  .ec-searchnavRole .ec-searchnavRole__counter span {
    font-size: 155%;
  }
}
.ec-searchnavRole .ec-searchnavRole__actions {
  text-align: right;
}
@media only screen and (min-width: 1280px) {
  .ec-searchnavRole .ec-searchnavRole__actions {
    text-align: center;
  }
}
.ec-searchnavRole .ec-searchnavRole__actions .ec-select, .ec-searchnavRole .ec-searchnavRole__actions .ec-birth {
  margin-bottom: 0;
  white-space: nowrap;
}
.ec-searchnavRole .ec-searchnavRole__actions .ec-select-label {
  display: none;
}
@media only screen and (min-width: 1280px) {
  .ec-searchnavRole .ec-searchnavRole__actions .ec-select-label {
    display: inline-block;
    margin: 0 0 0 1em;
    font-weight: normal;
  }
  .ec-searchnavRole .ec-searchnavRole__actions .ec-select-label:first-child {
    margin: 0;
  }
}
.ec-searchnavRole .ec-searchnavRole__actions .ec-select select, .ec-searchnavRole .ec-searchnavRole__actions .ec-birth select {
  background-color: #fff;
}

/*
トピックパス本体
*/
.ec-topicpath {
  line-height: 1;
}
.ec-topicpath ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.ec-topicpath .ec-topicpath__item {
  white-space: nowrap;
  padding: 4px 0;
  /* 連結の「>」 */
  /* タイトルラベル */
  /* 検索キーワード */
  /* 最後のli要素を太字 */
}
.ec-topicpath .ec-topicpath__item a {
  color: inherit;
  padding-top: 2px;
  padding-bottom: 2px;
  position: relative;
  text-decoration: none;
}
.ec-topicpath .ec-topicpath__item a:hover {
  text-decoration: none !important;
}
.ec-topicpath .ec-topicpath__item a:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left bottom;
  transform: scaleX(0);
  transition: transform 0.3s;
}
.ec-topicpath .ec-topicpath__item a:hover:after {
  transform: scaleX(1);
}
.ec-topicpath .ec-topicpath__item:before {
  font-family: "jeiafont";
  content: "\e902";
  font-size: 0.85em;
  margin-left: 4px;
}
.ec-topicpath .ec-topicpath__item.titleLabel {
  font-family: "Oswald", "Noto Sans JP", sans-serif;
}
.ec-topicpath .ec-topicpath__item.titleLabel:before {
  display: none;
}
.ec-topicpath .ec-topicpath__item.is-search span {
  margin-left: 0.2em;
}
.ec-topicpath .ec-topicpath__item:last-child a,
.ec-topicpath .ec-topicpath__item:last-child span {
  font-weight: 500;
  pointer-events: none;
}

/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。
*/
.ec-shelfRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .ec-shelfRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-shelfRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-shelfRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-shelfRole, .ec-layoutRole__mainBetweenColumn .ec-shelfRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

/*
商品一覧グリッド

陳列レイアウト用のmixinを使用します。（_projects.scss）
@mixin shelfLayout($amount: 5, $gutter_w: 20px, $gutter_h: 20px, $item: li) {}
* $amount: 横に配置する数
* $gutter_W: アイテム間の左右余白
* $gutter_h: アイテム間の上下余白
* $item: 子要素のタグ名称・class名称（デフォルトはli要素）
*/
.ec-shelfGrid {
  visibility: hidden;
  opacity: 0;
}
.ec-shelfGrid.ready {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 767px) {
  .ec-shelfGrid {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -20px;
  }
  .ec-shelfGrid > * {
    flex: 0 0 auto;
    width: calc((100% - 15px ) / 2);
    margin-left: 15px;
    margin-top: 20px;
  }
  .ec-shelfGrid > *:nth-child(2n+1) {
    margin-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .ec-shelfGrid {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -30px;
  }
  .ec-shelfGrid > * {
    flex: 0 0 auto;
    width: calc((100% - 30px ) / 3);
    margin-left: 15px;
    margin-top: 30px;
  }
  .ec-shelfGrid > *:nth-child(3n+1) {
    margin-left: 0;
  }
}
@media (min-width: 980px) {
  .ec-shelfGrid.col_max3 {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -40px;
  }
  .ec-shelfGrid.col_max3 > * {
    flex: 0 0 auto;
    width: calc((100% - 60px ) / 3);
    margin-left: 30px;
    margin-top: 40px;
  }
  .ec-shelfGrid.col_max3 > *:nth-child(3n+1) {
    margin-left: 0;
  }
}
@media (min-width: 980px) {
  .ec-shelfGrid.col_max4 {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -40px;
  }
  .ec-shelfGrid.col_max4 > * {
    flex: 0 0 auto;
    width: calc((100% - 60px ) / 4);
    margin-left: 20px;
    margin-top: 40px;
  }
  .ec-shelfGrid.col_max4 > *:nth-child(4n+1) {
    margin-left: 0;
  }
}
@media (min-width: 980px) and (max-width: 1279px) {
  .ec-shelfGrid.col_max5 {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -40px;
  }
  .ec-shelfGrid.col_max5 > * {
    flex: 0 0 auto;
    width: calc((100% - 60px ) / 4);
    margin-left: 20px;
    margin-top: 40px;
  }
  .ec-shelfGrid.col_max5 > *:nth-child(4n+1) {
    margin-left: 0;
  }
}
@media (min-width: 1280px) {
  .ec-shelfGrid.col_max5 {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -40px;
  }
  .ec-shelfGrid.col_max5 > * {
    flex: 0 0 auto;
    width: calc((100% - 80px ) / 5);
    margin-left: 20px;
    margin-top: 40px;
  }
  .ec-shelfGrid.col_max5 > *:nth-child(5n+1) {
    margin-left: 0;
  }
}
@media (min-width: 980px) and (max-width: 1279px) {
  .ec-shelfGrid.col_max6 {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -40px;
  }
  .ec-shelfGrid.col_max6 > * {
    flex: 0 0 auto;
    width: calc((100% - 60px ) / 4);
    margin-left: 20px;
    margin-top: 40px;
  }
  .ec-shelfGrid.col_max6 > *:nth-child(4n+1) {
    margin-left: 0;
  }
}
@media (min-width: 1280px) and (max-width: 1679px) {
  .ec-shelfGrid.col_max6 {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -40px;
  }
  .ec-shelfGrid.col_max6 > * {
    flex: 0 0 auto;
    width: calc((100% - 80px ) / 5);
    margin-left: 20px;
    margin-top: 40px;
  }
  .ec-shelfGrid.col_max6 > *:nth-child(5n+1) {
    margin-left: 0;
  }
}
@media (min-width: 1680px) {
  .ec-shelfGrid.col_max6 {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin-top: -40px;
  }
  .ec-shelfGrid.col_max6 > * {
    flex: 0 0 auto;
    width: calc((100% - 100px ) / 6);
    margin-left: 20px;
    margin-top: 40px;
  }
  .ec-shelfGrid.col_max6 > *:nth-child(6n+1) {
    margin-left: 0;
  }
}

/*
商品アイテム

商品一覧だけでなく特集商品など、一覧表示される商品アイテムの共通スタイリングになります。
*/
.ec-productItemRole {
  height: 100%;
  display: flex;
  flex-flow: column;
  /* 商品画像ブロック */
  /* 品切れ表示（商品画像ブロック上に被せる） */
  /* 商品情報枠ブロック */
  /* 底配置ブロック */
  /* 商品名称 */
  /* 商品コード */
  /* 商品属性タグ（商品画像の上に被せる JEIA4004） */
  /* 商品説明（一覧用） */
  /* 通常価格 */
  /* 商品価格 */
}
@media only screen and (min-width: 1280px) {
  .ec-productItemRole {
    position: relative;
  }
}
.ec-productItemRole .ec-productItemRole__image {
  position: relative;
  min-height: 0%;
}
.ec-productItemRole .ec-productItemRole__image a {
  display: block;
  display: block;
  overflow: hidden;
  /* HTML構造によってはIE11でちらつく事があるので保険 */
}
.ec-productItemRole .ec-productItemRole__image a * {
  overflow: hidden;
}
.ec-productItemRole .ec-productItemRole__image a img {
  transition: 0.6s;
}
.ec-productItemRole .ec-productItemRole__image a:hover img {
  transform: scale(1.1);
  filter: brightness(1.05);
}
.ec-productItemRole .ec-productItemRole__image a img {
  width: 100%;
  height: auto;
}
.ec-productItemRole .ec-productItemRole__stockout {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-family: "Oswald", "Noto Sans JP", sans-serif;
  font-size: 1.2rem;
  padding: 0.8em;
  background-color: rgba(189, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .ec-productItemRole .ec-productItemRole__stockout {
    font-size: 1rem;
    padding: 0.5em;
  }
}
.ec-productItemRole .ec-productItemRole__content {
  text-align: var(--productItemContentAlign, center);
  /* 一番最後にある商品情報のmargin-bottomを0にします */
}
.ec-productItemRole .ec-productItemRole__content > *:last-child {
  margin-bottom: 0;
}
.ec-productItemRole .ec-productItemRole__bottom {
  margin: auto 0 0;
}
.ec-productItemRole .ec-productItemRole__title {
  font-family: "Noto Serif JP", serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 0.85em 0 0.3em;
}
@media only screen and (min-width: 1280px) {
  .ec-productItemRole .ec-productItemRole__title {
    font-size: 1.25rem;
    margin: 1.25em 0 0.5em;
  }
}
.ec-productItemRole .ec-productItemRole__title span {
  display: inline-block;
  text-align: left;
}
.ec-productItemRole .ec-productItemRole__code {
  font-size: 0.8rem;
  margin: 1em 0;
}
.ec-productItemRole .ec-productItemRole__tags {
  margin: 0 0 10px;
  padding: 0;
  margin-left: -3px;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}
.ec-productItemRole .ec-productItemRole__tags .tag {
  border: solid 1px var(--clrBorderColor, #ddd);
  margin: 0;
  margin-left: 3px;
  margin-bottom: 3px;
  border-radius: 3px;
  font-size: 0.8rem;
  padding: 0.4em 0.75em;
  line-height: 1;
  white-space: nowrap;
}
@media only screen and (min-width: 1280px) {
  .ec-productItemRole .ec-productItemRole__tags {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
  }
  .ec-productItemRole .ec-productItemRole__tags .tag {
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 2px;
  }
}
.ec-productItemRole .ec-productItemRole__description, .ec-recommendProductRole .ec-productItemRole .ec-productItemRole__recommendComment {
  font-size: 0.933rem;
  line-height: 1.5;
  margin: 1em 0;
  text-align: justify;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .ec-productItemRole .ec-productItemRole__description, .ec-recommendProductRole .ec-productItemRole .ec-productItemRole__recommendComment {
    font-size: 0.833rem;
    line-height: 1.35;
  }
}
.ec-productItemRole .ec-productItemRole__priceRegular {
  margin: 0.5em 0;
  text-align: var(--productItemPriceAlign, center);
}
.ec-productItemRole .ec-productItemRole__priceRegular .ec-price__label {
  font-size: 0.8rem;
}
.ec-productItemRole .ec-productItemRole__priceRegular .ec-price__price {
  font-size: 0.9rem;
}
.ec-productItemRole .ec-productItemRole__priceRegular .ec-price__tax {
  font-size: 0.7rem;
}
.ec-productItemRole .ec-productItemRole__price {
  line-height: 1.2;
  margin: 0.75em 0;
  text-align: var(--productItemPriceAlign, center);
}
.ec-productItemRole .ec-productItemRole__price .ec-price__label {
  font-size: 0.8rem;
}
.ec-productItemRole .ec-productItemRole__price .ec-price__price {
  font-size: 1.13rem;
}
.ec-productItemRole .ec-productItemRole__price .ec-price__tax {
  font-size: 0.7rem;
}

/*
商品アイテム内 カートインアクション

当テンプレートではコメントアウトにて除去している部分ですが、最低限のスタイリングをしております。
カートインアクション機能が必要な場合はlist.twigのカートイン部分とモーダル表示部分のコメントを解除しCSSスタイリングを調整してください。
*/
.ec-productItemRole {
  /* タイトルラベル */
  /* 数量エリア */
  /* カートに入れるボタン */
}
.ec-productItemRole .ec-productItemRole__actions select {
  max-width: 100%;
}
.ec-productItemRole .ec-productItemRole__label {
  margin: 0;
  margin-top: 1em;
}
.ec-productItemRole .quantityLabel {
  display: inline-block;
  vertical-align: baseline;
  margin-top: 0;
  margin-right: 0.5em;
}
.ec-productItemRole .ec-quantityRole {
  margin-top: 1em;
  display: inline-flex;
  vertical-align: baseline;
}
@media only screen and (max-width: 767px) {
  .ec-productItemRole .ec-quantityRole .ec-quantityRole__btn {
    width: 2.65rem;
    height: 2.65rem;
    line-height: 2.65rem;
  }
  .ec-productItemRole .ec-quantityRole .ec-numberInput {
    height: 2.65rem;
  }
  .ec-productItemRole .ec-quantityRole .ec-numberInput input {
    height: 2.65rem;
    width: 2.65rem;
  }
}
.ec-productItemRole .ec-productItemRole__btn {
  margin-top: 1em;
}
.ec-productItemRole .ec-productItemRole__btn .ec-blockBtn--cartin {
  height: 3rem;
  line-height: 3rem;
}

/*
ページャ

商品一覧やマイページコンテンツで使われるページ送りコンポーネントを定義します。
*/
.ec-pagerRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-pagerRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-pagerRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-pagerRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-pagerRole, .ec-layoutRole__mainBetweenColumn .ec-pagerRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

/*
カート追加モーダル

カート追加モーダルに関する Project コンポーネントを定義します。
*/
.ec-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
}
.ec-modal.small {
  width: 30%;
}
.ec-modal.full {
  width: 100%;
  height: 100%;
}
.ec-modal .ec-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}
.ec-modal .ec-modal-wrap {
  position: relative;
  border-radius: 2px;
  border: 1px solid #333;
  background-color: #fff;
  color: #000;
  width: 90%;
  margin: 20px;
  padding: 40px 5px;
}
@media only screen and (min-width: 1280px) {
  .ec-modal .ec-modal-wrap {
    padding: 40px 10px;
    width: 50%;
    margin: 20px auto;
  }
}
.ec-modal .ec-modal-close {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 20px;
  height: 30px;
  width: 20px;
}
.ec-modal .ec-modal-close:hover {
  color: #4b5361;
}
.ec-modal .ec-modal-box {
  text-align: center;
}
.ec-modal .ec-role {
  margin-top: 20px;
}

/*
カテゴリヘッド

商品一覧ページの「カテゴリヘッドコンテンツ」のスタイルになります。
*/
.ec-categoryHeadRole {
  position: relative;
}
.ec-categoryHeadRole .ec-categoryHeadRole__image {
  display: none;
  /*
  visibility: hidden;
  opacity: 0;
  transition: 1s;

  // 表示開始
  &.is-active {
    visibility: visible;
    opacity: 1;
  }
  */
}
.ec-categoryHeadRole .ec-categoryHeadRole__content {
  /*
  // 初期は非表示
  visibility: hidden;
  opacity: 0;

  // 表示開始
  &.is-active {
    visibility: visible;
    opacity: 1;
  }
  */
}
@media only screen and (max-width: 767px) {
  .ec-categoryHeadRole .ec-categoryHeadRole__content {
    padding: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-categoryHeadRole .ec-categoryHeadRole__content {
    padding: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-categoryHeadRole .ec-categoryHeadRole__content {
    position: absolute;
    top: calc(50% - 50px);
    left: 0;
    width: 100%;
    height: 50%;
  }
}
.ec-categoryHeadRole .ec-categoryHeadRole__content .title {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-align: center;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .ec-categoryHeadRole .ec-categoryHeadRole__content .title {
    font-size: 6vw;
    position: absolute;
    top: calc(19vw - 0.5em);
    left: 0;
    width: 100%;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-categoryHeadRole .ec-categoryHeadRole__content .title {
    font-size: 30px;
    position: absolute;
    top: calc(19vw - 0.5em);
    left: 0;
    width: 100%;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (min-width: 1280px) {
  .ec-categoryHeadRole .ec-categoryHeadRole__content .title {
    margin: 0;
    font-size: 70px;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 0.5em;
  }
}
.ec-categoryHeadRole .ec-categoryHeadRole__content .description {
  font-size: 0.93rem;
}
@media only screen and (min-width: 1280px) {
  .ec-categoryHeadRole .ec-categoryHeadRole__content .description {
    max-width: 58em;
    margin: 0 auto;
    color: #fff;
  }
}
.ec-categoryHeadRole.noimage .ec-categoryHeadRole__image {
  display: none;
}
@media (max-width: 1279px) {
  .ec-categoryHeadRole.noimage .ec-categoryHeadRole__content .title {
    position: static;
    text-shadow: none;
    color: var(--clrDefaultDark, #000);
    margin-bottom: 0.75em;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-categoryHeadRole.noimage .ec-categoryHeadRole__content {
    position: static;
    background: var(--clrBodyToneBg, #f8f8f8);
    padding: 40px;
  }
  .ec-categoryHeadRole.noimage .ec-categoryHeadRole__content .title {
    font-size: 50px;
    color: var(--clrDefaultDark, #000);
    text-shadow: none;
  }
  .ec-categoryHeadRole.noimage .ec-categoryHeadRole__content .description {
    color: var(--clrDefault, #222);
  }
}

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。
*/
.ec-productRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 1480px;
  /* 商品タイトル */
  /* 商品コード（商品タイトルの中で展開） */
  /*
  // 数量ボタンユニット
  .ec-numberInput {

    dl {
      margin: 0;

      dt {
        font-size: 0.86rem;
        font-weight: 500;
      }

      dd {
        margin: 0;
        margin-top: .5rem;
      }
    }

    .ec-number-wrap {
      display: inline-flex;
      flex-direction: row;
      padding: 0;
      margin: 0;
      height: $cartinRow-size;
      border: solid 1px $clrBorderColor;

      input[type=number] {
        width: $cartinRow-size;//3rem;
        padding: 0;
        padding-top: 0.2em;// 上下位置微調整
        margin: 0;
        border-color: transparent;
        border-radius: 0;
        background-color: #fff;
        text-align: center;
        -moz-appearance: textfield;
        font-family: $ff-roboto;
        font-size: 1.4rem;
        font-weight: 400;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0; 
        }
      }

      .ec-number-down,
      .ec-number-up {
        width: $cartinRow-size;
        height: auto;
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
        background-color: #e8e8e8;
      }

      input[type=number],
      .ec-number-down,
      .ec-number-up {
        line-height: $cartinRow-size;
      }
    }
  }

  // __form
  & &__form {
    padding: 0;//1rem 0;
    margin: 1rem 0;
  }

  // 規格ボタン枠
  & &__actions {
    display: flex;
    flex-direction: column;

    @include media_desktop {
      flex-direction: row;
    }

    dl {
      margin: 0;

      &:nth-child(n+2) {
        margin-top: 1rem;

        @include media_desktop {
          margin-top: 0;
          margin-left: 15px;
        }
      }

      dt {
        font-size: 0.86rem;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0;
      }

      dd {
        margin: .5rem 0 0 0;
      }
    }
    .ec-select {
      margin: 0;
    }
  }
  */
  /* アクション枠 */
  /* 規格枠 */
  /*
  // 数量・カートボタン枠
  & &__add-cart {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 1rem 0;

    @include media_sphone {
      display: block;
    }

    // 数量ボタン枠
    .ec-numberInput {
      margin-right: 20px;// デスクトップ・タブレット

      @include media_sphone {
        margin-right: 0;
      }
    }

    // カートインボタン枠
    .ec-productRole__btn {
      flex-grow: 1;

      @include media_sphone {
        margin-top: 1rem;
      }
    }

    .ec-blockBtn--action {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.06rem;
      font-weight: 500;
      letter-spacing: 0;
      height: $cartinRow-size;
      //line-height: $cartinRow-size;

      &:before {
        display: block;
        width: 1.25em;
        height: 1.25em;
        margin-right: .5em;
        background: url(../icon/cart.svg) no-repeat;
        background-size: 100% 100%;
        content: "";
      }
    }
  }

  // お気に入りボタン・シェアボタン枠
  & &__share {
    margin: 3rem 0 1rem;
    display: flex;
    flex-direction: row;
  }

  & &__add-favorite {
    button {
      -webkit-appearance: none;
      appearance: none;
      padding: 0;
      border: 0;
      background: transparent;
      font-size: 1rem;

      &:before {
        display: inline-block;
        width: 1.4375em;
        height: 1.3125em;
        background: url(../icon/favorite.png) no-repeat;
        background-size: contain;
        vertical-align: -0.2em;
        margin: 0 0.5em 0 0;
        content: "";
      }
    }
  }

  // シェアボタン
  & &__sns {
    border-left: 1px solid $clrBorderColor;
    padding: 0 0 0 0.25rem;
    margin: 0 0 0 1rem;

    ul {
      padding: 0;
      margin: 0;
      font-size: 0;

      li {
        display: inline-block;
        padding: 0;
        margin: 0 0 0 0.5rem;
        list-style: none;

        @include media_desktop {
          margin: 0 0 0 0.75rem;
        }

        a {
          display: inline-block;

          i {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;

            &.icon-twitter {
              background-image: url(../icon/sns-twitter.svg);
            }
            &.icon-facebook {
              background-image: url(../icon/sns-facebook.svg);
            }
            &.icon-line {
              background-image: url(../icon/sns-line.svg);
            }
          }
        }
      }
    }
  }
  */
  /* カートイン枠数量・カートイン・お気に入り） */
  /* シェアボタン */
}
@media only screen and (max-width: 767px) {
  .ec-productRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-productRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-productRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-productRole, .ec-layoutRole__mainBetweenColumn .ec-productRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-productRole .ec-productRole__grid {
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 767px) {
  .ec-productRole .ec-productRole__gridCell.cellLeft {
    margin-left: -6.25vw;
    margin-right: -6.25vw;
  }
  .ec-productRole .ec-productRole__gridCell.cellRight {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-productRole .ec-productRole__gridCell {
    /*
    @media only screen and ( orientation: portrait ) {
      &.cellLeft {
        // 画像ビジュアル枠をフル幅にするためネガティブマージン使用
        margin-left: $padding-tablet * -1;
        margin-right: $padding-tablet * -1;
      }

      &.cellRight {
        margin-top: 30px;
      }
    }
    @media only screen and ( orientation: landscape ) {
      &.cellLeft {
        flex: 0 0 auto;
        width: 56%;
      }

      &.cellRight {
        flex: 1 1 auto;
        width: auto;
        margin-left: 50px;
      }
    }
    */
  }
  .ec-productRole .ec-productRole__gridCell.cellLeft {
    margin-left: -3.906vw;
    margin-right: -3.906vw;
  }
  .ec-productRole .ec-productRole__gridCell.cellRight {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-productRole .ec-productRole__gridCell.cellLeft {
    flex: 0 0 auto;
    width: 56%;
  }
  .ec-productRole .ec-productRole__gridCell.cellRight {
    flex: 1 1 auto;
    width: auto;
    margin-left: 50px;
  }
}
.ec-productRole .ec-productRole__img {
  /*
  @include media_desktop {
    flex: 0 0 auto;
    width: 55%;
    margin: 0;
  }
  */
}
.ec-productRole .ec-productRole__profile {
  /*
  @include media_sphone {
    //padding: $padding-sphone 0;
    margin-top: $padding-sphone;
  }

  @include media_tablet {
    //padding: $padding-tablet 0;
    margin-top: $padding-tablet;
  }

  @include media_desktop {
    width: auto;
    //padding-left: 50px;
    margin-left: 50px;
    flex-grow: 1;
  }
  */
}
.ec-productRole .ec-productRole__title h1, .ec-productRole .ec-productRole__title .h1 {
  margin: 0;
  margin-bottom: 0.5em;
  font-family: "Noto Serif JP", serif;
  font-size: 1.5rem;
  font-weight: normal;
  color: var(--clrDefaultDark, #000);
}
@media only screen and (min-width: 1280px) {
  .ec-productRole .ec-productRole__title h1, .ec-productRole .ec-productRole__title .h1 {
    font-size: 2rem;
  }
}
.ec-productRole .ec-productRole__code {
  font-size: 0.933rem;
  color: var(--clrDefaultThin, #444);
  margin: 0.5rem 0;
}
.ec-productRole .ec-productRole__code .product-code-default {
  font-family: "Poppins", "Noto Sans JP", sans-serif;
}
.ec-productRole .ec-productRole__tags {
  list-style: none;
  margin: 0.75rem 0;
  margin-left: -5px;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
}
.ec-productRole .ec-productRole__tag {
  border: solid 1px var(--clrBorderColor, #ddd);
  margin: 0;
  margin-left: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  font-size: 0.866rem;
  padding: 0.5em 0.75em;
  line-height: 1;
  white-space: nowrap;
  color: var(--clrDefaultThin, #444);
}
.ec-productRole .ec-productRole__priceRegular {
  margin: 0.75em 0;
}
.ec-productRole .ec-productRole__priceRegular .ec-price__label {
  font-size: 0.86rem;
}
.ec-productRole .ec-productRole__priceRegular .ec-price__price {
  font-size: 1rem;
}
.ec-productRole .ec-productRole__priceRegular .ec-price__tax {
  font-size: 0.8rem;
}
.ec-productRole .ec-productRole__price {
  margin: 0.75em 0;
  color: var(--clrBtnActionBg, #b20000);
}
.ec-productRole .ec-productRole__price .ec-price__label {
  font-size: 0.86rem;
}
.ec-productRole .ec-productRole__price .ec-price__price {
  font-size: 1.5rem;
  font-weight: 400;
}
@media only screen and (min-width: 1280px) {
  .ec-productRole .ec-productRole__price .ec-price__price {
    font-size: 2rem;
  }
}
.ec-productRole .ec-productRole__price .ec-price__tax {
  font-size: 0.8rem;
}
.ec-productRole .ec-productRole__description {
  font-size: 1rem;
  margin: 1.5rem 0 2rem;
  line-height: 1.7;
  color: var(--clrDefaultThin, #444);
  text-align: justify;
  border-top: 1px solid var(--clrBorderColor, #ddd);
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
  padding: 1rem 0;
}
@media only screen and (max-width: 767px) {
  .ec-productRole .ec-productRole__description {
    margin: 1rem 0;
  }
}
.ec-productRole .ec-productRole__actions {
  margin: 1rem 0;
}
.ec-productRole .ec-productRole__class {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ec-productRole .ec-productRole__class.classCategory2 {
  margin-top: 10px;
}
.ec-productRole .ec-productRole__class .classLabel {
  margin: 0;
  margin-right: 0.5em;
  min-width: 6em;
  font-size: 0.86rem;
}
.ec-productRole .ec-productRole__class .classLabel:after {
  content: "：";
}
.ec-productRole .ec-productRole__class .ec-select select, .ec-productRole .ec-productRole__class .ec-birth select {
  max-width: 100%;
}
@media (min-width: 768px) {
  .ec-productRole .ec-productRole__class {
    display: inline-block;
  }
  .ec-productRole .ec-productRole__class.classCategory2 {
    margin-top: 0;
    margin-left: 12px;
  }
  .ec-productRole .ec-productRole__class .classLabel {
    margin: 0 0 0.25em;
    min-width: auto;
  }
}
.ec-productRole .ec-productRole__cartinRow {
  display: flex;
  align-items: center;
  margin-top: 1.5em;
  /* 数量タイトルラベル */
  /* 数量 */
  /* カートインボタン・品切れ */
  /* お気に入りボタン */
}
.ec-productRole .ec-productRole__cartinRow .quantityLabel {
  margin: 0;
  margin-right: 0.5em;
  font-size: 0.86rem;
  font-weight: 400;
  display: none;
}
.ec-productRole .ec-productRole__cartinRow .ec-quantityRole {
  margin-right: 15px;
}
.ec-productRole .ec-productRole__cartinRow .ec-productRole__btn {
  flex: 1 1 auto;
  max-width: 350px;
}
.ec-productRole .ec-productRole__cartinRow .ec-productRole__btn button {
  height: 3.33rem;
  line-height: 3.33rem;
  width: 100%;
}
.ec-productRole .ec-productRole__cartinRow .ec-blockBtn--favorite {
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .ec-productRole .ec-productRole__cartinRow {
    flex-flow: row wrap;
  }
  .ec-productRole .ec-productRole__cartinRow .quantityLabel {
    display: block;
  }
  .ec-productRole .ec-productRole__cartinRow .quantityLabel,
.ec-productRole .ec-productRole__cartinRow .ec-quantityRole {
    margin-bottom: 1em;
  }
}
.ec-productRole .ec-productRole__share {
  margin-top: 2rem;
}
.ec-productRole .ec-productRole__share .shareLabel {
  margin: 0;
  margin-right: 0.5em;
  display: inline-block;
}
.ec-productRole .ec-productRole__share .shareBtnList {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}
.ec-productRole .ec-productRole__share .shareBtnList li {
  display: inline-block;
  margin-left: 3px;
}
.ec-productRole .ec-productRole__share .shareBtnList li a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  width: 2em;
  height: 2em;
  background: #666;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .ec-productRole .ec-productRole__share .shareBtnList li a {
    font-size: 1.1rem;
  }
}
.ec-productRole .ec-productRole__share .shareBtnList li a i + span {
  display: none;
}
.ec-productRole .ec-productRole__share .shareBtnList li a:hover {
  text-decoration: none;
}
.ec-productRole .ec-productRole__share .shareBtnList li a.twitter:hover {
  background-color: #1B95E0;
}
.ec-productRole .ec-productRole__share .shareBtnList li a.facebook:hover {
  background-color: #3B5999;
}
.ec-productRole .ec-productRole__share .shareBtnList li a.pinterest:hover {
  background-color: #cb2027;
}
.ec-productRole .ec-productRole__share .shareBtnList li a.line:hover {
  background-color: #06c755;
}
.ec-productRole .ec-productRole__share .shareBtnList li a.pocket:hover {
  background-color: #EE4056;
}
.ec-productRole .ec-productRole__share .shareBtnList li a.hatena:hover {
  background-color: #00a4de;
}
.ec-productRole .ec-productRole__category {
  margin: 1rem 0;
  font-size: 0.933rem;
  border-top: solid 1px var(--clrBorderColor, #ddd);
  padding-top: 1rem;
}
@media only screen and (min-width: 1280px) {
  .ec-productRole .ec-productRole__category {
    margin-top: 1.5rem;
  }
}
.ec-productRole .ec-productRole__category .titleLabel {
  color: var(--clrDefaultThin, #444);
  font-size: 0.86rem;
  margin-bottom: 0.5em;
}
.ec-productRole .ec-productRole__category .ec-topicpath .ec-topicpath__item {
  /* 最後のli要素を太字 */
}
.ec-productRole .ec-productRole__category .ec-topicpath .ec-topicpath__item:first-child:before {
  display: none;
}
.ec-productRole .ec-productRole__category .ec-topicpath .ec-topicpath__item:last-child a {
  font-weight: normal;
  pointer-events: auto;
}

/*
モバイル時に横長（landscape）の場合の追加スタイリング

特にタブレットにおいて横置きしての閲覧の場合に商品詳細ページのビジュアル画像が画面いっぱいに表示するとページスクロールができない問題の対策。
JEIA4002では画像拡大用のタッチイベントが画面全体に働くので、対策として、デスクトップ時と同じ見た目に上書きスタイリングします。
また、ビジュアル枠全体がすっぽり収まるようjsにて横幅を計算させます。
*/
#issp {
  --device: "";
}

@media only screen and (max-width: 1279px) and (orientation: landscape) {
  #issp {
    display: none;
    --device: "mobile_landscape";
  }

  .ec-sliderItemRole {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    padding: 3.906vw;
    padding-bottom: 0;
  }
  .ec-sliderItemRole .item_visual {
    flex: 1 0 auto;
    width: auto;
    margin: 0;
    margin-left: 1px;
    border: 1px solid var(--clrBorderColor, #ddd);
  }
  .ec-sliderItemRole .item_nav {
    flex: 0 0 auto;
    width: 15%;
    height: auto;
    margin-top: 0;
    overflow: visible;
  }
  .ec-sliderItemRole .item_nav__inner {
    flex-direction: column;
    left: 0;
  }
  .ec-sliderItemRole .item_nav__prev, .ec-sliderItemRole .item_nav__next {
    width: 100%;
    height: 20px;
  }
  .ec-sliderItemRole .item_nav__prev .arw, .ec-sliderItemRole .item_nav__next .arw {
    top: 0;
    left: 50%;
    width: 30px;
    height: 100%;
    margin-top: 0;
    margin-left: -15px;
  }
  .ec-sliderItemRole .item_nav__prev {
    top: -7px;
  }
  .ec-sliderItemRole .item_nav__prev .arw:before {
    left: 11px;
    margin-top: -2px;
    transform: rotate(45deg);
  }
  .ec-sliderItemRole .item_nav__next {
    top: auto;
    bottom: -7px;
  }
  .ec-sliderItemRole .item_nav__next .arw:before {
    right: 11px;
    margin-top: -5px;
    transform: rotate(225deg);
  }
  .ec-sliderItemRole .slideThumb {
    width: 100%;
    margin-bottom: 1px;
  }
}
/*
商品フリーエリア

フリーエリアのスタイリングになります。
*/
.ec-productRole .ec-productRole__freearea {
  margin-top: 30px;
}
@media only screen and (min-width: 1280px) {
  .ec-productRole .ec-productRole__freearea {
    margin-top: 60px;
  }
}

.ec-productDetailTab--noTab h2, .ec-productDetailTab--noTab .h2 {
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 2em;
  margin-bottom: 1em;
}
.ec-productDetailTab--noTab h3, .ec-productDetailTab--noTab .h3 {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 2em 0 1em;
}
.ec-productDetailTab--noTab h4, .ec-productDetailTab--noTab .h4 {
  font-size: 1.066rem;
  font-weight: 500;
  margin: 2em 0 1em;
}

/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

※ここで定義される`.ec-cartRole__error`はShopping/alert.twigでも使用されている。
*/
.ec-cartRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-cartRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-cartRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-cartRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-cartRole, .ec-layoutRole__mainBetweenColumn .ec-cartRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
.ec-cartRole .ec-cartRole__progress {
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .ec-cartRole .ec-cartRole__progress {
    margin-bottom: 20px;
  }
}
.ec-cartRole .ec-cartRole__error {
  margin-bottom: 20px;
}
.ec-cartRole .ec-cartRole__totalText {
  text-align: center;
  margin-bottom: 20px;
}
.ec-cartRole .ec-cartRole__totalText p {
  margin: 0;
}
.ec-cartRole .ec-cartRole__cart {
  margin: 2em 0;
}
.ec-cartRole .ec-cartRole__actions {
  width: 300px;
  margin-left: auto;
  margin-right: 0;
}
@media only screen and (max-width: 767px) {
  .ec-cartRole .ec-cartRole__actions {
    width: 100%;
    margin: 0;
  }
}
.ec-cartRole .ec-cartRole__total {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0 1.5em;
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
}
.ec-cartRole .ec-cartRole__totalAmount {
  font-size: 1.6rem;
  font-weight: 500;
}
.ec-cartRole .ec-blockBtn--action {
  margin-bottom: 10px;
}

/*
カートページのコンテナ違反？

cart/index.twig
.ec-cartRoleの中に、さらに.ec-cartRoleが指定されていて2重コンテナになっているのをCSSにて吸収対処

shopping/nonmemberでも同様に「.ec-customerRole」の中に.ec-cartRoleが入っているので対処
*/
.ec-cartRole .ec-cartRole,
.ec-customerRole .ec-cartRole {
  padding: 0;
}

/*
カート商品表示

カート内の商品をを表示するテーブル枠です。

タブレット・デスクトップはtableでレイアウトを組みますが、スマホは.ec-cartRowごとにflexでレイアウトを組んでいます。
*/
.ec-cartTable {
  width: 100%;
  display: table;
  border-collapse: collapse;
}
@media only screen and (max-width: 767px) {
  .ec-cartTable {
    display: block;
    border-top: 1px solid var(--clrBorderColor, #ddd);
  }
}

/*
カート商品表示：テーブルヘッダ

カート商品表示のテーブルのヘッダです。
タブレット・デスクトップのみで、スマホでは非表示となります。
*/
.ec-cartHeader {
  display: table-row;
  background-color: var(--clrCartToneBg, #f3f1eb);
}
.ec-cartHeader .ec-cartHeader__label {
  display: table-cell;
  font-size: 0.9rem;
  padding: 0.75em 0;
  text-align: center;
  overflow-x: hidden;
  color: var(--clrDefaultDark, #000);
}
@media only screen and (max-width: 767px) {
  .ec-cartHeader {
    display: none;
  }
}

/*
カート商品単位（共通パーツ）

カート内のアイテムを表示する単位です。

スマホと、タブレット・デスクトップとでレイアウトの組み方を変えてあります。
タブレット・デスクトップではtable行としてのレイアウトですが、スマホはflexを使ってレイアウトを組んでいます。

デバイス事のCSSスタイリングが煩雑ですので、共通パーツをスタイリングしておき、あとはデバイス個別にスタイリングをする流れとなっております。
*/
.ec-cartRow {
  list-style: none;
  margin: 0;
  padding: 0;
  /* ■ 削除カラム（デバイス共通） */
  /* ■ 商品内容カラム（デバイス共通） */
  /* 商品画像 */
  /* 商品サマリ */
  /* ■ 数量カラム（デバイス共通） */
  /* ■ 小計カラム（デバイス共通） */
}
.ec-cartRow a:hover {
  text-decoration: none;
}
.ec-cartRow .ec-cartRow__delColumn .ec-icon {
  display: block;
  color: inherit;
}
.ec-cartRow .ec-cartRow__delColumn .ec-icon:before {
  font-family: "jeiafont";
  content: "\e918";
  display: block;
  font-size: 18px;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  color: var(--clrDefaultThin, #444);
  border: solid 1px var(--clrBorderColor, #ddd);
  border-radius: 50%;
}
.ec-cartRow .ec-cartRow__delColumn .ec-icon img {
  display: none;
}
.ec-cartRow .ec-cartRow__contentColumn {
  text-align: left;
}
.ec-cartRow .ec-cartRow__img {
  display: inline-block;
  vertical-align: top;
}
.ec-cartRow .ec-cartRow__img a {
  display: block;
  overflow: hidden;
  /* HTML構造によってはIE11でちらつく事があるので保険 */
}
.ec-cartRow .ec-cartRow__img a * {
  overflow: hidden;
}
.ec-cartRow .ec-cartRow__img a img {
  transition: 0.6s;
}
.ec-cartRow .ec-cartRow__img a:hover img {
  transform: scale(1.1);
  filter: brightness(1.05);
}
.ec-cartRow .ec-cartRow__summary {
  display: inline-block;
  vertical-align: top;
  padding-left: 1em;
  /* 商品価格 */
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__name {
  /* 規格名をくくる要素はないので、まず規格名称を基準にスタイリングしておいて、
    * それから商品名が格納されるa要素でスタイリングを施す
    */
  font-size: 0.9em;
  line-height: 1.2;
  margin-left: 0.5rem;
  margin-bottom: 0.5em;
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__name a {
  display: block;
  color: inherit;
  font-size: 1rem;
  font-weight: 500;
  margin-left: -0.5rem;
  margin-bottom: 0.4em;
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__name a + br {
  display: none;
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__unitPrice {
  font-size: 1rem;
}
.ec-cartRow .ec-cartRow__amountColumn {
  /* 数量表示 */
  /* 数量変更ボタンエリア */
  /* 数量変更ボタン共通 */
  /* 数量プラスボタン */
  /* ボタン無効 */
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
  display: inline-flex;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton,
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton,
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  display: block;
  width: 36px;
  height: 36px;
  margin-left: 1px;
  color: inherit;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton__icon,
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton__icon,
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--clrCartToneBg, #f3f1eb);
  color: var(--clrDefaultDark, #000);
  padding: 5px;
  box-sizing: border-box;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton__icon:before,
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton__icon:before,
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled__icon:before {
  font-family: "jeiafont";
  content: "\e917";
  font-size: 1.5em;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton__icon img,
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton__icon img,
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled__icon img {
  display: none;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton__icon:before {
  content: "\e916";
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled .ec-cartRow__amountDownButton__icon {
  color: #bbb;
}
.ec-cartRow .ec-cartRow__subtotalColumn {
  font-weight: 500;
}
.ec-cartRow .ec-cartRow__sutbtotalSP {
  display: none;
}
.ec-cartRow .ec-cartRow__amountSP {
  display: none;
}

/*
カート商品単位（スマホ用）

flexを用いてまず、「商品内容カラム」を100%フル幅にして、
改行する形で「数量カラム」と「小計カラム」を左右2列で配置。
削除ボタンはpositionで右上に配置するレイアウトです。
*/
@media only screen and (max-width: 767px) {
  .ec-cartRow {
    display: flex;
    flex-flow: row wrap;
    font-size: 1rem;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid var(--clrBorderColor, #ddd);
    position: relative;
    /* ■ 削除カラム */
    /* ■ 商品内容カラム */
    /* 商品画像 */
    /* ■ 数量カラム */
    /* ■ 小計カラム */
  }
  .ec-cartRow .ec-cartRow__delColumn {
    position: absolute;
    top: 0.5em;
    right: 0;
  }
  .ec-cartRow .ec-cartRow__contentColumn {
    order: 1;
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 5px;
  }
  .ec-cartRow .ec-cartRow__img {
    width: 22vw;
    max-width: 120px;
  }
  .ec-cartRow .ec-cartRow__amountColumn {
    order: 3;
    flex: 1 1 auto;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* 数量表示 */
    /* 数量変更ボタンエリア */
    /* 数量変更ボタンサイズ */
  }
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
    margin-right: 1em;
  }
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount:before {
    content: "数量";
    display: inline-block;
    margin-right: 0.5em;
  }
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton,
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton,
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
    width: 36px;
    height: 36px;
  }
  .ec-cartRow .ec-cartRow__subtotalColumn {
    order: 4;
    flex: 0 0 auto;
    min-width: 25%;
    padding-left: 1em;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/*
カート商品単位（タブレット・デスクトップ用用）
*/
@media only screen and (min-width: 768px) {
  .ec-cartRow {
    display: table-row;
    font-size: 1rem;
    border-bottom: 1px solid var(--clrBorderColor, #ddd);
    /* ■ 削除カラム */
    /* ■ 商品内容カラム */
    /* 商品画像 */
    /* ■ 数量カラム */
    /* ■ 小計カラム */
  }
  .ec-cartRow .ec-cartRow__delColumn {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 5em;
  }
  .ec-cartRow .ec-cartRow__delColumn .ec-icon {
    display: inline-block;
  }
  .ec-cartRow .ec-cartRow__delColumn .ec-icon:before {
    border: none;
  }
  .ec-cartRow .ec-cartRow__contentColumn {
    display: table-cell;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .ec-cartRow .ec-cartRow__img {
    width: 100px;
  }
  .ec-cartRow .ec-cartRow__amountColumn {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    /* 数量表示 */
  }
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1em;
  }
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount:before {
    content: "数量";
    display: inline-block;
    margin-right: 0.5em;
  }
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
    vertical-align: middle;
  }
  .ec-cartRow .ec-cartRow__subtotalColumn {
    text-align: right;
    display: table-cell;
    vertical-align: middle;
  }
}

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。
*/
.ec-alert-warning {
  background-color: #ff9999;
  color: #fff;
  font-size: 1rem;
  padding: 1em;
  text-align: center;
}
.ec-alert-warning .ec-alert-warning__icon {
  display: inline-block;
  width: 2em;
  height: auto;
  margin-bottom: 0.5em;
}

/*
送料無料等のメッセージ

「送料無料」「あと〇〇円で送料無料になります」のメッセージ

このエリアのclass名が「.ec-cartRole__progress」となっており、これはカートやショッピングフローの
ページ冒頭にある「1-2-3-4」進捗状況を表示する部分と同じclass名称となっています。
コーティング上のミスだと思いますが、本来であればTwig側を編集すべきだと思いますが、
テンプレートとしては極力、必要以外の編集を加えたくないのでCSSで吸収したいと思います。

ただ、メッセージが空の場合でも要素自体は空のまま出力されてしまっているのでこのメッセージ欄の余白は影響を及ぼします。
その前提で上下のパーツの調整が必要となるかもしれません。

もし、Twig側を編集するのでありましたら、こちらのCSSも調整または削除してください。
*/
.ec-cartRole .ec-cartRole__cart + .ec-cartRole__progress {
  margin: 0;
  padding: 0;
  margin-top: 1em;
  text-align: right;
}

/*
ショッピング完了コンテナ
*/
.ec-cartCompleteRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-cartCompleteRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-cartCompleteRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-cartCompleteRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-cartCompleteRole, .ec-layoutRole__mainBetweenColumn .ec-cartCompleteRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

/*
注文内容確認

ショッピングフロー（入力、確認）と、マイページの注文履歴詳細
*/
/*
ページレイアウト

タブレット・デスクトップは注文内容とカートサマリーを左右2分割。スマホは縦1列配置。
*/
.ec-orderRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-orderRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-orderRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-orderRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-orderRole, .ec-layoutRole__mainBetweenColumn .ec-orderRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ec-orderRole {
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-orderRole {
    display: flex;
    justify-content: space-between;
  }
  .ec-orderRole .ec-orderRole__detail {
    flex: 1 1 auto;
    padding-right: 30px;
  }
  .ec-orderRole .ec-orderRole__detail > *:last-child {
    margin-bottom: 0;
  }
  .ec-orderRole .ec-orderRole__summary {
    flex: 0 0 250px;
    /*
    スクロールしてもサマリー欄をページ内に粘着（sticky）させる
    EC-CUBE4標準ではJavascriptで実装している機能ですが、CSSの「position:sticky;」を使って実装してみます。
    当テンプレートではヘッダーがページ上部に固定させる仕様のため、そのままではヘッダーに隠れてしまいます。
    スクリプトを調整するよりはstickyでの実装が簡単だと判断しました。（※IE11では動作しません。）
    */
  }
  .ec-orderRole .ec-orderRole__summary .ec-totalBox {
    margin: 0;
    position: sticky;
    top: 70px;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-orderRole {
    display: flex;
    justify-content: space-between;
  }
  .ec-orderRole .ec-orderRole__detail {
    flex: 1 1 auto;
    padding-right: 40px;
  }
  .ec-orderRole .ec-orderRole__detail > *:last-child {
    margin-bottom: 0;
  }
  .ec-orderRole .ec-orderRole__summary {
    flex: 0 0 27%;
    /* スクロール粘着（タブレット時と同様） */
  }
  .ec-orderRole .ec-orderRole__summary .ec-totalBox {
    margin: 0;
    position: sticky;
    top: 100px;
  }
}

/*
マイページ購入履歴詳細の「戻る」ボタン周り
*/
@media only screen and (max-width: 767px) {
  body#page_mypage_history .ec-orderRole:nth-child(n+2) {
    margin-top: 6.25vw;
  }
}

/* 注文内容各項目の共通（下マージン） */
@media only screen and (max-width: 767px) {
  .ec-orderOrder,
.ec-orderAccount,
.ec-orderDelivery,
.ec-orderPayment,
.ec-orderConfirm,
.ec-orderCoupon {
    margin-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-orderOrder,
.ec-orderAccount,
.ec-orderDelivery,
.ec-orderPayment,
.ec-orderConfirm,
.ec-orderCoupon {
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-orderOrder,
.ec-orderAccount,
.ec-orderDelivery,
.ec-orderPayment,
.ec-orderConfirm,
.ec-orderCoupon {
    margin-bottom: 40px;
  }
}

/*
オーダー情報（注文履歴詳細）

マイページ 注文履歴詳細の冒頭、注文日時や注文番号の記載の部分

※メモ：dt,dd要素で組まれているがdl親要素が無いのでできれば調整しておいたほうがベスト
*/
.ec-orderOrder {
  font-size: 0.933rem;
}
.ec-orderOrder .ec-definitions, .ec-orderOrder .ec-definitions--soft {
  padding-bottom: 0.5em;
  border-bottom: dotted 1px var(--clrBorderColor, #ddd);
}
.ec-orderOrder .ec-definitions dt, .ec-orderOrder .ec-definitions--soft dt {
  font-weight: normal;
}

/*
お客様情報（ショッピングフロー）
*/
.ec-orderAccount {
  /* お客様情報（確定情報） */
  /* ゲスト購入時の編集入力枠 */
  /* 変更ボタン */
}
.ec-orderAccount .ec-orderAccount__account {
  border: solid 1px var(--clrBorderColor, #ddd);
  padding: 10px;
}
.ec-orderAccount .ec-orderAccount__account .ec-halfInput,
.ec-orderAccount .ec-orderAccount__account .ec-input,
.ec-orderAccount .ec-orderAccount__account .ec-numberInput,
.ec-orderAccount .ec-orderAccount__account .ec-zipInput,
.ec-orderAccount .ec-orderAccount__account .ec-telInput {
  margin: 0;
  margin-bottom: 0.5em;
}
.ec-orderAccount .ec-orderAccount__account .ec-zipInput {
  margin-bottom: 0;
}
.ec-orderAccount .ec-orderAccount__account .ec-telInput:before {
  content: "電話番号　";
}
.ec-orderAccount .ec-orderAccount__account .customer-email:before {
  content: "メールアドレス　";
}
.ec-orderAccount .ec-borderedDefs {
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 767px) {
  .ec-orderAccount .ec-borderedDefs {
    margin-bottom: 0;
  }
}
.ec-orderAccount .ec-orderAccount__change {
  text-align: right;
  margin-bottom: 10px;
}

/*
配送情報

※メモ：dt,dd要素で組まれているがdl親要素が無いのでできれば調整しておいたほうがベスト
*/
.ec-orderDelivery {
  /* タイトル「お届け先」 */
  /* 変更ボタン */
  /* 商品内容 */
  /* お届け先住所 */
  /* 配送方法・お届け日時 */
  /* お届け先追加ボタンエリア */
  /*
  複数配送先の場合　各お届け先ごとのエリア区分が判別しやすいように調整
  少し太めのborderを敷いています。

  ※お届け先ごとにグルーピングされる要素が無いので、2つ目以降のお届け先は、
  「__actions」に続く「__title」でしか判別できません
  */
}
.ec-orderDelivery .ec-orderDelivery__title {
  font-size: 1rem;
  line-height: 40px;
  font-weight: 700;
  position: relative;
  margin-bottom: 1rem;
}
.ec-orderDelivery .ec-orderDelivery__change {
  position: absolute;
  right: 0;
  top: 0;
}
.ec-orderDelivery .ec-orderDelivery__item {
  /* 軽減税率対象商品である事のコメント */
}
.ec-orderDelivery .ec-orderDelivery__item ul + p {
  font-size: 0.933rem;
  margin-top: 0.5em;
}
.ec-orderDelivery .ec-orderDelivery__address {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.ec-orderDelivery .ec-orderDelivery__address p {
  margin: 0;
}
.ec-orderDelivery .ec-orderDelivery__actions label {
  font-weight: normal;
  min-width: 6em;
}
@media only screen and (min-width: 1280px) {
  .ec-orderDelivery .ec-orderDelivery__actions label {
    min-width: auto;
  }
}
body#page_shopping_confirm .ec-orderDelivery .ec-orderDelivery__actions .ec-select, body#page_shopping_confirm .ec-orderDelivery .ec-orderDelivery__actions .ec-birth {
  display: flex;
  align-items: center;
  margin: 0.5em 0 !important;
}
body#page_shopping_confirm .ec-orderDelivery .ec-orderDelivery__actions .ec-select label, body#page_shopping_confirm .ec-orderDelivery .ec-orderDelivery__actions .ec-birth label {
  display: block;
  min-width: 6em;
  padding: 0.2em;
  text-align: center;
  background-color: var(--clrBodyToneBg, #f8f8f8);
  margin-right: 1em;
}
.ec-orderDelivery .ec-orderDelivery__edit {
  border-top: 1px solid var(--clrBorderColor, #ddd);
  padding-top: 20px;
  margin-top: 20px;
}
.ec-orderDelivery .ec-orderDelivery__actions + .ec-orderDelivery__title {
  margin-top: 1em;
  border-top: solid 2px var(--clrBorderColor, #ddd);
  padding-top: 1em;
}
.ec-orderDelivery .ec-orderDelivery__actions + .ec-orderDelivery__title .ec-orderDelivery__change {
  top: 1em;
}

/*
注文詳細 お支払情報

※「お支払い方法」欄のほか、「利用ポイント」も同じclassを使用
*/
.ec-orderPayment .ec-input input#shopping_order_use_point, .ec-orderPayment .ec-halfInput input#shopping_order_use_point, .ec-orderPayment .ec-numberInput input#shopping_order_use_point, .ec-orderPayment .ec-zipInput input#shopping_order_use_point, .ec-orderPayment .ec-telInput input#shopping_order_use_point {
  width: 10em;
  text-align: right;
}
.ec-orderPayment img {
  width: auto;
}

/*
注文詳細 お問い合わせ
*/
/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。
*/
.ec-AddAddress {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  /* 新規お届け先追加ボタンエリア */
  /* 商品単位のフレーム */
  /* 商品アイテム（画像とコンテンツ） */
  /* 商品アイテム：画像 */
  /* 商品アイテム：コンテンツ */
  /* 商品アイテムコンテンツ：商品名 */
  /* 商品アイテムコンテンツ：商品規格名 */
  /* 商品アイテムコンテンツ：商品価格 */
  /* 商品アイテムコンテンツ：数量 */
  /* 住所・数量選択欄 */
  /* 住所選択 */
  /* 数量指定 */
  /* お届け先追加ボタンエリア */
  /* 選択したお届け先に送るボタンエリア */
}
@media only screen and (max-width: 767px) {
  .ec-AddAddress {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-AddAddress {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-AddAddress {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-AddAddress, .ec-layoutRole__mainBetweenColumn .ec-AddAddress {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
.ec-AddAddress .ec-AddAddress__new {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
}
@media only screen and (max-width: 767px) {
  .ec-AddAddress .ec-AddAddress__new {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.ec-AddAddress .ec-AddAddress__add {
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  font-size: 1rem;
}
@media only screen and (max-width: 767px) {
  .ec-AddAddress .ec-AddAddress__add {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.ec-AddAddress .ec-AddAddress__item {
  display: flex;
}
.ec-AddAddress .ec-AddAddress__itemThumb {
  flex: 0 0 auto;
  width: 22vw;
  max-width: 120px;
}
.ec-AddAddress .ec-AddAddress__itemThumb img {
  width: 100%;
}
.ec-AddAddress .ec-AddAddress__itemtContent {
  flex: 1 1 auto;
  padding-left: 1em;
}
.ec-AddAddress .ec-AddAddress__itemtTitle {
  font-weight: 500;
}
.ec-AddAddress .ec-AddAddress__itemtSize {
  font-size: 0.9em;
  margin-left: 0.75em;
}
.ec-AddAddress .ec-AddAddress__itemtPrice {
  margin-top: 0.5em;
  display: inline-block;
}
.ec-AddAddress .ec-AddAddress__itemtNumber {
  display: inline-block;
  margin-left: 1em;
}
.ec-AddAddress .ec-AddAddress__select {
  margin: 20px 0;
  background-color: var(--clrCartToneBg, #f3f1eb);
  padding: 1em;
  /* 削除ボタン */
}
.ec-AddAddress .ec-AddAddress__select .ec-inlineBtn.delete {
  display: inline-block;
  margin-left: 1em;
}
@media only screen and (max-width: 767px) {
  .ec-AddAddress .ec-AddAddress__select {
    position: relative;
  }
  .ec-AddAddress .ec-AddAddress__select .ec-inlineBtn.delete {
    position: absolute;
    bottom: 1em;
    right: 1em;
    padding: 8px 1em;
  }
}
.ec-AddAddress .ec-AddAddress__selectAddress {
  display: inline-block;
}
.ec-AddAddress .ec-AddAddress__selectAddress label {
  font-weight: normal;
  margin-right: 0.5em;
}
.ec-AddAddress .ec-AddAddress__selectAddress .select_ui {
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .ec-AddAddress .ec-AddAddress__selectAddress {
    display: block;
  }
  .ec-AddAddress .ec-AddAddress__selectAddress label {
    margin: 0 0 1em;
  }
  .ec-AddAddress .ec-AddAddress__selectAddress select {
    max-width: 100%;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-AddAddress .ec-AddAddress__selectAddress select {
    max-width: 50vw;
  }
}
.ec-AddAddress .ec-AddAddress__selectNumber {
  display: inline-block;
  margin-left: 1em;
}
.ec-AddAddress .ec-AddAddress__selectNumber label {
  font-weight: normal;
  margin-right: 0.5em;
}
.ec-AddAddress .ec-AddAddress__selectNumber input {
  display: inline-block;
  width: 80px;
}
@media only screen and (max-width: 767px) {
  .ec-AddAddress .ec-AddAddress__selectNumber {
    display: block;
    margin-left: 0;
    margin-top: 1em;
  }
}
.ec-AddAddress .ec-AddAddress__actions .ec-blockBtn--action {
  margin-bottom: 10px;
}

/*
補正

EC-CUBE4標準では、フォーム送信系のページの確認ページにおいて、入力フォームではない部位に「.ec-input」などのフォーム部品包括要素が
そのまま入力ページと同じ構成で使われています。
これが仕様なのかコーディングミスなのかは判別つきませんが、それにより少々CSSスタイリングがしづらい面があります。

※フォームパーツの特性がそれぞれにあるからこそ包括要素もそれぞれにあるのだろうと思うのですが、確認ページはそもそもテキスト表示のみですから、
そこは意図するものが違いますから別物としてコーディングすべきかとも思います。


ショッピングフローの「確認ページ」において、「お支払い方法」「利用ポイント」は「.ec-blockRadio」が使われていますがそれにより崩れが生じます。
（※「.ec-blockRadio」はラジオ部品を縦に並べる際に部品間に与えた余白を、ネガティブマージンで全体調整しています）
「.ec-blockRadio」のネガティブマージンを別の手法に置き換えるか考えるところですが、しかし、「お支払い方法」は入力ページでは別要素「.ec-radio」で
括られており入力ページと確認ページとで包括要素が別物になってるあたりコーディングミスなのだろうと思いますので、
.ec-blockRadioを調整するよりは、部分的な補正にとどめるのがベストかと思います。
さらに言えば「利用ポイント」はblockRadioの要素はまったくないわけですし…。次期バージョンアップで修正を希望します。

確認ページ：お支払い方法、利用ポイントの補正
*/
body#page_shopping_confirm .ec-blockRadio {
  margin-top: 0;
}

/*
クーポンプラグイン

ここでは補正程度にスタイリングを加えるに留めています
*/
.ec-orderCoupon #coupon_button {
  margin-top: 1em;
}

/*
マイページ　注文履歴一覧
*/
.ec-historyRole {
  font-size: 0.933rem;
  /* 左枠：注文データ */
  /* 右枠：商品内容 */
}
.ec-historyRole .ec-historyRole__contents {
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
  border-top: 1px solid var(--clrBorderColor, #ddd);
}
@media only screen and (max-width: 767px) {
  .ec-historyRole .ec-historyRole__contents {
    display: block;
  }
}
.ec-historyRole .ec-historyRole__header {
  flex: 0 0 25%;
  background-color: var(--clrBodyToneBg, #f8f8f8);
  padding: 1em;
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-historyRole .ec-historyRole__header {
    flex-basis: 33%;
  }
}
@media only screen and (max-width: 767px) {
  .ec-historyRole .ec-historyRole__header {
    width: 100%;
    padding: 10px;
  }
}
.ec-historyRole .ec-historyRole__detail {
  flex: 1 1 auto;
  padding-left: 10px;
  /* 商品内容 */
  /* 商品名称 */
  /* 商品価格＆数量 */
}
@media only screen and (max-width: 767px) {
  .ec-historyRole .ec-historyRole__detail {
    width: 100%;
    padding: 0;
    margin-top: 10px;
  }
}
.ec-historyRole .ec-historyRole__detail .ec-imageGrid + .ec-imageGrid {
  margin-top: 10px;
}
/*
注文履歴一覧ヘッダ内容
*/
.ec-historyListHeader {
  /* 日付 */
  /* ご注文番号・状況*/
}
.ec-historyListHeader .ec-definitions dt, .ec-historyListHeader .ec-definitions--soft dt {
  font-weight: normal;
  min-width: auto;
}
.ec-historyListHeader .ec-historyListHeader__action {
  margin: 0;
  margin-top: 1.5em;
}
@media only screen and (max-width: 767px) {
  .ec-historyListHeader {
    position: relative;
  }
  .ec-historyListHeader .ec-definitions, .ec-historyListHeader .ec-definitions--soft {
    margin: 0;
  }
  .ec-historyListHeader .ec-historyListHeader__action {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
}

/*
注文履歴詳細

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

注文履歴詳細の構造は`.ec-orderRole`と同じで、これはスタイルガイド15.2「注文内容確認」でスタイリングが完了しておりここでは追加定義は何もありません。
*/
/*
注文履歴詳細 メール履歴

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。
*/
/*
注文履歴詳細 メール履歴個別

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

メモ：
メール本文の`.ec-orderMail__body`は初期時は「display:none;」されており、function.jsにより`.ec-orderMail__link`クリックで展開されるようです。
*/
.ec-orderMail {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
}
.ec-orderMail .ec-orderMail__time {
  margin: 0;
}
.ec-orderMail .ec-orderMail__body {
  display: none;
}
.ec-orderMail .ec-orderMail__time {
  margin-bottom: 0.5em;
}
.ec-orderMail .ec-orderMail__link {
  margin-bottom: 4px;
}
.ec-orderMail .ec-orderMail__close {
  margin-top: 1em;
}
/*
住所一覧

マイページ「お届け先一覧」と、ショッピングページ「お届け先の指定」にて使用

ショッピングページ「お届け先の指定」は、配送情報の「変更」ボタンから入ることができます。
しかしながら、この「お届け先の指定」の選択ボタンの部位が、マイページ「お届け先一覧」の削除ボタン部位と同じ扱いとなっており、
その包括classが「.ec-addressList__remove」となっているのは非常に気持ち悪い所ですのでTwigの編集ができるのであればそうしたいところ

当テンプレートではそのままですが、レイアウト的に、選択ボタンは左に寄せ、削除ボタンは右に寄せるようにスタイリングしています。
*/
.ec-addressRole {
  /* 新規お届け先を追加ボタンエリア */
}
.ec-addressRole .ec-addressRole__actions {
  margin-bottom: 2em;
}

/* 住所一覧 */
.ec-addressList {
  /* 削除ボタンエリア、兼、選択ボタンエリア */
  /* 住所エリア */
  /* 変更ボタン（マイページお届け先一覧のみ） */
}
.ec-addressList .ec-addressList__item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--clrBorderColor, #ddd);
}
.ec-addressList .ec-addressList__item:first-child {
  border-top: 1px solid var(--clrBorderColor, #ddd);
}
.ec-addressList .ec-addressList__remove {
  flex: 0 0 auto;
  min-width: 3em;
  text-align: center;
  /*
  選択ボタン（ラジオボタン）においては、ここでは.ec-radioがコーディングされておりませんので、javascriptで.ec-radioとlabelを挿入しています。
  また、住所内容にもlabelを挿入して、住所のクリックでもラジオボタンが反応するようにしてあります。
  javascriptで挿入するのは気がひけますが、もし編集可能であればTwig側をほかのフォームページと同等になるように修正してください（EC-CUBE時期バージョンで修正希望）
  */
}
.ec-addressList .ec-addressList__address {
  flex: 1 1 auto;
  padding: 1em;
  padding-left: 0;
  /*
  住所エリアにlabelはコーディングされておりませんがUI的にあったほうがいいと判断しjavascriptで挿入させています。（「お届け先の指定」ページ）
  */
}
.ec-addressList .ec-addressList__address label {
  font-weight: normal;
  /* 氏名欄（識別classは振られていないので、やむなく1番目の要素を氏名と識別） */
}
.ec-addressList .ec-addressList__address label > :first-child {
  font-weight: 500;
}
.ec-addressList .ec-addressList__action {
  flex: 0 0 auto;
  padding: 0;
}

/*
マイページ「お届け先一覧」用のカスタマイズ
削除ボタンエリアを右端に来るように変更し、削除アイコンは使わずインラインボタンを生成
*/
body#page_mypage_delivery .ec-addressList .ec-addressList__remove {
  order: 3;
  width: auto;
  padding-left: 10px;
}
body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after {
  content: "削除";
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
  color: var(--clrBtnDefaultText, #fff);
  background-color: var(--clrBtnDefaultBg, #242930);
  border-color: var(--clrBtnDefaultBg, #444);
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  font-size: 0.9rem;
  padding: 0.6em 1em;
  font-weight: normal;
  font-size: 0.9rem;
}
body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after:focus, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after.focus {
  color: var(--clrBtnDefaultText, #fff);
  outline: none;
  border-color: var(--clrInputFocusBorder, #3c8dbc);
  box-shadow: 0 0 0 var(--focusShadowsize, 1px) var(--clrInputFocusShadow, #cceeff);
}
body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after:hover {
  text-decoration: none;
  color: var(--clrBtnDefaultText, #fff);
  filter: brightness(1.1);
}
body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after.disabled, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after[disabled], fieldset[disabled] body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
  cursor: not-allowed;
}
body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after.disabled:hover, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after.disabled:focus, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after.disabled.focus, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after[disabled]:hover, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after[disabled]:focus, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after[disabled].focus, fieldset[disabled] body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after:hover, fieldset[disabled] body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after:focus, fieldset[disabled] body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after.focus {
  background-color: var(--clrBtnDisabledBg, #999);
  border-color: var(--clrBtnDisabledBg, #999);
}
body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after .badge {
  color: var(--clrBtnDefaultBg, #242930);
  background-color: var(--clrBtnDefaultText, #fff);
}
body#page_mypage_delivery .ec-addressList .ec-addressList__remove .ec-icon {
  display: none;
}

/*
パスワードリセット

パスワードリセットのスタイルです。
*/
.ec-forgotRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-forgotRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-forgotRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-forgotRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-forgotRole, .ec-layoutRole__mainBetweenColumn .ec-forgotRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
.ec-forgotRole .ec-forgotRole__form {
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-forgotRole .ec-forgotRole__form {
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-forgotRole .ec-forgotRole__form {
    margin-bottom: 40px;
  }
}
.ec-forgotRole .ec-forgotRole__intro {
  font-size: 1rem;
}

/*
会員登録系

会員登録 に関する Project コンポーネントを定義します。

会員登録ページのみでなく、会員情報を扱いフォームページにて使用されている様子

メモ：
.ec-registerRole__actionsの上マージンについて、入力ページと確認ページとでは「利用規約チェック」の有無の違いがあるので余白の微調整をしたいところだが、
入力ページと確認ページで識別classが付くわけではない様子なので、区別ができない（たぶん）。
Twigを編集すれば可能となるがテンプレートではTwigは極力変更しない考えなので、この件は保留。jsで実装するのもちょっとあれなので。
*/
.ec-registerRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-registerRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-registerRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-registerRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-registerRole, .ec-layoutRole__mainBetweenColumn .ec-registerRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
.ec-registerRole .ec-registerRole__actions .ec-checkbox, .ec-registerRole .ec-registerRole__actions .ec-blockCheckbox {
  margin: 1em 0;
  border: solid 1px var(--clrBorderColor, #ddd);
  padding: 1em;
}
.ec-registerRole .ec-registerRole__actions .ec-checkbox label label, .ec-registerRole .ec-registerRole__actions .ec-blockCheckbox label label {
  margin: 0;
}

.ec-registerCompleteRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-registerCompleteRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-registerCompleteRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-registerCompleteRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-registerCompleteRole, .ec-layoutRole__mainBetweenColumn .ec-registerCompleteRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

/*
.ec-registerRole__actions

`.ec-registerRole__actions`は、会員情報系のフォームページ専用のアクションボタンの括り要素という位置づけで、
コンテナ`.ec-registerRole`に属するコンポーネントという設計思想かと思いましたが、実際のところ、
「お問い合わせ」ページのボタン部分にも使われており、しかし「お問い合わせ」ページはコンテナが`.ec-registerRole`ではありません。
どうやら`.ec-registerRole__actions`は、必ずしも`.ec-registerRole`の中に包含されているとは限らないようで、
ほかのさまざまなコンテナの中で使われています。コーディングルールから外れているように思いますが、CSS側で吸収してスタイリングし直しています。

※送信ボタンRole的なものは様々なフォームページで登場しますので、汎用な意味を持たせる意味で「register」とは違う名称を付けるべきかもしれません。

「.ec-registerRole__actions」使用箇所：
* Contact/confirm.twig
* Contact/index.twig
* Entry/confirm.twig
* Entry/index.twig
* Forgot/reset.twig
* Mypage/change.twig
* Mypage/delivery_edit.twig
* Shopping/nonmember.twig
* Shopping/shipping.twig
* Shopping/shipping_edit.twig
* Shopping/shipping_multiple_edit.twig

また、いくつかのページでは「.ec-RegisterRole__actions」と「r」が大文字「R」になっており、これはおそらくコーディングミスだと思われますが、
これもCSS側で吸収しておきます。（Twigの必要以外の編集はテンプレート的には極力避けたい所です）
※4.2.0でも「R」大文字はまだ残ったままとなっています。

大文字R該当ページ：

* Contact/confirm.twig
* Contact/index.twig
* Mypage/change.twig
* Mypage/delivery_edit.twig
* Shopping/nonmember.twig
* Shopping/shipping_edit.twig
*/
.ec-registerRole .ec-registerRole__actions,
.ec-registerRole .ec-RegisterRole__actions,
.ec-mypageRole .ec-registerRole__actions,
.ec-mypageRole .ec-RegisterRole__actions,
.ec-contactRole .ec-registerRole__actions,
.ec-contactRole .ec-RegisterRole__actions,
.ec-contactConfirmRole .ec-registerRole__actions,
.ec-contactConfirmRole .ec-RegisterRole__actions,
.ec-customerRole .ec-registerRole__actions,
.ec-customerRole .ec-RegisterRole__actions {
  margin-top: 6.25vw;
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-registerRole .ec-registerRole__actions,
.ec-registerRole .ec-RegisterRole__actions,
.ec-mypageRole .ec-registerRole__actions,
.ec-mypageRole .ec-RegisterRole__actions,
.ec-contactRole .ec-registerRole__actions,
.ec-contactRole .ec-RegisterRole__actions,
.ec-contactConfirmRole .ec-registerRole__actions,
.ec-contactConfirmRole .ec-RegisterRole__actions,
.ec-customerRole .ec-registerRole__actions,
.ec-customerRole .ec-RegisterRole__actions {
    margin-top: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-registerRole .ec-registerRole__actions,
.ec-registerRole .ec-RegisterRole__actions,
.ec-mypageRole .ec-registerRole__actions,
.ec-mypageRole .ec-RegisterRole__actions,
.ec-contactRole .ec-registerRole__actions,
.ec-contactRole .ec-RegisterRole__actions,
.ec-contactConfirmRole .ec-registerRole__actions,
.ec-contactConfirmRole .ec-RegisterRole__actions,
.ec-customerRole .ec-registerRole__actions,
.ec-customerRole .ec-RegisterRole__actions {
    margin-top: 40px;
  }
}
.ec-registerRole .ec-registerRole__actions .ec-blockBtn--action,
.ec-registerRole .ec-RegisterRole__actions .ec-blockBtn--action,
.ec-mypageRole .ec-registerRole__actions .ec-blockBtn--action,
.ec-mypageRole .ec-RegisterRole__actions .ec-blockBtn--action,
.ec-contactRole .ec-registerRole__actions .ec-blockBtn--action,
.ec-contactRole .ec-RegisterRole__actions .ec-blockBtn--action,
.ec-contactConfirmRole .ec-registerRole__actions .ec-blockBtn--action,
.ec-contactConfirmRole .ec-RegisterRole__actions .ec-blockBtn--action,
.ec-customerRole .ec-registerRole__actions .ec-blockBtn--action,
.ec-customerRole .ec-RegisterRole__actions .ec-blockBtn--action {
  margin-bottom: 1rem;
}

/*
お届け先の複数指定
shopping_multiple.twig
4.1.2以前はレジスタボタンの外側に「.ec-off4Grid .ec-off4Grid__cell」で包括されていましたが、4.2.0ではその外枠要素が削除されました。
これによりレジスタボタンがコンテナ幅いっぱいになってしまうので、体裁を整えるため調整を加えています。
*/
@media only screen and (min-width: 1280px) {
  body#page_shopping_shipping_multiple .ec-AddAddress__actions {
    min-height: 1px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 1280px) and (min-width: 1280px) {
  body#page_shopping_shipping_multiple .ec-AddAddress__actions {
    width: 33.3333333333%;
  }
}

/*
コンテナ違反？

`.ec-registerRole`は「@include container」されて「コンテナ」として振舞いますが（EC-CUBE標準仕様）、
「お届け先変更」ページ（shipping_edit.twig）では、同じコンテナの「.ec-role」の中に包含される形でコーディングされており、
結果、左右余白が2重にかかってしまいます。
会員ログイン時では2重コンテナにはなっておらずコンテナの住み分けができているので、こちらはコーディングミスではないかと思われます。
テンプレートとしてはTwig編集は極力避けたいのでCSS側にて吸収します。
*/
.ec-role .ec-registerRole {
  padding-left: 0;
  padding-right: 0;
}

/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。
*/
.ec-contactRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  /* .ec-contactRole__actions
   * EC-CUBE4では実際にはこれは使われておらず、「ec-RegisterRole__actions」となっています（しかもrの字が大文字R）
   * おそらくコーディングミスと思われますが、Twigを編集せずにCSS側で吸収して調整しています。（_19.1.register.scss）
   */
}
@media only screen and (max-width: 767px) {
  .ec-contactRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-contactRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-contactRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-contactRole, .ec-layoutRole__mainBetweenColumn .ec-contactRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.ec-contactConfirmRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-contactConfirmRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-contactConfirmRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-contactConfirmRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-contactConfirmRole, .ec-layoutRole__mainBetweenColumn .ec-contactConfirmRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.ec-contactCompleteRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-contactCompleteRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-contactCompleteRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-contactCompleteRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-contactCompleteRole, .ec-layoutRole__mainBetweenColumn .ec-contactCompleteRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

/*
お客様情報の入力

ログインせずゲストとして商品を購入する際の、お客様情報の入力 に関する Project コンポーネントを定義します。
*/
.ec-customerRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-customerRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-customerRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-customerRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-customerRole, .ec-layoutRole__mainBetweenColumn .ec-customerRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

/*
404ページ

404 エラー画面で使用するページコンポーネントです。

警告アイコンを包括するdiv要素で、Twig内で「style="font-size:100px;text-align:center;"」とインラインCSSが記述されており少々手荒な感じがしますが、
当テンプレートでのCSSスタイリングに影響は微小ととらえTwigは編集せずそのままにしておきます。
*/
.ec-404Role {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100svh;
}
.ec-404Role .ec-off4Grid {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  width: 100% !important;
  max-width: none;
}
@media only screen and (max-width: 767px) {
  .ec-404Role .ec-off4Grid {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-404Role .ec-off4Grid {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-404Role .ec-off4Grid {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-404Role .ec-off4Grid, .ec-layoutRole__mainBetweenColumn .ec-404Role .ec-off4Grid {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ec-404Role .ec-off4Grid {
    margin-top: 6.25vw;
    margin-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-404Role .ec-off4Grid {
    margin-top: 3.906vw;
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-404Role .ec-off4Grid {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}
.ec-404Role .ec-icon {
  line-height: 1rem;
}
.ec-404Role .ec-icon img {
  width: 60px;
  height: auto;
}
.ec-404Role .ec-404Role__title {
  font-size: 1.5rem;
  margin: 1em 0;
  text-align: center !important;
}
.ec-404Role .ec-404Role__description {
  font-size: 1.1rem;
  text-align: center !important;
}
.ec-404Role .ec-blockBtn--cancel {
  margin-top: 30px;
  width: 100%;
  box-sizing: border-box;
}

/*
〇〇は存在しません

商品一覧ページにて、存在しないカテゴリ、商品検索で該当商品0の場合の表示スタイリングです。
使用箇所は商品一覧ページのみですが、拡張的にFAQや他のコンテンツで使用する場面も出てくる可能性もありますので、
こちらにてスタイリングを記述しています。
*/
.ec-notFoundRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
}
@media only screen and (max-width: 767px) {
  .ec-notFoundRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-notFoundRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-notFoundRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-notFoundRole, .ec-layoutRole__mainBetweenColumn .ec-notFoundRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
.ec-notFoundRole p {
  text-align: center;
  margin: 3em 0;
}

/*
退会手続き（フロントページ）

警告アイコンを包括するdiv要素で、Twig内で「style="font-size:100px;text-align:center;"」とインラインCSSが記述されており少々手荒な感じがしますが、
当テンプレートでのCSSスタイリングに影響は微小ととらえTwigは編集せずそのままにしておきます。
*/
.ec-withdrawRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .ec-withdrawRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-withdrawRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-withdrawRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-withdrawRole, .ec-layoutRole__mainBetweenColumn .ec-withdrawRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
.ec-withdrawRole .ec-icon {
  line-height: 1rem;
}
.ec-withdrawRole .ec-icon img {
  width: 60px;
  height: 60px;
}
.ec-withdrawRole .ec-withdrawRole__title {
  font-size: 1.5rem;
  margin: 1em 0;
}
.ec-withdrawRole .ec-withdrawRole__description {
  font-size: 1.1rem;
}
.ec-withdrawRole .ec-blockBtn--cancel {
  margin-top: 30px;
}

/*
退会手続き（確認ページ）

フロントページでは「.ec-withdrawRole」がコンテナになっていますが、こちらの確認ページでは「.ec-withdrawConfirmRole」でスタイリングされているものの、
コンテナ要素にはなっておりません。この親要素として「.ec-maypageRole」がつけられておりこちらがコンテナとなっています。
*/
.ec-withdrawConfirmRole {
  text-align: center;
}
.ec-withdrawConfirmRole .ec-icon {
  line-height: 1rem;
}
.ec-withdrawConfirmRole .ec-icon img {
  width: 60px;
  height: auto;
}
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__title {
  font-size: 1.5rem;
  margin: 1em 0;
}
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__description {
  font-size: 1.1rem;
}
.ec-withdrawConfirmRole .ec-blockBtn--cancel {
  margin-top: 30px;
  margin-bottom: 10px;
}
.ec-withdrawConfirmRole .ec-blockBtn {
  background-color: var(--clrBtnActionBg, #b20000);
  border-color: var(--clrBtnActionBg, #b20000);
}

/*
会員情報編集完了

会員情報編集完了で使用するページコンポーネントです。

※現バージョンでの編集完了画面は`.ec-registerCompleteRole`となっており、これは「19.1 会員登録」の中で定義されていますので、
こちらのスタイリングは未使用につき不要と思われます。
*/
/*
.ec-userEditCompleteRole {
  @include container;
  text-align: center;
  padding: 0 16px;

  & &__title{
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px;
    @include media_desktop(){
      font-size: 32px;
    }
  }

  & &__description{
    margin-bottom: 32px;
    font-size: 16px;
  }
}
*/
/*
ヘルプ

各ヘルプページに関する Project コンポーネントを定義します。
*/
.ec-helpRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  /* section */
  /* h3タイトル */
  /* h4タイトル */
  /* 段落 */
  /* 画像 */
  /* table */
  /* 枠囲み */
}
@media only screen and (max-width: 767px) {
  .ec-helpRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-helpRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-helpRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-helpRole, .ec-layoutRole__mainBetweenColumn .ec-helpRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ec-helpRole .ec-navlistRole {
    margin-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-helpRole .ec-navlistRole {
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-helpRole .ec-navlistRole {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .ec-helpRole section + section {
    margin-top: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-helpRole section + section {
    margin-top: 40px;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-helpRole section + section {
    margin-top: 60px;
  }
}
.ec-helpRole h3, .ec-helpRole .h3 {
  font-size: 1.066em;
  font-weight: normal;
  margin: 2em 0 1em;
  border-left: solid 5px var(--clrBorderColor, #ddd);
  border-bottom: solid 1px var(--clrBorderColor, #ddd);
  padding: 0.5em 0.5em 0.75em;
}
.ec-helpRole h4, .ec-helpRole .h4 {
  font-size: 1em;
  font-weight: 500;
  margin: 1em 0;
}
.ec-helpRole h4:before, .ec-helpRole .h4:before {
  content: "【";
}
.ec-helpRole h4:after, .ec-helpRole .h4:after {
  content: "】";
}
.ec-helpRole p {
  font-size: 1rem;
  line-height: 1.75;
}
.ec-helpRole .fig img {
  width: auto;
  max-width: 100%;
  height: auto;
}
.ec-helpRole table {
  width: 100%;
  margin-bottom: 1em;
}
.ec-helpRole table th {
  border: solid 1px var(--clrBorderColor, #ddd);
  padding: 1em;
}
.ec-helpRole table td {
  border: solid 1px var(--clrBorderColor, #ddd);
  padding: 1em;
  /* 価格表示セル */
}
.ec-helpRole table td.priceCell {
  text-align: right;
  white-space: nowrap;
}
.ec-helpRole .ec-frame {
  border: solid 1px var(--clrBorderColor, #ddd);
  padding: 1em;
  margin: 1em 0;
  font-size: inherit;
  line-height: 1.75;
}

/*
規約条文

規約条文の汎用スタイルです。
*/
.ec-provision .ec-provision__heading {
  font-size: 1rem;
  margin-bottom: 2em;
}
.ec-provision dl {
  font-size: 1rem;
  margin-bottom: 2em;
}
.ec-provision dl dt {
  font-weight: 700;
  margin-bottom: 0.5em;
}
.ec-provision dl dd {
  font-size: 1em;
  font-weight: normal;
  margin: 0;
}

/*
ブロックタイトル

プラグインブロック用に用意したセクションタイトルになります。
当テンプレートでは「商品レビュー」「関連商品」プラグインで使用しています。

※「関連商品」プラグインはTwigカスタマイズできないので「jeia_function.js」からブロックタイトルを挿入しています。
*/
.ec-blockHeading {
  margin-bottom: 2rem;
  text-align: center;
}
@media only screen and (min-width: 1280px) {
  .ec-blockHeading {
    margin-bottom: 48px;
  }
}
.ec-blockHeading h2, .ec-blockHeading .h2 {
  margin: 0;
  line-height: 1.2;
  text-align: center;
}
.ec-blockHeading .ec-blockHeading__main {
  font-family: Arial;
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--clrDefault, #222);
  position: relative;
  z-index: 0;
}
.ec-blockHeading .ec-blockHeading__main span {
  display: inline-block;
  padding: 0 1em;
  background-color: var(--clrBodyBg, #fff);
}
.ec-blockHeading .ec-blockHeading__main:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--clrBorderColor, #ddd);
  position: absolute;
  top: 50%;
  left: 0;
  z-index: -1;
}
.ec-blockHeading .ec-blockHeading__sub {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.25em;
}

/*
おすすめ商品管理プラグイン

※当テンプレートではカスタマイズした「Block/recommend_product_block.twig」を使用しています。
*/
.ec-recommendProductRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 1680px;
}
@media only screen and (max-width: 767px) {
  .ec-recommendProductRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-recommendProductRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-recommendProductRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-recommendProductRole, .ec-layoutRole__mainBetweenColumn .ec-recommendProductRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ec-recommendProductRole {
    margin-top: 6.25vw;
    margin-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-recommendProductRole {
    margin-top: 3.906vw;
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-recommendProductRole {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-recommendProductRole {
    padding-left: 60px;
    padding-right: 60px;
  }
}
/*
関連商品管理プラグイン

関連商品プラグインはTwigファイルをカスタマイズできません（プラグイン有功時にフロントにTwigファイルがコピーされない様子）ので、
プラグインが持つオリジナルのTwigのHTML構造の形で、可能な範囲においてスタイリングを施しています。
Twigをカスタマイズされる場合は「/app/Plugin/RelatedProduct4/Resource/template/front/related_product.twig」を編集してください。
*/
#RelatedProduct-product_area {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 1480px;
}
@media only screen and (max-width: 767px) {
  #RelatedProduct-product_area {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  #RelatedProduct-product_area {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  #RelatedProduct-product_area {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn #RelatedProduct-product_area, .ec-layoutRole__mainBetweenColumn #RelatedProduct-product_area {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  #RelatedProduct-product_area {
    margin-top: 6.25vw;
    margin-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  #RelatedProduct-product_area {
    margin-top: 3.906vw;
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  #RelatedProduct-product_area {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}
#RelatedProduct-product_area .ec-shelfGrid .ec-shelfGrid__item a {
  color: inherit;
}
#RelatedProduct-product_area .ec-shelfGrid .ec-shelfGrid__item a:hover {
  text-decoration: none;
}
#RelatedProduct-product_area .ec-shelfGrid .ec-shelfGrid__item p {
  text-align: center;
  margin-bottom: 0.5em;
}
#RelatedProduct-product_area .ec-shelfGrid .ec-shelfGrid__item .ec-shelfGrid__item-image {
  display: block;
  overflow: hidden;
  /* HTML構造によってはIE11でちらつく事があるので保険 */
  margin-bottom: 1em;
}
#RelatedProduct-product_area .ec-shelfGrid .ec-shelfGrid__item .ec-shelfGrid__item-image * {
  overflow: hidden;
}
#RelatedProduct-product_area .ec-shelfGrid .ec-shelfGrid__item .ec-shelfGrid__item-image img {
  transition: 0.6s;
}
#RelatedProduct-product_area .ec-shelfGrid .ec-shelfGrid__item .ec-shelfGrid__item-image:hover img {
  transform: scale(1.1);
  filter: brightness(1.05);
}
#RelatedProduct-product_area .ec-shelfGrid .ec-shelfGrid__item a + span {
  font-size: 0.8em;
  line-height: 1.2;
  text-align: left;
}

/*
商品レビュープラグイン

プラグインディレクトリ「/app/Plugin/ProductReview4/Resource/template/default」下のTwigファイルから、
「review.twig」のみテンプレートディレクトリにコピーして内容をカスタマイズしています。
その他の、index.twig, confirm.twig, complete.twigはコピーせず、プラグインディレクトリにあるTwigファイルが参照される形のままとしています。
その上で「index.twig」は一部CSS上書きスタイリングをしています。
*/
/*
「/app/Plugin/ProductReview4/Resource/template/default/index.twig」内に記述されているインラインCSSを上書きして調整します
*/
#product_review_recommend_level label {
  position: static !important;
  line-height: 0.75 !important;
}

.ec-productReviewRole {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto;
  max-width: 1280px;
  max-width: 1480px;
}
@media only screen and (max-width: 767px) {
  .ec-productReviewRole {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-productReviewRole {
    padding-left: 3.906vw;
    padding-right: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-productReviewRole {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: 40px;
    padding-right: 40px;
    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
  }
  .ec-layoutRole__mainWithColumn .ec-productReviewRole, .ec-layoutRole__mainBetweenColumn .ec-productReviewRole {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ec-productReviewRole {
    margin-top: 6.25vw;
    margin-bottom: 6.25vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .ec-productReviewRole {
    margin-top: 3.906vw;
    margin-bottom: 3.906vw;
  }
}
@media only screen and (min-width: 1280px) {
  .ec-productReviewRole {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}
.ec-productReviewRole .ec-productReviewRole__heading {
  display: flex;
  justify-content: center;
  background-color: #EFEFEF;
  color: #222;
  font-size: 1.066rem;
  padding: 1.25em;
  border-radius: 7px;
  position: relative;
}
.ec-productReviewRole .ec-productReviewRole__heading .heading_average {
  color: #FFA64D;
  margin-left: 0.5em;
}
.ec-productReviewRole .ec-productReviewRole__heading .heading_total {
  margin-left: 0.5em;
}
.ec-productReviewRole .ec-productReviewRole__heading .toggle {
  font-size: 1.25rem;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 1em);
  right: 0.25rem;
  transform: rotate(-90deg);
  transition: 0.3s;
}
.ec-productReviewRole .ec-productReviewRole__heading .toggle.is-close {
  transform: rotate(90deg);
}
.ec-productReviewRole .ec-productReviewRole__item {
  margin-top: 2rem;
}
@media only screen and (min-width: 1280px) {
  .ec-productReviewRole .ec-productReviewRole__item {
    margin-top: 40px;
  }
}
.ec-productReviewRole .ec-productReviewRole__item .item_1 {
  display: flex;
}
.ec-productReviewRole .ec-productReviewRole__item .item_2 {
  margin-top: 0.5rem;
  display: flex;
}
.ec-productReviewRole .ec-productReviewRole__item .item_3 {
  margin-top: 0.5rem;
}
.ec-productReviewRole .ec-productReviewRole__item .review_date {
  font-size: 1rem;
}
.ec-productReviewRole .ec-productReviewRole__item .review_name {
  font-size: 1rem;
  margin-left: 0.5em;
}
.ec-productReviewRole .ec-productReviewRole__item .review_name a {
  color: inherit;
}
.ec-productReviewRole .ec-productReviewRole__item .review_name a i {
  color: #999;
  margin-left: 0.25em;
}
.ec-productReviewRole .ec-productReviewRole__item .review_level {
  font-size: 1.066rem;
  color: #FFA64D;
}
.ec-productReviewRole .ec-productReviewRole__item .review_title {
  font-size: 1.066rem;
  font-weight: 500;
  margin-left: 0.5em;
}
.ec-productReviewRole .ec-productReviewRole__item .review_comment {
  font-size: 1rem;
  word-wrap: break-word;
}
.ec-productReviewRole .no_review {
  font-size: 1rem;
  margin-top: 2rem;
  text-align: center;
}
@media only screen and (min-width: 1280px) {
  .ec-productReviewRole .no_review {
    margin-top: 40px;
  }
}
.ec-productReviewRole .ec-productReviewRole__actions {
  margin-top: 2rem;
}
@media only screen and (min-width: 1280px) {
  .ec-productReviewRole .ec-productReviewRole__actions {
    margin-top: 40px;
  }
}
.ec-productReviewRole .ec-productReviewRole__actions .ec-blockBtn {
  width: 250px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .ec-productReviewRole .ec-productReviewRole__actions .ec-blockBtn {
    width: 100%;
  }
}