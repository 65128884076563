///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
アイコン・WEBフォント

EC-CUBE4ではアイコンに[Font Awesome 5](https://fontawesome.com/) を使用しています。<br>
Font Awesome 5 で利用可能なフォント群については、 Font Awesome 5 公式サイト をご確認ください。

当テンプレートではそのほか、独自のWEBフォントと[Google Material Design](https://material.io/" target="_blank">Material Design)を使用しています。
*/



/*
SVGアイコン

デフォルトテンプレートのアイコンは`.ec-icon`>`img`タグで使用することができます
*/
.ec-icon img {
  max-width: 80px;
  max-height: 80px;
}



/*
JEIA WEBフォント

当テンプレート用に独自のWEBフォントを使用しています。

WEBフォントの読み込みは__default_frame.twig__のheadの中で行っています。

当テンプレートのWEBフォントには[Apache 2.0ライセンス](http://www.apache.org/licenses/LICENSE-2.0)の
[Google Material Design](https://material.io/" target="_blank">Material Design)のアイコンと、
[FontAwesome](https://fontawesome.com/ target="_blank")のアイコンを含めています。

[アイコンリスト](/html/template/dtJEIA4002/assets/icon/jeiafont/demo.html)
*/
i.jeiafont {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'jeiafont';//!important;
  //speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  display: inline-block;
  vertical-align: middle;
}

/* アイコン回転 */
i.rotate-90 {
  transform-origin: center center;
  transform: rotate(90deg);
}

/* 以下アイコン追加があれば適宜追加 */
.jeia-cart:before {
  content: "\e900";
}
.jeia-search:before {
  content: "\e901";
}
.jeia-arrow:before {
  content: "\e902";
}
.jeia-heart:before {
  content: "\e903";
}
.jeia-heart_fill:before {
  content: "\e904";
}
.jeia-user:before {
  content: "\e905";
}
.jeia-user_fill:before {
  content: "\e906";
}
.jeia-lock:before {
  content: "\e907";
}
.jeia-lock_fill:before {
  content: "\e908";
}
.jeia-cross:before {
  content: "\e909";
}
.jeia-menu:before {
  content: "\e90a";
}
.jeia-more:before {
  content: "\e90b";
}
.jeia-radio:before {
  content: "\e90c";
}
.jeia-radio_checked:before {
  content: "\e90d";
}
.jeia-checkbox:before {
  content: "\e90e";
}
.jeia-checkbox_checked:before {
  content: "\e90f";
}
.jeia-call:before {
  content: "\e910";
}
.jeia-mail:before {
  content: "\e911";
}
.jeia-place:before {
  content: "\e912";
}
.jeia-more_angle:before {
  content: "\e913";
}
.jeia-warning:before {
  content: "\e914";
}
.jeia-error:before {
  content: "\e915";
}
.jeia-add:before {
  content: "\e916";
}
.jeia-remove:before {
  content: "\e917";
}
.jeia-clear:before {
  content: "\e918";
}
.jeia-star:before {
  content: "\e919";
}
.jeia-caret:before {
  content: "\e91a";
}
.jeia-arrow-left:before {
  content: "\e91b";
}
.jeia-arrow_b1:before {
  content: "\e91c";
}
.jeia-arrow_b2:before {
  content: "\e91d";
}
.jeia-truck:before {
  content: "\e91e";
}
.jeia-coins:before {
  content: "\e91f";
}
.jeia-creditcard:before {
  content: "\e920";
}
.jeia-twitter:before {
  content: "\e921";
}
.jeia-facebook:before {
  content: "\e922";
}
.jeia-googleplus:before {
  content: "\e923";
}
.jeia-instagram:before {
  content: "\e924";
}
.jeia-volume-mute:before {
  content: "\e925";
}
.jeia-volume-up:before {
  content: "\e926";
}
.jeia-angleup:before {
  content: "\e927";
}
.jeia-info:before {
  content: "\e928";
}
.jeia-crown:before {
  content: "\e929";
}
.jeia-eye:before {
  content: "\e92a";
}
.jeia-btn:before {
  content: "\e92b";
}
.jeia-fax:before {
  content: "\e92c";
}
.jeia-grid:before {
  content: "\e92d";
}
.jeia-cart2:before {
  content: "\e92e";
}
.jeia-cart3:before {
  content: "\e92f";
}
.jeia-amazon:before {
  content: "\e930";
}
.jeia-pinterest:before {
  content: "\e931";
}
.jeia-get-pocket:before {
  content: "\e932";
}
.jeia-line:before {
  content: "\e933";
}
.jeia-external-link:before {
  content: "\e934";
}
.jeia-printer:before {
  content: "\e935";
}
.jeia-facebook2:before {
  content: "\e936";
}
.jeia-line2:before {
  content: "\e937";
}
.jeia-hatenabookmark:before {
  content: "\e938";
}
.jeia-play-circle:before {
  content: "\e939";
}
