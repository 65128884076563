///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
フォーム部品（入力要素）

テキストや数値の入力項目に関するフォーム要素の基本整形をスタイリングします。
*/



/*
テキスト入力フォーム
*/
.ec-input {

  input {
    @include _form-control;    
  }

  textarea {
    @include _form-control;    
    height: auto;
    min-height: 8em;
  }

  & + p {
    margin: 0.75em 0 0;
  }

  // 連続する.ec-input
  & + & {
    margin-top: 1rem;
  }

  // エラー状態
  &.error {
    //input, textarea {
    input:not(:focus), textarea:not(:focus) {
      border-color: $input-error-border;
      background-color: $input-error-bg;  
    }
  }
}



/*
テキスト入力フォーム（左右2分割）

姓名など入力フォームが分割されている項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。
*/
.ec-halfInput {
  @extend .ec-input;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;// エラーメッセージも.ec-halfInput内に記述されるので

  $halfMargin: 1em;

  // 通常は.ec-halfInputのフレックスアイテムはinputだが、ショッピングページの「お客様情報」変更フォームにおいては、
  // ギミック上必要なのかspan要素が介入する形になるので、両方のパターンでスタイリングしておく。
  // エラーメッセージについては各エラーメッセージ（p要素）に「.ec-errorMessage」が付され、それが連続する形となりエラーメッセージを包括する要素はありません。（入力inputと同じ構造）
  > input,
  > .ec-errorMessage,
  > span.customer-form {
    flex: 0 0 auto;
    width: calc( ( 100% - #{$halfMargin} - 1px ) / 2 );// ブラウザサイズによっては2つ目のエラーメッセージが改行されてしまうケースがあるようなので「-1px」入れています
    margin-left: $halfMargin;

    &:first-of-type {
      margin-left: 0;
    }
  }

  // ショッピングページ「お客様情報」変更フォーム時の調整
  > span.customer-form {
    input {
      width: 100%;
      margin: 0;
    }
  }
}



/*
数量ボタン

数量を表示するための小さなコンポーネントです。
*/
.ec-numberInput {
  @extend .ec-input;

  input[type='number'] {
    width: auto;
    max-width: 5em;
    text-align: center;
  }
}

/*
数量ボタン（増減ボタンセット）
*/
.ec-quantityRole {
  $borderColor: $clrBorderColor;
  font-size: 1rem;

  display: inline-flex;
  align-items: center;
  box-sizing: border-box;//content-box;
  border: solid 1px $borderColor;

  & &__btn {
    width: $cartinRow-size;
    height: $cartinRow-size;
    line-height: $cartinRow-size;

    text-align: center;
    font-size: 1.2em;
    background-color: #f0f0f0;
    color: #222;
    cursor: pointer;
  }

  .ec-numberInput {
    height: $cartinRow-size;
    padding: 0;
    border: none;
    border-left: solid 1px $borderColor;
    border-right: solid 1px $borderColor;

    input {
      height: $cartinRow-size;
      border: none;
      width: $cartinRow-size;//3em;
      font-family: $ff-roboto;//$ff-en_thin;
      font-size: 1.3em;
    }
  }
}



/*
郵便番号フォーム
*/
.ec-zipInput {
  @extend .ec-input;

  input {
    display: inline-block;
    width: auto;
    max-width: 9em;
    margin-left: .5em;

    @include media_sphone {
      max-width: 8em;// iPhone SEだと「郵便番号検索」が改行されてしまうので以前の8emに
    }
  }

  > span {
    display: inline-block;
  }
}

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 1em;

  & &__icon {
    display: none;
  }

  a {
    font-size: 12px;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    height: 2em;
    border-radius: 3px;
    background-color: #16457e;
    color: #fff;
    padding-left: .5em;
    padding-right: .5em;
    transition: .3s;

    &:before {
      font-family: "jeiafont";
      content: "\e901";
      margin-right: .5em;
      padding-right: .5em;
      border-right: solid 1px hsla(0,0%,100%,0.3);
    }

    &:hover {
      text-decoration: none;
      filter: brightness(1.2);
    }
  }
}

// 郵便番号から自動入力（未使用）
.ec-zipAuto {
}

// .ec-zipInputに続く都道府県selectと住所Input間のマージン調整
.ec-zipInput {
  & + .ec-select {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}



/*
電話番号ボタン
*/
.ec-telInput{
  @extend .ec-input;

  input {
    max-width: 12em;
  }
}


/*
エラーメッセージ

エラー項目にポップアップ形式で表示するタイプにしたいが、.ec-harfInputの場合に2つ目のエラーも左に出てしまう。これを制御するにはTwig側に手をいれないといけないので断念。
普通にエラー項目の下にテキスト表示するに留めます。
*/
.ec-errorMessage {
  font-size: 0.9rem;
  margin: .5em 0 0;
  color: $clrError;

  &:before {
    font-family: "jeiafont";
    font-weight: normal;
    content: "\e915";
    font-size: 20px;
    display: inline-block;
    vertical-align: -4px;
    margin-right: 3px;
  }
}



/* その他 */

/*
input[type="number"]のスピンボタンを非表示

使用箇所：
利用ポイント、商品数量入力
*/
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance:textfield;
}
